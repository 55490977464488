<template>
    <div class="mx-10">
        <!-- Si es posible devolver nombre del cliente -->
        <v-data-table :headers="headerOrder" :items="orders">
            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->

                <v-row align="center" justify="space-between">
                    <v-col cols="12" lg="6" md="6" sm="6">
                        <v-text-field
                            v-model="search"
                            label="Buscar"
                            append-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="auto"
                        md="auto"
                        sm="auto"
                        v-if="canCreate"
                    >
                        <router-link to="/order/form">
                            <v-btn color="primary">
                                <v-icon> mdi-plus </v-icon>
                                Agregar
                            </v-btn>
                        </router-link>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:[`item.estado`]="{ item }">
                <v-chip
                    :color="returnEstadoColor(item.estado)"
                    text-color="white"
                    label
                >
                    {{ item.estado }}
                </v-chip>
            </template>

            <template v-slot:[`item.credito`]="{ item }">
                <v-chip v-if="!item.credito" outlined label color="green">
                    <v-icon left> mdi-cash </v-icon>
                    Contado
                </v-chip>
                <v-chip v-else outlined label color="orange darken-3">
                    <v-icon left> mdi-credit-card </v-icon>
                    Credito</v-chip
                >
            </template>

            <template v-slot:[`item.order_detail[0].total`]="{ item }">
                {{
                    convertMoney(
                        item.order_detail[0].total,
                        item.order_detail[0].codigo
                    )
                }}
            </template>

            <!-- Acciones para eliminar y editar -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    color="green"
                    @click="verPedido(item)"
                >
                    mdi-eye
                </v-icon>
                <router-link
                    to="/order/form"
                    v-if="item.estado !== 'Vendido' && canEdit"
                >
                    <v-icon
                        small
                        class="mr-2"
                        color="blue"
                        @click="editarPedido(item)"
                    >
                        mdi-pencil
                    </v-icon>
                </router-link>
                <v-icon
                    v-if="canDelete && item.estado !== 'Vendido'"
                    small
                    color="red"
                    @click="eliminarPedido(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <DeleteDialogComponentVue
            :dialog="dialogDeleteOrder"
            :set-dialog="setDialogDeleteOrder"
            @deleted="deleteOrder(deleteObject)"
        />
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { convertMoney } from "../../formatos";
import DeleteDialogComponentVue from "../DeleteDialog.Component.vue";

export default {
    data: () => ({
        deleteObject: {},
        search: "",
    }),
    mounted() {
        this.getPermissions(this.$route.path);
        this.getOrders();
        this.getQuoteCurrency();
    },
    components: {
        DeleteDialogComponentVue,
    },
    computed: {
        ...mapState("app", ["canCreate", "canEdit", "canDelete"]),
        ...mapState("order", [
            "orders",
            "headerOrder",
            "dialogDeleteOrder",
            "isEditOrder",
        ]),
    },
    methods: {
        ...mapActions("app", ["getPermissions"]),
        ...mapMutations("order", [
            "setDialogDeleteOrder",
            "setDialogShowOrder",
        ]),
        ...mapMutations("order", ["setIsEditOrder"]),
        ...mapActions("order", ["getOrders", "getOneOrder", "deleteOrder"]),
        ...mapActions("quote", ["getQuoteCurrency"]),

        eliminarPedido(item) {
            this.setDialogDeleteOrder(true);
            this.deleteObject = item;
        },
        verPedido(item) {
            this.setDialogShowOrder(true);
            this.getOneOrder(item);
        },

        async editarPedido(item) {
            await this.getOneOrder(item);
            this.setIsEditOrder(true);
        },
        returnEstadoColor(estado) {
            if (estado === "Vendido") {
                return "green";
            } else if (estado === "Faltantes") {
                return "red";
            } else {
                return "orange";
            }
        },
        convertMoney,
    },
};
</script>
