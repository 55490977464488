import axios from "axios";
const state = {
    deleteDialogBudget: false,
    showDialogBudget: false,
    editBudget: false,
    budget: {
        currency_id: 0,
        order_detail: [
            {
                codigo: "",
            },
        ],
    },
    productosBudget: [],
    productsBugetToPos: [],
    isBudgetToPos: false,
    producto: {},
    items: 0,
    budgets: [],
    headers: [
        { text: "Número", value: "numero" },
        {
            text: "Cliente",
            align: "start",
            value: "nombre_y_apellido",
        },
        {
            text: "Ruc",
            align: "start",
            value: "ruc",
        },
        {
            text: "Estado",
            align: "center",
            value: "estado",
        },
        {
            text: "N° venta",
            value: "sale_numero",
            align: "center"
        },
        {
            text: "N° pedido",
            value: "order_numero",
            align: "center"
        },
        {
            text: "Fecha",
            align: "center",
            value: "created_at",
        },
        { text: "Opciones", value: "actions", sortable: false, width: "150px" },
    ],
    headersProduct: [
        { text: "Codigo", value: "codigo", align: "center" },
        { text: "Producto", value: "producto", align: "center" },
        { text: "Cantidad", value: "cantidad", align: "center" },
        { text: "Precio Unitario", value: "precio_unitario", align: "center" },
        { text: "SubTotal", value: "subtotal", align: "center" },
        { text: "Deposito", value: "store_id" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
    headersShowProduct: [
        { text: "Codigo", value: "codigo", align: "center" },
        { text: "Producto", value: "producto", align: "center" },
        { text: "Cantidad", value: "cantidad", align: "center" },
        { text: "Precio Unitario", value: "precio_unitario", align: "center" },
        { text: "SubTotal", value: "subtotal", align: "center" },
        { text: "Deposito", value: "store_id" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
};

const actions = {
    getBudgets({ commit }) {
        axios
            .get("/budgets")
            .then((response) => {
                commit("ALL_BUDGETS", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    async getOneBudget({ commit }, payload) {
        await axios
            .get(`/budgets/${payload.id}`)
            .then((response) => {
                commit("GET_BUDGET", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    createBudget({ commit, dispatch }, params) {
        axios
            .post("/budgets", params)
            .then((response) => {
                commit("CREATE_BUDGET");
            })
            .then(() => {
                this.commit(
                    "app/setSnackbarSuccess",
                    "Presupuesto creado correctamente"
                );
                dispatch("getBudgets");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    updateBudget({ commit, dispatch }, params) {
        axios
            .put(`/budgets/${params.id}`, params)
            .then((response) => {
                commit("UPDATE_BUDGET");
            })
            .then(() => {
                this.commit(
                    "app/setSnackbarSuccess",
                    "Presupuesto actualizado correctamente"
                );
                dispatch("getBudgets");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    deleteBudget({ commit, dispatch }, payload) {
        axios
            .delete(`/budgets/${payload.id}`)
            .then(() => {
                commit("DELETE_BUDGET");
            })
            .then(() => {
                this.commit("app/setSnackbarSuccess", "Operacion Exitosa");
                dispatch("getBudgets");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    async getClientDate({ commit, state, dispatch }, id) {
        const cliente = await axios.post("/clients", id);
        if (!cliente) {
            this.commit(
                "app/setSnackbarAlert",
                "No se pudo encontrar el cliente"
            );
        }
        commit("GET_CLIENT", cliente);
    },

    async verifyProductAmount({ commit }, payload) {
        await this.dispatch("deposito/getProductsStores", { dep: 1 });
        payload.forEach((product) => {
            const verifyProduct = this.state.deposito.productsStore.find(
                (item) => item.product_id === product.product_id
            );

            if (!verifyProduct) {
                this.commit(
                    "app/setSnackbarAlert",
                    "No se ha encontrado el producto"
                );
                commit("VERIFY_PRODUCT_ERROR");
                return;
            }
            if (verifyProduct.cantidad < product.cantidad) {
                this.commit(
                    "app/setSnackbarAlert",
                    `No hay suficiente stock para producto: ${verifyProduct.product.nombre}`
                );
                commit("VERIFY_PRODUCT_ERROR");
                return;
            }
            commit("VERIFY_PRODUCT", verifyProduct);
        });
    },
};

const mutations = {
    addProductBudget(state, payload) {
        state.productosBudget.push(payload);
    },
    setProducto(state, payload) {
        state.producto = payload;
    },
    setEditBudget(state, payload) {
        state.editBudget = payload;
    },

    setShowDialogBudget(state, payload) {
        state.showDialogBudget = payload;
    },

    ALL_BUDGETS(state, payload) {
        state.budgets = payload;
    },
    GET_BUDGET(state, payload) {
        state.budget = { ...payload };
        let productsBudget = [];
        let totalVenta = 0;

        const cotizacion = this.getters["quote/getQuotes"].find(
            (co) => co.currency_id === payload.currency_id
        );

        payload.budget_detail.forEach((budget) => {
            let product = {
                ...budget,

                codigo: budget.product.codigo,
                producto: budget.product.nombre,

                subtotal:
                    parseFloat(budget.cantidad) *
                    parseFloat(budget.precio_unitario),
            };
            totalVenta += product.subtotal;
            productsBudget.push(product);
        });
        state.budget.total = totalVenta;

        this.state.sale.saleProducts = productsBudget;
        this.state.sale.totalVenta = totalVenta;

        state.budget.cotizacion = cotizacion;
    },
    CREATE_BUDGET(state) {},
    UPDATE_BUDGET(state) {
        state.budget = {};
        this.editBudget = false;
    },
    DELETE_BUDGET(state) {
        state.isBudgetToPos = false;
    },
    VERIFY_PRODUCT(state, payload) {
        state.productsBugetToPos.push(payload);
        state.isBudgetToPos = true;
    },
    VERIFY_PRODUCT_ERROR(state) {
        state.productsBugetToPos = [];
        state.isBudgetToPos = false;
    },
    setDeleteDialogBudget(state, payload) {
        state.deleteDialogBudget = payload;
    },
    setBudgetToSale(state) {
        console.log(state.budget);
    },
    clearProductBudgetToPos(state) {
        state.productsBugetToPos = [];
    },
    clearBudget(state) {
        state.budget = {
            currency_id: 0,
            order_detail: [
                {
                    codigo: "",
                },
            ],
        };

        this.state.sale.saleProducts = [];
        this.state.sale.totalVenta = 0;
    },
    addBudgetProduct(state, payload) {
        const index = state.productosBudget.findIndex(
            (el) => el.product_id === payload.product_id
        );

        if (index !== -1) {
            let cantidad = state.productosBudget[index].cantidad;
            state.productosBudget[index].cantidad =
                parseInt(cantidad) + parseInt(payload.cantidad);

            state.productosBudget[index].subtotal =
                state.productosBudget[index].cantidad *
                parseInt(payload.precio_unitario);

            return;
        }

        state.productosBudget.push(payload);
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
