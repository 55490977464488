import { render, staticRenderFns } from "./FormPurchases.Component.vue?vue&type=template&id=127cf3e8&scoped=true&v-slot%3Aactivator=%7B%20on%2C%20attrs%20%7D"
import script from "./FormPurchases.Component.vue?vue&type=script&lang=js"
export * from "./FormPurchases.Component.vue?vue&type=script&lang=js"
import style0 from "./FormPurchases.Component.vue?vue&type=style&index=0&id=127cf3e8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "127cf3e8",
  null
  
)

export default component.exports