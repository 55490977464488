<template>
    <div class="mx-10">
        <v-data-table
            :headers="headersPurchase"
            :items="purchases"
            :search="search"
            sort-by="nombre"
        >
            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->

                <v-row align="center" justify="space-between">
                    <v-col cols="12" lg="6" md="6" sm="6">
                        <v-text-field
                            v-model="search"
                            label="Buscar"
                            append-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="auto"
                        md="auto"
                        sm="auto"
                        v-if="canCreate"
                    >
                        <v-btn color="primary" @click="nuevo()">
                            <v-icon> mdi-plus </v-icon>
                            Agregar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>

            <!-- Columna Proveedor -->
            <template v-slot:[`item.supplier.nombre`]="{ item }">
                <v-chip color="indigo darken-1" label text-color="white">
                    <v-icon left> mdi-account-circle-outline </v-icon>
                    {{ item.supplier.nombre }}</v-chip
                >
            </template>

            <!-- Columna credito -->
            <template v-slot:[`item.credito`]="{ item }">
                <v-chip
                    class="ma-2"
                    label
                    :color="item.credito ? 'primary' : 'green lighten-1'"
                    text-color="white"
                >
                    <v-icon left>{{
                        item.credito === 1
                            ? "mdi-credit-card-multiple"
                            : "mdi-cash-multiple"
                    }}</v-icon>

                    {{ isCredito(item.credito) }}
                </v-chip>
            </template>

            <!-- Columna Plazo  -->
            <template v-slot:[`item.plazo`]="{ item }">
                {{ item.credito === 0 ? "Sin Plazo" : `${item.plazo} dias` }}
            </template>

            <!-- columna precio total de productos -->
            <template v-slot:[`item.purchase_detail.total`]="{ item }">
                <div v-for="detalle in item.purchase_detail" :key="detalle.id">
                    {{ convertMoney(detalle.total, detalle.quotation.currency.codigo) }}
                </div>
                <!--<span
                    >{{
                        item.purchase_detail.subtotal[1]
                    }}
                </span>-->
            </template>

            <!-- Columna Proveedor -->
            <template v-slot:[`item.created_at`]="{ item }">
                <span>{{ returnDate(item.created_at) }}</span>
            </template>

            <!-- Iconos Editar Y Eliminar  -->
            <template v-slot:[`item.actions`]="{ item }">
                <!-- aca se reemplaza el nombre del metodo que solicita los detalles de compra-->
                <v-icon
                    small
                    class="mr-2"
                    color="green"
                    @click="showPurchaseDetail(item)"
                >
                    mdi-eye
                </v-icon>
                <v-icon
                    v-if="canEdit"
                    small
                    class="mr-2"
                    color="blue"
                    @click="
                        setDialog(true);
                        setEdit();
                        getOnePurchase(item);
                    "
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    v-if="canDelete"
                    small
                    class="mr-2"
                    color="red"
                    @click="
                        localPurchase = item;
                        setDeleteDialog();
                    "
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <DeleteDialog
            :dialog="deleteDialog"
            :setDialog="setDeleteDialog"
            v-on:deleted="setDeletePurchase(localPurchase)"
        />
        <AvisoAperturaDialog />
        <DialogShowPurchase />
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import DialogShowPurchase from "../Purchases/DialogShowPurchase.Component.vue";
import DeleteDialog from "../DeleteDialog.Component.vue";
import AvisoAperturaDialog from "../AvisoAperturaDialog.vue";
import { convertMoney } from "../../formatos";
export default {
    data: () => ({
        search: "",
        localPurchase: {},
        searchProductDetail: "",
    }),
    components: {
        DeleteDialog,
        AvisoAperturaDialog,
        DialogShowPurchase,
    },
    mounted() {
        this.getPurchases();
        this.getPermissions(this.$route.path);
        this.comprobarApertura();

    },
    computed: {
        ...mapState("purchase", [
            "purchases",
            "dialog",
            "headersPurchase",
            "edit",
            "purchase",
            "deleteDialog",
            "headersDialog",
            "dialogCreditorDetail",
            "showPurchase",
        ]),
        ...mapState("app", ["snackbar"]),
        ...mapState("app", ["canCreate", "canEdit", "canDelete"]),
        ...mapState("box", ["isApertura"]),
        reutrnSupplier() {
            return (this.localSupplier = Object(this.purchase.supplier));
        },
    },
    methods: {
        ...mapActions("purchase", [
            "getPurchases",
            "deletePurchase",
            "getOnePurchase",
            "getShowPurchase",
        ]),
        ...mapActions("box", ["comprobarApertura"]),
        ...mapMutations("purchase", [
            "setDialog",
            "setEdit",
            "getEdit",
            "setCategory",
            "setDeleteDialog",
            "clearCategory",
            "clearPurchase",
            "setDialogCreditorDetail",
        ]),
        convertMoney,

        //Eliminar Categoria
        setDeletePurchase(purchase) {
            this.deletePurchase(purchase);
            this.getPurchases();
        },

        isCredito(cred) {
            return cred === 1 ? "Credito" : "Contado";
        },
        returnDate(date) {
            return new Date(date).toLocaleDateString("es-ES");
        },
        redirectPurchaseDetail(id) {
            this.$router.push(`purchase/${id}`);
        },
        showPurchaseDetail(item) {
            //Ver si es un form editar para cambiar de ruta "url"
            //para editar purchases/{id}/edit
            //para ver los detalles purchases//{id}
            this.getOnePurchase(item);
            this.setDialogCreditorDetail(true);
        },
        nuevo() {
            this.clearPurchase();
            this.setDialog();
        },
        ...mapActions("app", ["getPermissions"]),
    },
};
</script>
