const state = {
    dialog: false,
    edit: false,
    deleteDialog: false,
    isDeleted: false,
    suppliers: [],
    supplier: {},
    supplierForOther: {},
    isSupplierForOther: false,
    loading: false,
    suppliersPage: {
        current: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        firstPage: 0,
    },
    headers: [
        {
            text: "Nombre",
            align: "start",
            value: "nombre",
        },
        { text: "ruc", value: "ruc" },
        { text: "telefono", value: "phone.telefono" },
        { text: "correo", value: "mail.correo" },
        { text: "direccion", value: "address.direccion" },
        { text: "Opciones", value: "actions", sortable: false },
    ],
};

const actions = {
    getSuppliers({ commit, state }) {
        axios
            .get(`/suppliers?page=${state.suppliersPage.current}`)
            .then((response) => {
                commit("GET_SUPPLIERS", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    "Ocurrio algun error en servidor"
                );
            });
    },
    createSupplier({ commit, dispatch }, params) {
        this.commit("app/setIsLoading", true);
        axios
            .post("/suppliers", params)
            .then((response) => {
                if (state.isSupplierForOther)
                    state.supplierForOther = response.data;
                commit("CREATE_SUPPLIER", params);
            })
            .then((response) => {
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getSuppliers");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    "Ocurrio algun error en servidor"
                );
            });
        this.commit("app/setIsLoading", false);
    },
    editSupplier({ commit }, params) {
        axios
            .put(`/suppliers/${params.id}`, params)
            .then((response) => {
                commit("EDIT_SUPPLIER", params);
            })
            .then(() => {
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getSuppliers");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    "Ocurrio algun error en servidor"
                );
            });
    },
    deleteSupplier({ commit }, params) {
        axios
            .delete(`/suppliers/${params.id}`)
            .then((response) => {
                commit("DELETE_SUPPLIER", params);
            })
            .then((response) => {
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
                dispatch("getSuppliers");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    "Ocurrio algun error en servidor"
                );
            });
    },

    searchSupplier({ commit, state }, payload) {
        state.loading = true;
        if (state.setTimeoutBuscador) clearTimeout(state.setTimeoutBuscador);
        state.setTimeoutBuscador = setTimeout(function () {
            axios
                .post("/search_suppliers", { buscador: payload })
                .then((response) => {
                    commit("SEARCH_SUPPLIER", response.data);
                })
                .catch((error) => {
                    this.commit(
                        "app/setSnackbarAlert",
                        "Ocurrio algun error en servidor"
                    );
                })
                .finally(() => {
                    state.loading = false;
                });
        }, 500);
    },
};

const mutations = {
    setDialog(state) {
        state.dialog = !state.dialog;
    },
    setEdit(state) {
        state.edit = !state.edit;
    },
    setDeleteDialog(state) {
        state.deleteDialog = !state.deleteDialog;
    },
    setSupplier(state, item) {
        state.supplier.id = item.id;
        state.supplier.nombre = item.nombre;
        state.supplier.ruc = item.ruc;
        state.supplier.correo = item.mail.correo;
        state.supplier.direccion = item.address.direccion;
        state.supplier.telefono = item.phone.telefono;
        state.supplier.user_id = item.user_id;
    },
    clearSupplierForOther(state) {
        state.supplierForOther = {};
        state.isSupplierForOther = false;
    },
    setIsSupplierForOther(state) {
        state.isSupplierForOther = true;
    },
    clearSupplier(state) {
        state.supplier = {};
    },
    GET_SUPPLIERS(state, suppliers) {
        state.suppliers = suppliers.data;
        state.suppliersPage = {
            total: suppliers.total,
            perPage: suppliers.per_page,
            current: suppliers.current_page,
            lastPage: suppliers.last_page,
            firstPage: suppliers.first_page,
        };
    },
    CREATE_SUPPLIER(state) {
        state.dialog = false;
    },
    EDIT_SUPPLIER(state) {
        state.dialog = false;
        state.edit = false;
    },
    DELETE_SUPPLIER(state) {
        state.delete = false;
    },
    SEARCH_SUPPLIER(state, suppliers) {
        state.suppliers = suppliers.data;
        state.suppliersPage = {
            total: suppliers.total,
            perPage: suppliers.per_page,
            current: suppliers.current_page,
            lastPage: suppliers.last_page,
            firstPage: suppliers.first_page,
        };
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
