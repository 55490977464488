<template v-slot:activator="{ on, attrs }">
    <!-- Dialog ver Venta -->
    <v-dialog
        v-model="dialogShowSale"
        max-width="1000px"
        @click:outside="closeDialog()"
        @keydown.esc="closeDialog()"
    >
        <v-card>
            <v-row class="mx-1" justify="space-between">
                <v-col cols="12" md="6" class="">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title
                                v-if="sale.client"
                                class="text-h7 pb-1"
                                >Cliente:
                                {{
                                    sale.client.nombre_y_apellido
                                }}</v-list-item-title
                            >
                            <v-list-item-title
                                v-if="sale.client"
                                class="text-h7 pb-1"
                                >Ruc: {{ sale.client.ruc }}</v-list-item-title
                            >
                            <v-list-item-title class="text-h7 pb-1"
                                >Fecha: {{ sale.fecha }}</v-list-item-title
                            >
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="6">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title
                                v-if="!!sale.factura_numero"
                                class="text-body-1 text-end mb-2"
                            >
                                Factura: {{ sale.factura_numero }}
                            </v-list-item-title>
                            <v-list-item-title class="text-h6 text-end mb-2">
                                Cantidad:
                                {{ sale.cantidad }}
                            </v-list-item-title>
                            <v-list-item-title class="text-h6 text-end mb-2">
                                Total:
                                {{ convertMoney(sale.total, currencyCode) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
            </v-row>
            <v-row justify="center" class="pa-0 ma-0">
                <v-col class="pa-0" md="10"
                    ><v-divider class="info"></v-divider
                ></v-col>
            </v-row>

            <!-- Tabla mostrar compra-->
            <v-row class="p-0 m-0">
                <v-col cols="12" md="12">
                    <v-data-table
                        class="mx-1"
                        :headers="filteredHeaders"
                        :items="sale.sale_detail"
                        hide-default-footer
                        :items-per-page="-1"
                    >
                        <!-- Columna precio_unitario  -->
                        <template v-slot:[`item.precio_unitario`]="{ item }">
                            {{
                                convertMoney(item.precio_unitario, currencyCode)
                            }}
                        </template>

                        <!-- Columna subtotal  -->
                        <template v-slot:[`item.subtotal`]="{ item }">
                            {{ convertMoney(item.subtotal, currencyCode) }}
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red lighten-2"
                    text
                    class="ma-3"
                    @click="closeDialog()"
                >
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { convertMoney } from "../../formatos";

export default {
    data: () => ({
        dialog: true,
    }),
    computed: {
        ...mapState("sale", [
            "sale",
            "headersProduct",
            "showSale",
            "dialogShowSale",
        ]),
        filteredHeaders() {
            return this.headersProduct.filter(
                (header) => header.text !== "Opciones"
            );
        },
        currencyCode() {
            return this.sale.moneda ? this.sale.moneda.codigo : "PYG";
        },
    },
    methods: {
        ...mapMutations("sale", ["setDialogShowSale", "limpiarPOS"]),

        closeDialog() {
            this.setDialogShowSale(false);
            const quote = JSON.parse(localStorage.getItem("quote"));
            this.limpiarPOS(quote.currency_id);
        },

        convertMoney,
    },
};
</script>
