<template>
    <v-row justify="center">
        <v-dialog
            v-model="budgetDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            @keydown.esc="setBudgetDialog(false), clearBudget()"
        >
            <v-card>
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Presupuesto</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-checkbox
                            v-model="ocultarTotal"
                            class="mt-4 mr-5"
                            label="Ocultar total"
                        ></v-checkbox>
                        <v-checkbox
                            v-model="credito"
                            class="mt-4 mr-5"
                            label="Crédito"
                        ></v-checkbox>

                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon dark v-bind="attrs" v-on="on">
                                    <v-icon>mdi-download</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="generarPDF()">
                                    <v-list-item-title
                                        >Descargar como PDF</v-list-item-title
                                    >
                                </v-list-item>
                                <v-list-item @click="generarPNG()">
                                    <v-list-item-title
                                        >Descargar como
                                        imagen</v-list-item-title
                                    >
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-btn icon dark @click="abrirWhatsapp()">
                            <v-icon>mdi-whatsapp</v-icon>
                        </v-btn>
                        <v-menu left bottom offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon class="color-disabled"
                                        >mdi-dots-vertical</v-icon
                                    >
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item @click="abrirTelegram()">
                                    <v-list-item-title>
                                        <v-icon color="info"
                                            >mdi-telegram</v-icon
                                        >
                                        Enviar por telegram
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="abrirInstagram">
                                    <v-list-item-title>
                                        <v-icon color="#C13584"
                                            >mdi-instagram</v-icon
                                        >
                                        Enviar por instagram
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="abrirFacebook()">
                                    <v-list-item-title>
                                        <v-icon color="#00B2FF"
                                            >mdi-facebook-messenger</v-icon
                                        >
                                        Enviar por facebook
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                        <v-btn
                            icon
                            dark
                            @click="setBudgetDialog(false), clearBudget()"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-container ref="mainTemplate" class="">
                    <v-row justify="center" class="mt-3">
                        <v-col md="4">
                            <div class="text-star mb-2">
                                <img
                                    src="/images/logo.png"
                                    width="200px"
                                    alt=""
                                />
                            </div>
                            <span
                                >Cliente:
                                {{
                                    budget?.client
                                        ? budget?.client?.nombre_y_apellido
                                        : "Venta al público"
                                }}</span
                            ><br />
                            <span
                                >RUC:
                                {{
                                    budget?.client ? budget?.client?.ruc : "--"
                                }}</span
                            ><br />
                            <span
                                >Telefono:
                                {{
                                    budget?.client
                                        ? budget?.client?.telefono
                                        : "--"
                                }}</span
                            >
                        </v-col>
                        <v-col md="4" align-self="end" class="text-center">
                            <span
                                >Venta:
                                {{ credito ? "Crédito" : "Contado" }}</span
                            >
                        </v-col>
                        <v-col class="text-end mt-3" md="4">
                            <h2>Presupuesto</h2>
                            <span>Presupuesto #: {{ budget?.numero }}</span
                            ><br />
                            <span>Emitido: {{ fecha(true) }}</span
                            ><br />
                            <span>Válido hasta: {{ fecha(false) }}</span
                            ><br />
                        </v-col>
                    </v-row>

                    <v-row justify="space-around">
                        <v-col class="text-center" md="12">
                            <v-data-table
                                :headers="filteredHeaders"
                                :items="budget.budget_detail"
                                class="mx-1"
                                :items-per-page="-1"
                                hide-default-footer
                            >
                                <template v-slot:[`item.codigo`]="props">
                                    <span>{{
                                        props.item.product?.codigo
                                    }}</span>
                                </template>
                                <template v-slot:[`item.producto`]="props">
                                    <span>{{
                                        props.item.product?.nombre
                                    }}</span>
                                </template>
                                <template
                                    v-slot:[`item.precio_unitario`]="{ item }"
                                >
                                    {{
                                        convertMoney(
                                            item.precio_unitario,
                                            budget.cotizacion.codigo
                                        )
                                    }}
                                </template>

                                <!-- Mostrar precio subTotal -->
                                <template v-slot:[`item.subtotal`]="{ item }">
                                    <span>{{
                                        convertMoney(
                                            item.subtotal,
                                            budget.cotizacion.codigo
                                        )
                                    }}</span>
                                </template>
                            </v-data-table>
                            <h5 v-if="!ocultarTotal" class="text-end mt-2">
                                Total: {{ budget.total }}
                            </h5>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { convertMoney } from "../../formatos";
export default {
    data() {
        return {
            buscarCliente: "",
            selectedClient: null,
            credito: false,
            ocultarTotal: false,
            totalVenta: 0,
        };
    },
    watch: {
        buscarCliente(val) {
            this.searchClient(val);
        },
    },
    computed: {
        ...mapState("app", ["isLoading"]),
        ...mapState("sale", [
            "headersProductPOS",
            "saleProducts",
            "budgetDialog",
        ]),
        ...mapState("budget", ["headersProduct", "budget"]),
        filteredHeaders() {
            return this.headersProductPOS.filter(
                (header) => header.text !== "Opciones"
            );
        },

        localShowHeaders() {
            return this.headersProduct.slice(0, 6);
        },
    },
    methods: {
        ...mapActions("sale", ["createBudget", "getBudgetNumber"]),
        ...mapMutations("sale", [
            "setBudgetDialog",
            "limpiarPOS",
            "setIsSaved",
        ]),
        ...mapMutations("report", ["budgetPDF", "budgetPNG"]),
        ...mapMutations("budget", ["clearBudget"]),

        generarPDF() {
            const cliente = this.budget?.client
                ? "Presupuesto_" +
                  this.budget.numero +
                  "_" +
                  this.budget?.client.nombre_y_apellido
                : "Presupuesto_" + this.budget?.numero + "_al_publico";
            const payload = {
                container: this.$refs.mainTemplate,
                client: cliente,
            };
            this.budgetPDF(payload);
        },
        generarPNG() {
            const cliente = this.budget?.client
                ? "Presupuesto_" +
                  this.budget.numero +
                  "_" +
                  this.budget?.client.nombre_y_apellido
                : "Presupuesto_" + this.budget?.numero + "_al_publico";
            const payload = {
                container: this.$refs.mainTemplate,
                client: cliente,
            };
            this.budgetPNG(payload);
        },
        filterObjectClient(item, queryText, itemText) {
            return (
                item.nombre_y_apellido
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.ruc
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
        fecha(hoy) {
            const dias = hoy ? 0 : 14;
            const fechaHoy = new Date();
            fechaHoy.setDate(fechaHoy.getDate() + dias);
            const meses = [
                "enero",
                "febrero",
                "marzo",
                "abril",
                "mayo",
                "junio",
                "julio",
                "agosto",
                "septiembre",
                "octubre",
                "noviembre",
                "diciembre",
            ];
            const mesActual = meses[fechaHoy.getMonth()];
            const anioActual = fechaHoy.getFullYear();
            const diaActual = fechaHoy.getDate();
            const fechaFormateada = `${diaActual} de ${mesActual} de ${anioActual}`;
            return fechaFormateada;
        },
        abrirWhatsapp() {
            let link = "https://web.whatsapp.com";
            if (this.selectedClient) {
                const numero = this.selectedClient.telefono.slice(1);
                link =
                    "https://api.whatsapp.com/send?phone=" +
                    numero +
                    "&text=Hola " +
                    this.selectedClient.nombre_y_apellido +
                    ", te enviamos este presupuesto.";
            }
            window.open(link, "_blank");
        },
        abrirTelegram() {
            let link = "https://web.telegram.org";
            const msj =
                "Hola " +
                this.selectedClient.nombre_y_apellido +
                ", te enviamos este presupuesto.";
            if (this.selectedClient) {
                link =
                    `https://t.me/${this.selectedClient.telefono}?text=` + msj;
            }
            window.open(link, "_blank");
        },
        abrirFacebook() {
            window.open("https://www.facebook.com/messages/t", "_blank");
        },
        abrirInstagram() {
            window.open("https://www.instagram.com/direct/inbox/", "_blank");
        },
        convertMoney,
    },
};
</script>
<style scope>
.color-disabled {
    color: hsla(0, 0%, 100%, 0.3) !important;
}
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
    max-width: 850px;
}
.v-input--checkbox .v-label {
    margin-bottom: 0px !important;
}
</style>
