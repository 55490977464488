import { render, staticRenderFns } from "./FormBrand.Component.vue?vue&type=template&id=56bed9d7&v-slot%3Aactivator=%7B%20on%2C%20attrs%20%7D"
import script from "./FormBrand.Component.vue?vue&type=script&lang=js"
export * from "./FormBrand.Component.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports