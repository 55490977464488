<template v-slot:top>
    <div>
        <v-card class="my-10">
            <v-list-item class="">
                <v-card
                    class="mt-n7"
                    color="primary"
                    elevation="10"
                    min-height="80"
                    min-width="80"
                >
                    <v-icon class="ma-5" large dark>mdi-tools</v-icon>
                </v-card>

                <v-list-item-title>
                    <div class="overline mx-10 mt-5">
                        <h4>Lista de servicios</h4>
                    </div>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-card-text>
                        <v-card-actions> </v-card-actions>
                    </v-card-text>
                </v-list-item-content>
            </v-list-item>

            <!-- Lista de Marcas -->
            <ListProduct :isFromService="true" />
        </v-card>

        <!-- Para Dialogo Editar y Nuevo  -->
        <template>
            <FormProduct />
        </template>
        <!-- Dialogo Para Form Marca llamado de Form Product -->
        <template>
            <FormBrand />
            <FormCategory />
        </template>
    </div>
</template>
<script>
import FormProduct from "./FormProduct.Component.vue";
import FormBrand from "../Brands/FormBrand.Component.vue";
import FormCategory from "../Category/FormCategory.Component.vue";
import ListProduct from "./ListProduct.Component.vue";
export default {
    components: {
        FormProduct,
        ListProduct,
        FormBrand,
        FormCategory,
    },
    methods: {},
};
</script>
