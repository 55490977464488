<template>
    <v-row justify="center">
        <v-dialog
            v-model="paymentDialog"
            @click:outside="setDialogPayment(false)"
            @keydown.esc="setDialogPayment(false)"
            persistent
            max-width="800px"
        >
            <v-form v-model="validMethod" ref="form">
                <v-card style="overflow-x: hidden">
                    <v-row class="pa-4">
                        <v-col lg="8" md="8" sm="12" xs="12">
                            <v-row justify="center" align="center" class="mx-2">
                                <v-col lg="8" md="8" sm="8" xs="8">
                                    <v-autocomplete
                                        v-model="selectedClient"
                                        :filter="filterObjectClient"
                                        :items="clients"
                                        :search-input.sync="buscarCliente"
                                        item-text="ruc"
                                        return-object
                                        cache-items
                                        label="Cliente"
                                        ref="cliente"
                                        clearable
                                        @change="(event) => chengeClient(event)"
                                    >
                                        <!-- En caso que no encuentra -->
                                        <template v-slot:no-data>
                                            <v-sheet
                                                class="d-flex justify-center ma-2"
                                            >
                                                <p>Cliente no registrado</p>
                                            </v-sheet>
                                            <v-sheet
                                                class="d-flex justify-center"
                                            >
                                                <v-btn
                                                    @click="
                                                        setDialog();
                                                        setIsClientForOther();
                                                    "
                                                >
                                                    Nuevo Cliente
                                                </v-btn>
                                            </v-sheet>
                                        </template>

                                        <template slot="item" slot-scope="data">
                                            {{ data.item?.nombre_y_apellido }} -
                                            {{ data.item?.ruc }}
                                        </template>

                                        <template v-slot:selection="data">{{
                                            data.item.ruc
                                        }}</template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col lg="4" md="4" sm="4" xs="4">
                                    <v-checkbox
                                        v-model="sale.factura"
                                        :disabled="habilitarFactura"
                                        label="Factura"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-divider vertical class="my-6" />
                        <v-col lg="4" md="4" sm="12" xs="12">
                            <v-card>
                                <v-list-item three-line>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-if="
                                                !!selectedClient?.nombre_y_apellido
                                            "
                                            ><v-icon dense>mdi-account</v-icon>
                                            {{
                                                selectedClient?.nombre_y_apellido
                                            }}</v-list-item-title
                                        >
                                        <v-list-item-subtitle
                                            v-if="!!selectedClient?.ruc"
                                            ><v-icon dense>mdi-domain</v-icon>
                                            {{
                                                selectedClient?.ruc
                                            }}</v-list-item-subtitle
                                        >
                                        <v-list-item-subtitle
                                            v-if="!!selectedClient?.direccion"
                                            class="mt-2"
                                            ><v-icon dense
                                                >mdi-map-marker</v-icon
                                            >
                                            {{
                                                selectedClient?.direccion
                                            }}</v-list-item-subtitle
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card>
                        </v-col>
                    </v-row>

                    <template class="mx-7">
                        <v-data-table
                            :headers="[
                                { text: 'Metodo', value: 'metodo_pago' },
                                { text: 'Código', value: 'codigo' },
                                {
                                    text: 'Entidad',
                                    value: 'entidad_metodo_pago',
                                },
                                { text: 'Monto', value: 'monto' },
                                {
                                    text: 'Opciones',
                                    value: 'actions',
                                    align: 'center',
                                },
                            ]"
                            :items="paymentReceipt.details"
                            hide-default-footer
                            class="mx-5"
                        >
                            <template v-slot:[`item.monto`]="{ item }">
                                {{
                                    convertMoney(
                                        item.monto,
                                        getCurrencyCode
                                    )
                                }}
                            </template>

                            <!-- Icono Eliminar  -->
                            <template v-slot:[`item.actions`]="{ index }">
                                <v-icon
                                    small
                                    color="red"
                                    @click="eliminarItemDetail(index)"
                                >
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                        <template>
                            <div class="text-center">
                                <v-dialog
                                    v-model="dialogAddMount"
                                    @click:outside="setDialogAddMount"
                                    @keydown.esc="setDialogAddMount"
                                    width="550"
                                >
                                    <v-card>
                                        <v-row class="mx-2 mt-2">
                                            <v-col>
                                                <v-select
                                                    v-model="
                                                        nuevoDetalle.metodo_pago
                                                    "
                                                    label="Metodo de pago"
                                                    :items="metodoPago"
                                                    item-value="method"
                                                    item-text="name"
                                                ></v-select>
                                            </v-col>
                                            <v-col>
                                                <vuetify-money
                                                    v-model.number="
                                                        nuevoDetalle.monto
                                                    "
                                                    label="Monto"
                                                    :options="vmOptions"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row
                                            class="mx-2"
                                            v-if="
                                                nuevoDetalle.metodo_pago !=
                                                'efectivo'
                                            "
                                        >
                                            <v-col>
                                                <v-text-field
                                                    v-model="
                                                        nuevoDetalle.entidad_metodo_pago
                                                    "
                                                    label="Entidad de pago"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    v-model="
                                                        nuevoDetalle.codigo
                                                    "
                                                    label="Código"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="primary"
                                                text
                                                @click="
                                                    setNewDetail(nuevoDetalle)
                                                "
                                            >
                                                Agregar
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </div>
                        </template>
                    </template>
                    <template>
                        <v-btn
                            color="green lighten-2 text-white"
                            @click="setDialogAddMount"
                            class="mt-2 mx-2"
                        >
                            + monto
                        </v-btn>
                    </template>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title
                                class="text-end text-h8 mb-2"
                                :class="
                                    sumPaymentDetails < totalVenta
                                        ? 'red--text'
                                        : 'green--text'
                                "
                            >
                                Total Gs.
                                {{
                                    convertMoney(
                                        sumPaymentDetails,
                                        getCurrencyCode
                                    )
                                }}
                            </v-list-item-title>
                            <v-list-item-subtitle class="text-end text-h8 mb-2">
                                Total a pagar Gs.
                                {{
                                    convertMoney(
                                        totalVenta,
                                        getCurrencyCode
                                    )
                                }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="btnCancelarDialogPaymentSale"
                        >
                            Cancelar
                        </v-btn>

                        <v-btn
                            color="blue darken-1"
                            text
                            :disabled="
                                !(sumPaymentDetails >= totalVenta || isPagando)
                            "
                            :loading="isPagando"
                            @click="pagar()"
                        >
                            Pagar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <FormClientComponent />

        <PrintDialog />
    </v-row>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";

import FormClientComponent from "../Clients/FormClient.Component.vue";
import PrintDialog from "./PrintDialog.Component";
import { convertMoney } from "../../formatos";
import { required } from "../../utils/validate";
import { mapGetters } from "vuex/dist/vuex.common.js";

export default {
    data: () => ({
        dialog: false,
        selectedClient: null,
        buscarCliente: "",
        ticket: null,
        disableFactura: false,
        validMethod: false,
        required,
    }),
    mounted() {
        this.ticket = JSON.parse(localStorage.getItem("ticket"));
        if (this.ticket == null) {
            this.ticket = true;
            localStorage.setItem("ticket", true);
        }
        if (!!this.sale.client) {
            this.selectedClient = { ...this.sale.client };
        }

        this.sale.factura = false;
        this.sale.disableFactura = true;
    },
    watch: {
        buscarCliente(val) {
            if(val){
                this.searchClient(val);
                if (this.isClientForOther) {
                    this.client.nombre_y_apellido = val;
                }
            }
        },
        clientForOther() {
            if (this.isClientForOther) {
                this.selectedClient = this.clientForOther;
                this.clearClientForOther();
            }
        },
        paymentDialog(val) {
            if (!val) {
                this.limpiarCampos();
                this.selectedClient = null;
                this.buscarCliente = "";
                this.sale.client = null;
                this.sale.client_id = null;
                this.sale.cliente = null;
                this.sale.factura = null;
            }
        },
    },
    components: {
        FormClientComponent,
        PrintDialog,
    },
    computed: {
        ...mapState("payment", [
            "paymentDialog",
            "paymentReceipt",
            "metodoPago",
            "dialogAddMount",
            "nuevoDetalle",
            "sumPaymentDetails",
        ]),
        ...mapState("debtor", ["debtorData"]),
        ...mapState("client", ["clients"]),
        ...mapState("debtor", ["debtorData"]),
        ...mapState("sale", [
            "totalVenta",
            "sale",
            "isPagando",
            "selectCurrency",
        ]),
        ...mapState("client", [
            "clients",
            "isClientForOther",
            "client",
            "clientForOther",
        ]),
        ...mapState("app", ["vmOptions"]),
        ...mapGetters("app", ["checkPermiso"]),
        habilitarFactura() {
            return this.selectedClient == null;
        },
        getCurrencyCode(){
            return this.selectCurrency ? this.selectCurrency.codigo : 'PYG';
        }
    },
    methods: {
        ...mapMutations("payment", [
            "setNewDetail",
            "setDialogAddMount",
            "setDialogPayment",
            "setDialogListSale",
            "btnCancelarDialogPaymentSale",
            "limpiarCampos",
        ]),
        ...mapMutations("client", [
            "setDialog",
            "setIsClientForOther",
            "clearClientForOther",
        ]),
        ...mapMutations("sale", [
            "setPaymentReceipt",
            "setIsPagando",
            "setDialogImpresion",
            "setPrintDialog",
            "setPriceForeign",
            "revertPriceForeign",
            "updateTotal",
        ]),
        ...mapActions("payment", ["createPayment", "eliminarItemDetail"]),
        ...mapActions("sale", ["pagoMetodo", "printSale"]),
        ...mapActions("client", ["searchClient"]),
        convertMoney,
        async pagar() {
            this.setPaymentReceipt(this.paymentReceipt);

            if (this.selectedClient != null) {
                this.sale.client ??= { ...this.selectedClient };
                this.sale.client_id ??= this.selectedClient.id;
                this.sale.cliente ??= this.selectedClient.nombre_y_apellido;
            }

            await this.pagoMetodo(); //este queda al final porque se ejecuta si o si
            //this.createPayment();
            this.selectedClient = null;
        },
        filterObjectClient(item, queryText, itemText) {
            return (
                item.nombre_y_apellido
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.ruc
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
        chengeClient(val) {
            this.sale.factura = false;
            this.disableFactura = false;

            this.sale.client = null;
            this.sale.client_id = null;
            this.sale.cliente = null;
            if (val != null) {
                this.sale.client = { ...val };
                this.sale.client_id = val.id;
                this.sale.cliente = val.nombre_y_apellido;
                this.sale.factura = true;
                this.disableFactura = true;

                if (
                    val.nacionalidad !== "Paraguay" &&
                    this.checkPermiso("export_create")
                ) {
                    this.setPriceForeign();
                } else {
                    this.revertPriceForeign();
                }

                this.updateTotal();
            }
        },
    },
};
</script>
