import axios from "axios";

const state = {
    dialog: false,
    edit: false,
    deleteDialog: false,
    isDeleted: false,
    search: "",
    allDropProducts: [],
    arrayStockProduct: [],
    products: [],
    loadingChangeStore: false,
    dropProduct: {
        id: 0,
        cantidad: 0,
        observacion: "",
    },
    headersDropProduct: [
        {
            text: "Producto",
            align: "start",
            value: "product.nombre",
        },
        { text: "Cantidad", value: "cantidad", sortable: false },
        { text: "Deposito", value: "store.nombre", sortable: false },
        { text: "Observaciones", value: "observaciones", sortable: false },
        { text: "Usuario", value:"user.username"},
        { text: "Opciones", value: "actions", sortable: false, width: "150px" },
    ],
};
const actions = {
    getOneDropProduct({ commit }, id) {
        axios
            .get(`/drop_product/${id}`)
            .then((response) => {
                commit("GET_ONE_DROP_PRODUCT", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },

    getAllDropProduct({ commit, dispatch }) {
        axios
            .get(`/drop_products`)
            .then((response) => {
                commit("GET_DROP_PRODUCT", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },

    createDropProduct({ commit, dispatch }, params) {
        this.commit("app/setIsLoading", true);
        axios
            .post("/drop_products", params)
            .then((response) => {
                commit("CREATE_DROP_PRODUCT");
            })
            .then(async () => {
                this.commit(
                    "app/setSnackbarSuccess",
                    `Baja creada correctamente`
                );
                await dispatch("getAllDropProduct");
                await this.dispatch("deposito/getProductsStores", { dep: 1 });
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    `${error.response.data.message}`
                );
            })
            .finally(() => {
                dispatch("getAllDropProduct");
            });
        this.commit("app/setIsLoading", false);
    },

    editDropProduct({ commit, dispatch }, params) {
        axios
            .put(`/drop_products/${params.id}`, params)
            .then((response) => {
                commit("EDIT_DROP_PRODUCT", response.data);
            })
            .then(() => {
                this.commit("app/setSnackbarSuccess", `Editado correctamente`);
                this.dispatch("deposito/getProductsStores", { dep: 1 });
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    `${error.response.data.message}`
                );
            })
            .finally(() => {
                dispatch("getAllDropProduct");
            });
    },

    deleteDropProduct({ commit, dispatch }, params) {
        axios
            .delete(`/drop_products/${params.id}`)
            .then(() => {
                commit("DELETE_DROP_PRODUCT");
            })
            .then(() => {
                this.commit(
                    "app/setSnackbarSuccess",
                    `Eliminado correctamente`
                );

                this.dispatch("deposito/getProductsStores", { dep: 1 });
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    `${error.response.data.message}`
                );
            })
            .finally(() => {
                dispatch("getAllDropProduct");
            });
    },

    getCantProducts({ commit }) {
        axios
            .get("/product_for_sale")
            .then((response) => {
                commit("GET_CANT_PRODUCT", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    `${error.response.data.message}`
                );
            });
    },
    getProducts({ state, commit }, store_id) {
        state.loadingChangeStore = true;
        axios
            .post("/products_for_drop", { store_id })
            .then((response) => {
                commit("GET_PRODUCTS", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    `${error.response.data.message}`
                );
            });
    },
};

const mutations = {
    setDialog(state) {
        state.dialog = !state.dialog;
    },
    setEdit(state) {
        state.edit = !state.edit;
    },
    setDeleteDialog(state) {
        state.deleteDialog = !state.deleteDialog;
    },
    setDropProduct(state, payload) {
        state.dropProduct = payload;
    },
    clearDropProduct(state) {
        state.dropProduct = {
            id: 0,
            cantidad: 0,
            observacion: "",
            product_id: 0,
        };
    },
    setSearch(state, search) {
        state.search = search;
    },
    clearSearch(state) {
        state.search = "";
    },
    setDeleteDialog(state) {
        state.deleteDialog = !state.deleteDialog;
    },
    GET_DROP_PRODUCT(state, payload) {
        state.allDropProducts = payload;
    },
    CREATE_DROP_PRODUCT(state) {
        state.dialog = false;
        state.dropProduct = {};
    },
    EDIT_DROP_PRODUCT(state) {
        state.dialog = false;
        state.edit = false;
    },
    DELETE_DROP_PRODUCT(state) {
        state.dropProduct = {};
    },
    GET_ONE_DROP_PRODUCT(state, payload) {
        state.dropProduct = payload;
    },
    GET_CANT_PRODUCT(state, payload) {
        state.arrayStockProduct = payload;
    },
    GET_PRODUCTS(state, payload) {
        state.products = payload;
        state.loadingChangeStore = false;
    },
};

const getters = {
    getCountProductById: (state) => (id) => {
        let count = 0;
        if (id !== null) {
            let temp = state.arrayStockProduct.find(
                (obj) => obj.stock !== null && obj.stock.product_id === id
            );

            if (temp) {
                return temp.stock.cantidad;
            }
        }

        return count;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
