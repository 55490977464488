<template v-slot:top>
    <div>
        <v-card class="my-10">
            <v-list-item class="">
                <v-card
                    class="mt-n7"
                    color="primary"
                    elevation="10"
                    min-height="80"
                    min-width="80"
                >
                    <v-icon class="ma-5" large dark>mdi-settings</v-icon>
                </v-card>

                <v-list-item-title>
                    <div class="overline mx-10 mt-5">
                        <h4>Lista Parametros</h4>
                    </div>
                </v-list-item-title>
            </v-list-item>

            <!-- Lista de Marcas -->
            <ListParameter class="mt-4" />
        </v-card>

        <!-- Para Dialogo Editar y Nuevo  -->
        <template>
            <FormParameter />
        </template>
    </div>
</template>
<script>
import ListParameter from "./ListParameter.Component.vue";
import FormParameter from "./FormParameter.Component.vue";

import { mapState, mapActions } from "vuex";

export default {
    data: () => ({}),

    components: {
        ListParameter,
        FormParameter,
    },
};
</script>
./ListParameter.Component.vue./FormParameter.Component.vue
