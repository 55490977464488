import axios from "axios";

const state = {
    order: {
        currency_id: 0,
        order_detail: [
            {
                codigo: "PYG",
            },
        ],
    },
    orders: [],
    orderToPos: false,
    missingOrder: [],
    isEditOrder: false,
    dialogFormOrder: false,
    dialogDeleteOrder: false,
    dialogShowOrder: false,
    orderProduct: [],
    IsEditOrder: false,
    total: 0,
    headerOrder: [
        { text: "N. Pedido", value: "numero" },
        { text: "Cliente", value: "client.nombre_y_apellido" },
        { text: "RUC", value: "client.ruc" },
        { text: "Estado", value: "estado", align: "center" },
        { text: "Total", value: "order_detail[0].total" },
        { text: "Fecha", value: "fecha" },
        { text: "Vendedor", value: "seller.username", align: "center" },
        { text: "N° venta", value: "numero_venta", align: "center" },
        {
            text: "N° presupuesto",
            value: "numero_presupuesto",
            align: "center",
        },
        { text: "Opciones", value: "actions", align: "center" },
    ],
    headerOrderShow: [
        { text: "Prod. Nombre", value: "product.nombre" },
        { text: "Prod. Ruc", value: "product.codigo" },
        { text: "Dep.", value: "store.nombre" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Precio Unit.", value: "precio_unitario" },
        { text: "Subtotal", value: "subtotal" },
    ],
    headerOrderProducts: [
        { text: "Prod. Nombre", value: "product.nombre" },
        { text: "Prod. Codigo", value: "product.codigo" },
        { text: "Cantidad", value: "cantidad", align: "center" },
        { text: "Precio Unit.", value: "precio_unitario" },
        { text: "Subtotal", value: "subtotal" },
        { text: "Dep.", value: "store_id" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
};
const actions = {
    getOrders({ commit, dispatch }) {
        axios
            .get("/orders")
            .then((res) => {
                commit("GET_ORDERS", res.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    getOneOrder({ commit }, payload) {
        axios
            .get(`/orders/${payload.id}/edit`)
            .then((res) => {
                commit("GET_ONE_ORDER", res.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    createOrder({ commit, dispatch }, payload) {
        axios
            .post(`/orders`, payload)
            .then((res) => {
                commit("CREATE_ORDER");
                dispatch("getOrders");
                this.commit("app/setSnackbarSuccess", "Operación exitosa");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    editOrder({ commit, dispatch }, payload) {
        axios
            .put(`/orders/${payload.id}`, payload)
            .then((res) => {
                commit("EDIT_ORDER");
                dispatch("getOrders");
                this.commit("app/setSnackbarSuccess", "Operación exitosa");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    deleteOrder({ commit, dispatch }, payload) {
        axios
            .delete(`/orders/${payload.id}`)
            .then(() => {
                commit("DELETE_ORDER");
                dispatch("getOrders");
                this.commit("app/setSnackbarSuccess", "Operación exitosa");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    createOrderToBudget({ commit, dispatch }, payload) {
        axios
            .post(`/budget_to_order/${payload.id}`)
            .then((res) => {
                commit("CREATE_ORDER_TO_BUDGET", res.data);
                this.dispatch("budget/getBudgets", null, { root: true });
                this.commit(
                    "app/setSnackbarSuccess",
                    "Pedido creado correctamente"
                );
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
};
const mutations = {
    setIsEditOrder(state, paylaod) {
        state.isEditOrder = paylaod;
    },
    setDialogFormOrder(state, paylaod) {
        state.dialogFormOrder = paylaod;
    },
    setDialogDeleteOrder(state, paylaod) {
        state.dialogDeleteOrder = paylaod;
    },
    setDialogShowOrder(state, paylaod) {
        state.dialogShowOrder = paylaod;
    },
    setOrderToPos(state, payload) {
        state.orderToPos = payload;
    },
    GET_ORDERS(state, payload) {
        state.orders = payload;
    },
    GET_ONE_ORDER(state, payload) {
        state.order = { ...state.order, ...payload };
        state.order.currency_id = payload.order_detail[0].currency_id;
        state.orderProduct = payload.order_detail;
    },
    CREATE_ORDER(state) {
        state.dialogFormOrder = false;
        state.order = {
            currency_id: 1,
        };
        state.orderProduct = [];
    },
    EDIT_ORDER(state) {
        state.dialogFormOrder = false;
        state.isEditOrder = false;
        state.order = {
            currency_id: 1,
        };
        state.orderProduct = [];
    },
    DELETE_ORDER(state) {
        state.order = {
            currency_id: 1,
        };
    },
    CREATE_ORDER_TO_BUDGET(state, paylaod) {
        if (paylaod.faltantes.length !== 0) {
            state.missingOrder = paylaod;
        }
    },

    addOrderProduct(state, payload) {
        const index = state.orderProduct.findIndex(
            (el) => el.product_id === payload.product_id
        );

        if (index !== -1) {
            let cantidad = state.orderProduct[index].cantidad;
            state.orderProduct[index].cantidad =
                parseInt(cantidad) + parseInt(payload.cantidad);

            state.orderProduct[index].subtotal =
                state.orderProduct[index].cantidad *
                parseInt(payload.precio_unitario);

            return;
        }

        state.orderProduct.push(payload);
    },

    editOrderProduct(state, payload) {
        state.orderProduct.splice(
            state.orderProduct.indexOf(
                (p) => p.product_id === payload.product_id
            ),
            1,
            payload
        );
    },

    deleteOrderProduct(state, paylaod) {
        const { product_id } = paylaod;
        const index = state.orderProduct.findIndex(
            (p) => p.product_id === product_id
        );
        state.orderProduct.splice(index, index >= 0 ? 1 : 0);
    },

    clearOrder(state) {
        state.order = {
            currency_id: 0,
            order_detail: [
                {
                    codigo: "PYG",
                },
            ],
        };
        state.orderProduct = [];
    },
    currencyConversion(state, payload) {
        //metodo llamado desde el watch en listStore, convierte a la moneda seleccionada, desde la moneda principal

        if (payload !== 1) {
            const cotizaciones = this.getters["quote/getQuotes"];

            const currency = cotizaciones.find(
                (co) => co.currency_id === payload
            );

            state.orderProduct.forEach((e) => {
                e.precio_venta /= currency.cotizacion;
                e.precio_mayorista /= currency.cotizacion;
                e.precio_descuento /= currency.cotizacion;
                e.precio_unitario /= currency.cotizacion;
            });
        }
    },
    restoreCurrencyConversion(state, payload) {
        //metodo llamado desde el watch en listStore, revierte la conversion de cualquier moneda a la moneda prinvcipal

        if (payload !== 1) {
            const cotizaciones = this.getters["quote/getQuotes"];

            const currency = cotizaciones.find(
                (co) => co.currency_id === payload
            );

            state.orderProduct.forEach((e) => {
                e.precio_venta *= currency.cotizacion;
                e.precio_mayorista *= currency.cotizacion;
                e.precio_descuento *= currency.cotizacion;
                e.precio_unitario *= currency.cotizacion;
            });
        }
    },

    updateTotal(state, paylaod) {
        state.total = 0;
        state.orderProduct.forEach((e) => {
            e.subtotal = e.precio_unitario * e.cantidad;
            state.total += e.subtotal;
        });
    },
    clearTableOrder(state) {
        state.orderProduct = [];
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
