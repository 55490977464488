import axios from "axios";

const state = {
    bills: [],
    valuesBills: [],
    arqueo: {
        box_id: "0",
    },
};

const actions = {
    async createCashCount({ dispatch, commit }, params) {
        axios
            .post(`cash_counts`, params)
            .then((response) => {
                commit("CREATE_CASH_COUNT", response.data);
                this.commit("app/setSnackbarSuccess", "Operacion exitosa");
            })
            .catch((err) => {
                this.commit("app/setSnackbarAlert", err.message);
            });

        await dispatch("box/comprobarApertura", {}, { root: true });
        await dispatch("box/getBoxes", {}, { root: true });
    },
    async getBillsCashCount({ commit, dispatch }, params) {
        axios.get("currency_bills").then((response) => {
            commit("GET_BILLS_CASHCOUNT", response.data);
        });
    },
};

const mutations = {
    CREATE_CASH_COUNT(state, item) {
        state.arqueo = item;
    },
    GET_BILLS_CASHCOUNT(state, item) {
        state.bills = item;
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
