var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},on:{"click:outside":function($event){_vm.setDialog();
                _vm.resetValidate();
                _vm.clearBrandForOther();
                _vm.resetImage();},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.setDialog();
                _vm.resetValidate();
                _vm.clearBrandForOther();
                _vm.resetImage();}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_vm._v(" "),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre","rules":[_vm.required('Nombre')],"required":""},model:{value:(_vm.brand.nombre),callback:function ($$v) {_vm.$set(_vm.brand, "nombre", $$v)},expression:"brand.nombre"}})],1),_vm._v(" "),_c('v-col',{staticClass:"d-flex align-end",attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-file-input',{attrs:{"accept":"image/*","label":"Selccionar Imagen","rules":_vm.sizeRules,"show-size":"","clearable":""},on:{"change":_vm.onFileChange,"click:clear":_vm.resetImage},model:{value:(_vm.filename),callback:function ($$v) {_vm.filename=$$v},expression:"filename"}})],1),_vm._v(" "),_c('v-col',{staticClass:"d-flex align-end mb-5",attrs:{"cols":"12","sm":"4","md":"4"}},[(_vm.showImg)?_c('v-img',{staticClass:"rounded text-center align-end pa-1",attrs:{"src":_vm.showImg,"height":"100","width":"120","aspect-ratio":"2","contain":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-fab-transition',[_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","dark":"","bottom":"","color":"red"},on:{"click":_vm.resetImage}},'v-btn',attrs,false),on),[_vm._v("Eliminar\n                                                ")])],1)]}}],null,false,1310671506)},[_vm._v(" "),_c('span',[_vm._v("Eliminar Imagen")])])],1):_vm._e()],1)],1)],1),_vm._v(" "),_c('small',[_vm._v("*indicates required field")])],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),(_vm.edit)?_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":function($event){_vm.setDialog();
                            _vm.setEdit();
                            _vm.resetValidate();
                            _vm.resetImage();}}},[_vm._v("\n                        Cerrar\n                    ")]):_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":function($event){_vm.setDialog();
                            _vm.resetValidate();
                            _vm.clearBrandForOther();
                            _vm.resetImage();}}},[_vm._v("\n                        Cerrar\n                    ")]),_vm._v(" "),(_vm.edit)?_c('v-btn',{attrs:{"color":"blue darken-1","outlined":""},on:{"click":function($event){return _vm.validateSubmit(_vm.edit, _vm.brand)}}},[_vm._v("\n                        Editar\n                    ")]):_c('v-btn',{attrs:{"loading":_vm.isLoading,"disabled":!_vm.valid || _vm.isLoading,"color":"blue darken-1","outlined":""},on:{"click":function($event){return _vm.validateSubmit(_vm.edit, _vm.brand)}}},[_vm._v("\n                        Guardar\n                    ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }