<template>
    <v-card class="overflow-hidden">
        <v-navigation-drawer
            id="default-drawer"
            app
            :dark="true"
            :v-model="drawer"
            :mini-variant="mini || $vuetify.breakpoint.mdAndDown"
            mini-variant-width="80"
            permanent
            width="210"
        >
            <template #img="props">
                <v-img v-bind:src="image" :gradient="gradient" v-bind="props" />
            </template>

            <!-- Encabezado Navigation Drawer -->
            <v-list v-if="!mini && !$vuetify.breakpoint.mdAndDown">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6 text-center">
                            IDevPY
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <div class="text-center" high="100%" v-if="!isLoadingPermissions">
                <v-progress-circular
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </div>

            <v-list nav dense>
                <!-- Recorrido de grupo y titulo -->
                <v-list-item-group v-for="(item, i) in items" :key="i">
                    <v-subheader
                        v-if="!mini && !$vuetify.breakpoint.mdAndDown"
                        >{{ item.title }}</v-subheader
                    >

                    <!-- Recorrido de sub grupos -->
                    <v-list-item
                        v-for="(group, i) in item.groups"
                        :key="i"
                        :to="group.to"
                        style="text-decoration: none"
                        active-class="elevation-4 primary"
                        class="py-1 list-hover"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ group.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                group.text
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </v-card>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import imageUrl from "../assets/Sidebar.jpg";

export default {
    data: () => ({
        image: imageUrl,
        selectedItem: null,
    }),
    mounted() {
        if (localStorage.mini != undefined) {
            let localMini = JSON.parse(localStorage.mini);
            if (this.mini != localMini) this.setMini();
        }
        this.getItems();
    },
    computed: {
        ...mapState("app", [
            "mini",
            "drawer",
            "items",
            "gradient",
            "isLoadingPermissions",
        ]),
    },
    watch: {
        mini(val) {
            if (localStorage.mini) {
                let localMini = JSON.parse(localStorage.mini);
                if (val != localMini) localStorage.mini = val;
            } else {
                localStorage.mini = val;
            }
        },
        selectedItem(val) {
            console.log(val);
        },
    },
    methods: {
        ...mapMutations("app", ["setMini"]),
        ...mapActions("app", ["getItems"]),
    },
};
</script>
<style scoped>
.list-hover:hover {
    margin-left: 4px;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}
</style>
