<template>
    <div>
        <v-card class="my-5">
            {{ this.sinDescuetoStock ? "**" : "" }}
            <v-list-item>
                <v-row class="mt-5">
                    <v-col md="7">
                        <v-row>
                            <v-col md="3">
                                <v-select
                                    :items="stores"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Deposito"
                                    @change="changeDeposito"
                                    v-model="selectDeposito"
                                >
                                </v-select>
                            </v-col>
                            <v-col md="3">
                                <v-select
                                    v-model="sale.currency_id"
                                    :items="quotes"
                                    item-text="nombre"
                                    item-value="currency_id"
                                    label="Moneda"
                                    :rules="quoteRules"
                                >
                                </v-select>
                            </v-col>
                            <v-col md="3">
                                <v-select
                                    :items="sellers"
                                    item-text="username"
                                    item-value="user_id"
                                    label="Vendedor"
                                    v-model="sale.seller_id"
                                >
                                </v-select>
                            </v-col>

                            <v-col md="3">
                                <v-btn
                                    class="mt-3"
                                    outlined
                                    :disabled="saleProducts.length < 1"
                                    @click="dialogPago = true"
                                >
                                    pagar
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <AutocompleteProduct
                                    v-model="selectedProduct"
                                    :deposito="selectDeposito"
                                    :agregar="agregar"
                                />
                            </v-col>

                            <v-col md="3" sm="2">
                                <v-text-field
                                    type="number"
                                    v-model="cantidad"
                                    label="Cantidad"
                                    append-outer-icon="mdi-send"
                                    @click:append-outer="agregar()"
                                    ref="cantidad"
                                    @keydown.enter="agregar()"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col md="3">
                                <v-text-field
                                    v-model="productCode"
                                    label="Codigo"
                                    required
                                    @keydown.enter="obtenerPorCodigo"
                                    ref="obtenerPorCodigo"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col md="12">
                                <v-data-table
                                    class="my-4"
                                    :headers="headersProductPOS"
                                    :items="saleProducts"
                                    :items-per-page="-1"
                                    hide-default-footer
                                    no-data-text="Producto no seleccionado"
                                >
                                    <!-- Icono Eliminar  -->
                                    <template
                                        v-slot:[`item.actions`]="{ item }"
                                    >
                                        <v-icon
                                            small
                                            color="red"
                                            @click="abrirDialogEliminar(item)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </template>

                                    <template
                                        v-if="isDescuentoArbitrario"
                                        v-slot:[`item.precio_unitario`]="props"
                                    >
                                        <v-edit-dialog
                                            :return-value.sync="
                                                props.item.precio_unitario
                                            "
                                            @save="
                                                saveArbitraryPrice(props.item)
                                            "
                                        >
                                            {{
                                                convertMoney(
                                                    props.item.precio_unitario,
                                                    selectCurrency.codigo
                                                )
                                            }}
                                            <template v-slot:input>
                                                <v-text-field
                                                    label="Precio"
                                                    v-model="
                                                        props.item
                                                            .precio_unitario
                                                    "
                                                />
                                            </template>
                                        </v-edit-dialog>
                                    </template>

                                    <template
                                        v-else
                                        v-slot:[`item.precio_unitario`]="{
                                            item,
                                        }"
                                    >
                                        <!--este se muestra cuando no es precio arbitrario-->
                                        {{
                                            convertMoney(
                                                item.precio_unitario,
                                                selectCurrency.codigo
                                            )
                                        }}
                                    </template>

                                    <template
                                        v-if="isDescuentoArbitrario"
                                        v-slot:[`item.subtotal`]="props"
                                    >
                                        <v-edit-dialog
                                            :return-value.sync="
                                                props.item.subtotal
                                            "
                                            @save="
                                                saveArbitrarySubtotal(props.item)
                                            "
                                        >
                                            {{
                                                convertMoney(
                                                    props.item.subtotal,
                                                    selectCurrency.codigo
                                                )
                                            }}
                                            <template v-slot:input>
                                                <v-text-field
                                                    label="Precio"
                                                    v-model="
                                                        props.item
                                                            .subtotal
                                                    "
                                                />
                                            </template>
                                        </v-edit-dialog>
                                    </template>

                                    <template
                                        v-else
                                        v-slot:[`item.subtotal`]="{
                                            item,
                                        }"
                                    >
                                        <!--este se muestra cuando no es precio arbitrario-->
                                        {{
                                            convertMoney(
                                                item.subtotal,
                                                selectCurrency.codigo
                                            )
                                        }}
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col md="1">
                        <v-divider vertical />
                    </v-col>
                    <v-col md="4" class="mt-5">
                        <v-row class="justify-center">
                            <v-col md="12">
                                <v-card class="pa-5">
                                    <div class="text-center">
                                        <h3>
                                            Total:
                                            {{
                                                convertMoney(
                                                    totalVenta,
                                                    selectCurrency.codigo
                                                )
                                            }}
                                        </h3>
                                        <h5>ítems: {{ saleProducts.length  }}</h5>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col md="12">
                                <v-card
                                    class="mx-auto"
                                    v-if="!!selectedProduct"
                                >
                                    <v-card-title class="justify-center">
                                        <template v-if="!!selectedProduct.img_url">
                                            <div class="magnifier-container" @mousemove="moveMagnifier" @mouseleave="hideMagnifier">
                                                <v-img
                                                    
                                                    class="ma-1 rounded"
                                                    max-height="200"
                                                    max-width="200"
                                                    :src="selectedProduct.img_url"
                                                    ref="image"
                                                ></v-img>
                                                <div class="magnifier" ref="magnifier" :style="magnifierStyle"></div>
                                            </div>
                                        </template>

                                        <template v-else>

                                        </template>
                                        <v-img
                                            v-else
                                            class="ma-1 rounded"
                                            max-height="200"
                                            max-width="200"
                                            v-bind:src="
                                                require('../../assets/no-image.png')
                                            "
                                        ></v-img>
                                    </v-card-title>
                                    <v-card-title>{{
                                        selectedProduct.producto
                                    }}</v-card-title>

                                    <v-card-text>
                                        <div>
                                            {{ selectedProduct.descripcion }}
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-list-item>
        </v-card>

        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5"
                    >¿Retirar este producto de la venta?</v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="setDialogDelete(false)"
                        >Cancelar</v-btn
                    >
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="eliminarItemCarrito()"
                        >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--
        <template>
            <FormClient></FormClient>
        </template> -->

        <template v-if="payMethod === 0">
            <DialogQuickPaymentComponentVue />
        </template>

        <template v-if="payMethod === 1">
            <DialogCreditPaymentComponentVue />
        </template>

        <template v-if="payMethod === 2">
            <DialogMethodPaymentComponentVue />
        </template>

        <!--DIALOG DESCUENTOS-->
        <template>
            <v-dialog v-model="dialogDescuentos" max-width="1000">
                <v-card>
                    <v-card-text class="mt-3">
                        <v-row align="center" justify="center">
                            <v-col cols="3">
                                <v-btn @click="descuento(0)" class=""
                                    >Precio Descuento</v-btn
                                >
                            </v-col>
                            <v-col cols="3">
                                <v-btn @click="descuento(1)" class=""
                                    >Precio Mayorista</v-btn
                                >
                            </v-col>
                            <v-col cols="3">
                                <v-btn @click="descuento(2)" class=""
                                    >Precio Arbitrario</v-btn
                                >
                            </v-col>
                            <v-col cols="3">
                                <v-btn @click="descuento(3)" class=""
                                    >Precio Normal</v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>

        <!--DIALOG PAGOS-->
        <template>
            <v-dialog v-model="dialogPago" max-width="800">
                <v-card>
                    <v-card-text class="mt-3">
                        <v-row>
                            <v-col md="3">
                                <v-btn @click="pago(0)" class=""
                                    >Pago rápido</v-btn
                                >
                            </v-col>
                            <v-col md="3">
                                <v-btn @click="pago(1)" class=""
                                    >Venta crédito</v-btn
                                >
                            </v-col>
                            <v-col md="3">
                                <v-btn @click="pago(2)" class=""
                                    >Métodos pago</v-btn
                                >
                            </v-col>
                            <v-col md="3">
                                <v-btn @click="pago(3)" class=""
                                    >Presupuesto</v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>

        <template v-if="dialogImpresion && invoice">
            <PreimpresoDoble class="impresion" />
        </template>
        <template v-if="dialogImpresion && !invoice">
            <Preimpreso />
        </template>
        <AvisoAperturaDialog />
        <BudgetComponent />
        <PrintDialog />
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import { convertMoney } from "../../formatos";
import FormClient from "../Clients/FormClient.Component.vue";
import DialogQuickPaymentComponentVue from "../PointOfSale/DialogQuickPayment.Component.vue";
import DialogCreditPaymentComponentVue from "../PointOfSale/DialogCreditPayment.Component.vue";
import DialogMethodPaymentComponentVue from "../PointOfSale/DialogMethodPayment.Component.vue";
import AvisoAperturaDialog from "../AvisoAperturaDialog.vue";
import BudgetComponent from "./Budget.Component.vue";
import Preimpreso from "./Preimpreso.Component.vue";
import PreimpresoDoble from "./PreimpresoDoble.Component.vue";
import PrintDialog from "./PrintDialog.Component";
import AutocompleteProduct from "../AutocompleteProduct.vue";
export default {
    data: () => ({
        productCode: "",
        searchProduct: "",
        selectedProduct: null,
        dialogDescuentos: false,
        isDescuentoArbitrario: false,
        dialogPago: false,
        payMethod: 0,
        invoice: null,
        selectDeposito: 0,
        selectSeller: 0,
        quoteRules: [
            (v) => !!v || "Es necesario seleccionar moneda",
            (v) => v !== 0 || "Es necesario seleccionar moneda",
        ],
    }),
    watch: {
        searchProduct(val) {
            this.buscarProductos({ buscador: val, dep: this.selectDeposito });
        },
        selectedProduct(val) {
            if (val !== null) {
                this.saleProduct = val;
                this.saleProduct.dep = val.store_id;
            }
        },
        selectSeller(val) {
            this.sale.seller_id = val;
        },
        "sale.currency_id"(newVal, oldVal) {
            if (oldVal !== 0) {
                this.setSelectCurrency(
                    this.quotes.find((o) => o.currency_id === newVal)
                );

                this.restoreCurrencyConversion(oldVal); // se llama para revertir la conversion. y volver a la moneda principal
                this.currencyConversion(newVal); // se llama para hacer la conversion a la moneda seleccionada
                this.setVmSuffix(this.selectCurrency.simbolo);
                this.setVmPrecision(this.selectCurrency.decimales);
                this.updateTotal();
            }
        },
    },
    components: {
        FormClient,
        DialogQuickPaymentComponentVue,
        DialogCreditPaymentComponentVue,
        DialogMethodPaymentComponentVue,
        AvisoAperturaDialog,
        BudgetComponent,
        Preimpreso,
        PrintDialog,
        PreimpresoDoble,
        AutocompleteProduct,
    },
    computed: {
        ...mapState("sale", [
            "sale",
            "products",
            "headersProductPOS",
            "saleProducts",
            "saleProduct",
            "loadingSearchProduct",
            "cantidad",
            "dialogDelete",
            "totalVenta",
            "dialogImpresion",
            "selectCurrency",
            "sinDescuetoStock",
        ]),
        ...mapState("deposito", ["stores"]),
        ...mapState("seller", ["sellers"]),
        ...mapState("quote", ["quotes", "quote"]),
        ...mapState("app", ["user", "vmOptions"]),

        ...mapGetters("sale", [
            "getSaleProduct",
            "getCantidad",
            "getSelectCurrency",
        ]),
        ...mapGetters("quote", ["getQuote"]),

        saleProduct: {
            get() {
                return this.getSaleProduct;
            },
            set(newVal) {
                this.setSaleProduct(newVal);
                //if( newVal != '' ) this.$refs.cantidad.focus();
            },
        },
        cantidad: {
            get() {
                return this.getCantidad;
            },
            set(newVal) {
                this.setCantidad(newVal);
            },
        },
    },

    async mounted() {
        this.setProductPage({
            current: 1,
            total: 0,
            perPage: 0,
            lastPage: 0,
            firstPage: 0,
        });

        this.getSellers();
        this.getQuoteCurrency();
        document.addEventListener("keydown", this.keyListener);
        this.invoice = JSON.parse(localStorage.getItem("invoice"));
        this.sale.currency_id =
            this.sale?.currency_id || this.getQuote.currency_id;
        if (this.invoice === null) {
            this.invoice = true;
            localStorage.setItem("invoice", true);
        }
        const tempDep = localStorage.getItem("selectedDeposito"); //obtenet deposito del localStorage
        this.selectDeposito = parseInt(tempDep);
        //comprobar si hay lista de depositos
        if (this.stores.length < 1 || this.selectDeposito == null)
            this.getAllStores();
        this.buscarProductos({ buscador: "", dep: this.selectDeposito }); //carga array de productos con los primeros 40
        this.getClients();
    },

    methods: {
        convertMoney,
        ...mapActions("sale", ["buscarProductos", "searchProductsByCode"]),
        ...mapActions("seller", ["getSellers"]),
        ...mapActions("deposito", ["getAllStores"]),
        ...mapActions("quote", ["getQuoteCurrency"]),
        ...mapActions("client", ["getClients"]),
        ...mapMutations("app", [
            "setSnackbarAlert",
            "setSnackbarInfo",
            "setVmSuffix",
            "setVmPrecision",
        ]),
        ...mapMutations("product", ["setProductPage"]),
        ...mapMutations("sale", [
            "setSaleProduct",
            "setCantidad",
            "agregarAlCarrito",
            "setDialogDelete",
            "eliminarItemCarrito",
            "setDialogQuickPay",
            "descuentoMayorista",
            "descuentoNormal",
            "revertirDescuento",
            "updateTotal",
            "setDialogCreditPay",
            "setBudgetDialog",
            "descuentoArbitrario",
            "descuentoSubtotal",
            "restoreCurrencyConversion",
            "currencyConversion",
            "setSelectCurrency",
            "setSinDescuetoStock",
        ]),
        ...mapMutations("payment", ["setDialogPayment"]),
        obtenerPorCodigo() {
            //busca el producto por codigo
            this.searchProductsByCode({
                codigo: this.productCode,
                dep: this.selectDeposito,
            });
            this.productCode = "";
            this.$refs.obtenerPorCodigo.focus();
        },

        filterObjectProduct(item, queryText, itemText) {
            return (
                (item.producto &&
                    item.producto
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.codigo &&
                    item.codigo
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.descripcion &&
                    item.descripcion
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.marca &&
                    item.marca
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.categoria &&
                    item.categoria
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1)
            );
        },
        agregar() {
            const cot = this.selectCurrency.currency_id === 1;

            const { precio_venta } = this.saleProduct;

            this.saleProduct = {
                ...this.saleProduct,
                precio_unitario: cot
                    ? precio_venta
                    : precio_venta / this.selectCurrency.cotizacion,
                precio_venta: cot
                    ? precio_venta
                    : precio_venta / this.selectCurrency.cotizacion,
            };

            if (this.cantidad > 0) {
                this.agregarAlCarrito();
                this.selectedProduct = null;
            } else {
                this.setSnackbarAlert("No puedes agregar cantidades negativas");
            }
        },
        agregarEnter() {
            this.searchProductsByCode();
        },
        abrirDialogEliminar(item) {
            this.setDialogDelete(true);
            this.saleProduct = item;
        },

        keyListener(e) {
            if ((e.key === "p" || e.key === "P") && e.ctrlKey) {
                e.preventDefault(); // present "Save Page" from getting triggered.
                if (this.saleProducts.length > 0) {
                    this.setDialogQuickPay(true);
                }
            }
            if ((e.key === "o" || e.key === "O") && e.ctrlKey) {
                e.preventDefault();
            }
            if ((e.key === "b" || e.key === "B") && e.ctrlKey) {
                e.preventDefault();
            }
            if ((e.key === "d" || e.key === "D") && e.ctrlKey) {
                e.preventDefault();
                this.dialogDescuentos = true;
                //this.descuentoMayorista();
            }
            if ((e.key === "x" || e.key === "X") && e.ctrlKey) {
                e.preventDefault();
                if (!this.sinDescuetoStock) {
                    this.setSinDescuetoStock(true);
                    this.sale.sds = this.sinDescuetoStock;
                } else {
                    this.setSinDescuetoStock(false);
                    this.sale.sds = this.sinDescuetoStock;
                }
            }
        },
        descuento(tipo) {
            if (this.saleProducts.length > 0) {
                switch (tipo) {
                    case 0:
                        this.descuentoNormal();
                        this.dialogDescuentos = false;
                        break;
                    case 1:
                        this.descuentoMayorista();
                        this.dialogDescuentos = false;
                        break;
                    case 2:
                        this.isDescuentoArbitrario = true;
                        this.dialogDescuentos = false;
                        break;
                    case 3:
                        this.revertirDescuento();
                        this.dialogDescuentos = false;
                        break;
                }
            } else {
                alert("No hay productos en el carrito");
            }
        },
        pago(tipo) {
            this.payMethod = tipo;
            switch (tipo) {
                case 0:
                    this.dialogPago = false;
                    this.setDialogQuickPay(true);
                    break;
                case 1:
                    this.dialogPago = false;
                    this.sale.client = null;
                    this.setDialogCreditPay(true);
                    break;
                case 2:
                    this.dialogPago = false;
                    this.setDialogPayment(true);
                    break;
                case 3:
                    this.dialogPago = false;
                    this.setBudgetDialog(true);
                    break;
            }
            this.isDescuentoArbitrario = false; // al seleccionar un tipo de pago, ya no se puede hacer dto arbitrario
        },
        changeDeposito() {
            //metodo que se llama al cambiar el deposito, cambia en el localStorage
            localStorage.setItem("selectedDeposito", this.selectDeposito);
        },

        saveArbitraryPrice(item) {
            this.descuentoArbitrario(item);
            this.updateTotal();
        },
        saveArbitrarySubtotal(item, amount) {
            this.descuentoSubtotal(item, amount);
            this.updateTotal();
        },
        handleClickSeller() {
            if (!this.sale.seller_id) {
                this.sale.seller_id = this.user.id;
            }
            this.dialogPago = true;
            },
        moveMagnifier(event) {
            console.log(this.$refs.image.getBoundingClientRect());
            /*const img = this.$refs.image;
            const magnifier = this.$refs.magnifier;
            const { left, top, width, height } = img.getBoundingClientRect();

            const x = event.pageX - left - window.pageXOffset;
            const y = event.pageY - top - window.pageYOffset;

            const xPercent = (x / width) * 100;
            const yPercent = (y / height) * 100;

            this.magnifierStyle = {
                display: 'block',
                left: `${x}px`,
                top: `${y}px`,
                backgroundImage: `url(${this.image})`,
                backgroundPosition: `${xPercent}% ${yPercent}%`,
            };*/
        },
        hideMagnifier() {
        this.magnifierStyle.display = 'none';
        }
    },
    beforeDestroy() {
        document.removeEventListener("keydown", this.keyListener);
    },

    //Todo Elegir que componente dejar abierto para que no choquen los datos vuex
    methodPay(payload) {
        this.payMethod = payload;
    },

};
</script>
<style scoped>
.impresion {
    display: none;
    margin: 0;
    padding: 0;
}
.magnifier-container {
  position: relative;
  display: inline-block;
}

.main-image {
  width: 100%;
  height: auto;
}

.magnifier {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #000;
  background-repeat: no-repeat;
  background-size: 200%;
  pointer-events: none;
}
</style>
