<template>
    <v-form ref="form" lazy-validation>
        <v-card height="80%">
            <v-toolbar dark color="primary">
                <router-link to="/budget">
                    <v-icon class="text-info">mdi-arrow-left</v-icon>
                    <v-spacer></v-spacer>
                </router-link>
                <v-toolbar-title class="ml-4"
                    >{{ isEdit }} Presupuesto</v-toolbar-title
                >
            </v-toolbar>
            <v-row class="ma-2">
                <v-col md="8">
                    <!-- row para cliente y btn crear -->
                    <v-row justify="center" align-content="center">
                        <v-col md="5">
                            <AutocompleteClient v-model="selectedClient" />
                        </v-col>
                        <v-col auto>
                            <v-select
                                v-model="budget.currency_id"
                                :items="quotes"
                                item-value="currency_id"
                                item-text="nombre"
                                label="Moneda"
                                :rules="quoteRules"
                            ></v-select>
                        </v-col>

                        <v-col>
                            <v-select
                                v-model="budget.seller_id"
                                :items="sellers"
                                item-value="user_id"
                                item-text="username"
                                label="Vendedor"
                            ></v-select>
                        </v-col>
                        <v-col md="2">
                            <!-- Agregar presupuesto  -->

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        class="mt-3 btn-crear"
                                        outlined
                                        :color="
                                            editBudget ? 'green' : 'primary'
                                        "
                                        :disabled="saleProducts.length < 1"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="isEditBtnFunc"
                                    >
                                        {{ isEdit }}
                                    </v-btn>
                                </template>
                                <span>{{ isEdit }} presupuesto</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>

                    <!-- row para agregar los productos -->
                    <v-row class="mt-n4">
                        <v-col cols="12" md="4">
                            <AutocompleteProduct
                                v-model="selectedProduct"
                                :deposito="selectDeposito"
                            />
                        </v-col>
                        <v-col md="2">
                            <v-text-field
                                type="number"
                                v-model="cantidad"
                                label="Cantidad"
                                append-outer-icon="mdi-send"
                                @click:append-outer="agregar()"
                                ref="cantidad"
                                @keydown.enter="agregar()"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col md="3">
                            <v-text-field
                                v-model="productCode"
                                label="Codigo"
                                required
                                @keydown.enter="obtenerPorCodigo"
                                ref="obtenerPorCodigo"
                            ></v-text-field>
                        </v-col>

                        <!-- seleccionar deposito -->
                        <v-col md="2">
                            <v-select
                                v-model="selectDeposito"
                                :items="stores"
                                label="Deposito"
                                item-text="nombre"
                                item-value="id"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <!-- Table de productos -->
                    <v-row class="mb-4">
                        <v-col md="12">
                            <v-data-table
                                :headers="headersProduct"
                                :items="saleProducts"
                                hide-default-footer
                                :items-per-page="-1"
                                no-data-text="Producto no seleccionado"
                            >
                                <!-- Icono Eliminar  -->
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                        small
                                        color="red"
                                        @click="abrirDialogEliminar(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </template>

                                <template
                                    v-if="isDescuentoArbitrario"
                                    v-slot:[`item.precio_unitario`]="props"
                                >
                                    <v-edit-dialog
                                        :return-value.sync="
                                            props.item.precio_unitario
                                        "
                                        @save="updateTotal()"
                                    >
                                        {{
                                            convertMoney(
                                                item.precio_unitario,
                                                currencyCode
                                            )
                                        }}
                                        <template v-slot:input>
                                            <v-text-field
                                                label="Precio"
                                                v-model="
                                                    props.item.precio_unitario
                                                "
                                            ></v-text-field>
                                        </template>
                                    </v-edit-dialog>
                                </template>

                                <template
                                    v-else
                                    v-slot:[`item.precio_unitario`]="{ item }"
                                >
                                    <!--este se muestra cuando no es precio arbitrario-->
                                    {{
                                        convertMoney(
                                            item.precio_unitario,
                                            currencyCode
                                        )
                                    }}
                                </template>

                                <template v-slot:[`item.subtotal`]="{ item }">
                                    {{
                                        convertMoney(
                                            item.subtotal,
                                            currencyCode
                                        )
                                    }}
                                </template>

                                <template v-slot:[`item.store_id`]="props">
                                    <span>{{
                                        returnNameStore(props.item.store_id)
                                    }}</span>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Detalle de productos -->
                <v-col md="4" class="">
                    <v-row class="justify-center">
                        <v-col md="12">
                            <v-card class="">
                                <v-img
                                    class="ma-1 rounded"
                                    max-height="100"
                                    max-width="100"
                                    :src="saleProduct.img_url"
                                >
                                </v-img>
                                <v-card-title>{{
                                    saleProduct.producto
                                }}</v-card-title>

                                <v-card-text>
                                    <div>
                                        {{ saleProduct.descripcion }}
                                    </div>
                                </v-card-text>

                                <v-card-text>
                                    <h3>
                                        Total:
                                        {{
                                            convertMoney(
                                                totalVenta,
                                                currencyCode
                                            )
                                        }}
                                    </h3>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5"
                    >¿Retirar este producto de la venta?</v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="setDialogDelete(false)"
                        >Cancelar</v-btn
                    >
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="eliminarItemCarrito()"
                        >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <FormClient />
    </v-form>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { convertMoney } from "../../formatos";
import FormClient from "../Clients/FormClient.Component.vue";
import AutocompleteProduct from "../AutocompleteProduct.vue";
import AutocompleteClient from "../AutocompleteClient.Component.vue";

export default {
    data: () => ({
        productCode: "",
        searchProduct: "",
        selectedProduct: "",
        dialogDescuentos: false,
        isDescuentoArbitrario: false,
        dialogPago: false,
        payMethod: 0,
        invoice: null,
        buscarCliente: "",
        selectedClient: null,
        selectDeposito: 0,
        quoteRules: [
            (v) => !!v || "Es necesario seleccionar moneda",
            (v) => v !== 0 || "Es necesario seleccionar moneda",
        ],
        isEditFirstTime: false,
    }),
    watch: {
        selectDeposito(val) {
            this.buscarProductos({ buscador: "", dep: val });
            this.setLocaleStorageStore(val);
        },
        buscarCliente(val) {
            this.searchClient(val);
        },
        clientForOther() {
            if (this.isClientForOther) {
                this.selectedClient = this.clientForOther;
                this.clearClientForOther();
            }
        },
        "budget.client"(val) {
            this.selectedClient = val;
        },
        /*selectedClient(val) {  TODO: CREO QUE ES REDUNDANTE BY EDGAR CANO M.
            this.selectedClient = val;
        },*/
        selectedProduct(val) {
            if (val) {
                this.saleProduct = val;
            }
        },
        "budget.currency_id"(newVal, oldVal) {
            if (oldVal !== 0) {
                this.restoreCurrencyConversion(oldVal); // se llama para revertir la conversion. y volver a la moneda principal
                this.currencyConversion(newVal); // se llama para hacer la conversion a la moneda seleccionada
                this.setVmSuffix(this.currencySymbol);
                this.setVmPrecision(this.currencyDecimal);

                this.updateTotal();
            }
        },
    },
    mounted() {
        this.getAllStores();
        this.getSellers();
        this.selectDeposito = parseInt(this.getLocaleStorageStore);
        if (this.editBudget) {
            this.selectedClient = parseInt(this.budget.client_id);
            this.isEditFirstTime = true;
        } else {
            this.budget.currency_id = 1;
        }
    },
    destroyed() {
        this.isEditFirstTime = false;
        this.clearBudget();
    },
    components: {
        FormClient,
        AutocompleteProduct,
        AutocompleteClient,
    },
    computed: {
        ...mapState("budget", [
            "dialog",
            "headersProduct",
            "editBudget",
            "budget",
        ]),
        ...mapState("deposito", ["stores"]),
        ...mapState("sale", [
            "sale",
            "products",
            "headersProductPOS",
            "saleProducts",
            "saleProduct",
            "loadingSearchProduct",
            "cantidad",
            "dialogDelete",
            "totalVenta",
            "dialogImpresion",
        ]),
        ...mapState("client", [
            "clients",
            "client",
            "clientForOther",
            "isClientForOther",
            "isPagando",
        ]),
        ...mapState("quote", ["quotes"]),
        ...mapState("seller", ["sellers"]),

        ...mapGetters("sale", ["getSaleProduct", "getCantidad"]),
        ...mapGetters("deposito", ["getLocaleStorageStore", "returnNameStore"]),
        ...mapGetters("quote", ["getQuote", "getQuotes"]),

        filteredHeaders() {
            return this.headersProductPOS.filter(
                (header) => header.text !== "Opciones"
            );
        },
        isEdit() {
            return !this.editBudget ? "Crear" : "Editar";
        },
        isEditBtnFunc() {
            return this.editBudget
                ? this.editarPresupesto
                : this.crearPresupuesto;
        },
        saleProduct: {
            get() {
                return this.getSaleProduct;
            },
            set(newVal) {
                this.setSaleProduct(newVal);
                //if( newVal != '' ) this.$refs.cantidad.focus();
            },
        },
        cantidad: {
            get() {
                return this.getCantidad;
            },
            set(newVal) {
                this.setCantidad(newVal);
            },
        },
        currencyCode() {
            const currency_id =
                this.budget.currency_id ?? this.getQuote.currency_id;
            return (
                this.getQuotes.find((o) => o.currency_id === currency_id)
                    ?.codigo || "PYG"
            );
        },
        CurrencySymbol() {
            const currency_id =
                this.budget.currency_id ?? this.getQuote.currency_id;
            return this.getQuotes.find((o) => o.currency_id === currency_id)
                .simbolo;
        },
        currencyDecimal() {
            const currency_id =
                this.budget.currency_id ?? this.getQuote.currency_id;
            return this.getQuotes.find((o) => o.currency_id === currency_id)
                .decimales;
        },
        selectCurrency() {
            return (
                this.getQuotes.find(
                    (o) => o.currency_id === this.budget.currency_id
                ) ?? this.getQuote
            );
        },
    },
    methods: {
        ...mapActions("budget", ["createBudget", "updateBudget"]),
        ...mapActions("sale", ["buscarProductos", "searchProductsByCode"]),
        ...mapActions("client", ["searchClient"]),
        ...mapActions("deposito", ["getAllStores"]),
        ...mapActions("seller", ["getSellers"]),

        ...mapMutations("app", [
            "setSnackbarAlert",
            "setSnackbarInfo",
            "setVmSuffix",
            "setVmPrecision",
        ]),
        ...mapMutations("budget", [
            "setDialog",
            "clearBudget",
            "addBudgetProduct",
        ]),
        ...mapMutations("deposito", ["setLocaleStorageStore"]),
        ...mapMutations("sale", [
            "setSaleProduct",
            "setCantidad",
            "agregarAlCarrito",
            "setDialogDelete",
            "eliminarItemCarrito",
            "updateTotal",
            "setBudgetDialog",
            "restoreCurrencyConversion",
            "currencyConversion",
        ]),

        ...mapMutations({
            setDialogClient: "client/setDialog",
            setIsClientForOther: "client/setIsClientForOther",
            clearClientForOther: "client/clearClientForOther",
        }),
        ...mapMutations("payment", ["setDialogPayment"]),

        filterObjectClient(item, queryText, itemText) {
            return (
                item.nombre_y_apellido
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.ruc
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
        agregar() {
            const cot = this.selectCurrency.currency_id === 1;

            const { precio_venta } = this.saleProduct;

            this.saleProduct = {
                ...this.saleProduct,
                precio_unitario: cot
                    ? precio_venta
                    : precio_venta / this.selectCurrency.cotizacion,
                precio_venta: cot
                    ? precio_venta
                    : precio_venta / this.selectCurrency.cotizacion,
            };

            if (this.cantidad > 0) {
                this.agregarAlCarrito();
                this.selectedProduct = {};
            } else {
                this.setSnackbarAlert(
                    "No puedes agregar cantidades negativas o nulas"
                );
            }
        },
        abrirDialogEliminar(item) {
            this.setDialogDelete(true);
            this.saleProduct = item;
        },

        crearPresupuesto() {
            const newBudget = {
                client_id: this.selectedClient?.id || null,
                products: this.saleProducts,
                currency_id: this.selectCurrency.currency_id,
            };
            this.createBudget(newBudget);
            this.$router.push("/budget");
        },
        editarPresupesto() {
            const editBudget = {
                client_id: this.selectedClient.id,
                products: this.saleProducts,
                id: this.budget.id,
                currency_id: this.selectCurrency.currency_id,
            };
            this.updateBudget(editBudget);
            this.$router.push("/budget");
        },
        convertMoney,
    },
};
</script>
<style scoped>
.btn-crear {
    min-width: 120px;
}
</style>
