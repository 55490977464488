<template>
    <div class="mx-10">
        <!-- Si es posible devolver nombre del cliente -->
        <v-data-table :headers="headerParameter" :items="parameters">
            <template v-slot:top>
                <!-- Encabezado Lista Tabla -->
                <!-- Buscar y Button Agregar -->

                <v-row align="center" justify="space-between">
                    <v-col cols="12" lg="6" md="6" sm="6">
                        <v-text-field
                            v-model="search"
                            label="Buscar"
                            append-icon="mdi-magnify"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="auto"
                        md="auto"
                        sm="auto"
                        v-if="canCreate"
                    >
                        <v-btn
                            color="primary"
                            @click="setDialogFormParams(true)"
                        >
                            <v-icon> mdi-plus </v-icon>
                            Agregar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:[`item.valor`]="{ item }">
                <div class="text-truncate" style="max-width: 200px">
                    {{ item.valor }}
                </div>
            </template>
            <!-- Acciones para eliminar y editar -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon @click="handlerView()">
                    <v-icon small color="green" @click="handlerView(item)">
                        mdi-eye
                    </v-icon>
                </v-btn>
                <v-btn icon @click="editarParametro(item)">
                    <v-icon v-if="canEdit" small color="blue">
                        mdi-pencil
                    </v-icon>
                </v-btn>
                <v-btn icon @click="handlerDelete(item)">
                    <v-icon v-if="canDelete" small color="red">
                        mdi-delete
                    </v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <DeleteDialog
            :dialog="dialogDeleteParams"
            :set-dialog="setDialogDeleteParams"
            @deleted="deleteParameters(parameter.clave)"
        />
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import DeleteDialog from "../DeleteDialog.Component.vue";
export default {
    data: () => ({
        search: "",
    }),
    watch: {},
    components: {
        DeleteDialog,
    },
    mounted() {
        this.getPermissions(this.$route.path);
        this.getParameters();
    },
    computed: {
        ...mapState("app", ["canCreate", "canEdit", "canDelete"]),
        ...mapState("config", [
            "parameter",
            "parameters",
            "headerParameter",
            "dialogDeleteParams",
        ]),
    },
    methods: {
        ...mapActions("app", ["getPermissions"]),
        ...mapActions("config", [
            "getParameters",
            "deleteParameters",
            "getOneParameter",
        ]),
        ...mapMutations("config", [
            "setDialogFormParams",
            "handlerEdit",
            "setDialogDeleteParams",
            "handlerDelete",
        ]),

        editarParametro(payload) {
            this.getOneParameter(payload.clave);
            this.handlerEdit();
        },
    },
};
</script>
