<template>
    <v-autocomplete
        v-model="localSelectedProduct"
        :items="products"
        label="Buscar producto"
        return-object
        :loading="loadingSearchProduct"
        :search-input.sync="searchProduct"
        :filter="filterObjectProduct"
        @change="handleInput()"
        @keydown.enter="agregar()"
        clearable
    >
        <!-- No Encuentra el producto -->
        <template v-slot:no-data>
            <div class="d-flex flex-column align-items-center pt-4">
                <h6>Producto inexistente</h6>
            </div>
        </template>

        <!--   Lista categoria de productos -->
        <template slot="item" slot-scope="data">
            <v-list-item-avatar tile>
                <img :src="data.item.img_url" />
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{ data.item.producto }}</v-list-item-title>
                <v-list-item-subtitle
                    >{{ data.item.codigo }}
                    -
                    {{ data.item.descripcion }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                    >Disponible: {{ data.item.stock }}</v-list-item-subtitle
                >
            </v-list-item-content>
        </template>

        <!-- Producto seleccionado  -->
        <template slot="selection" slot-scope="data">
            <v-list-item-content>
                <v-list-item-title>
                    {{ `${data.item.producto} - ${data.item.codigo}` }}
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    props: ["deposito", "select", "agregar"],
    model: {
        prop: "select",
        event: "blur",
    },
    data: () => ({
        searchProduct: "",
        localSelectedProduct: {},
    }),
    mounted() {
        this.localSelectedProduct = JSON.parse(JSON.stringify(this.select));
    },
    watch: {
        searchProduct(val) {
            this.buscarProductos({ buscador: val, dep: this.deposito });
        },
        localSelectedProduct(val) {
            if (val) {
                this.handleInput(val);
            }
        },
        select(val) {
            if (!val) {
                this.localSelectedProduct = null;
            } else {
                this.localSelectedProduct = this.select;
            }
        },
        deposito(val) {
            this.buscarProductos({ buscador: "", dep: val });
        },
    },
    computed: {
        ...mapState("product", ["products", "loadingSearchProduct"]),
    },

    methods: {
        ...mapActions("product", ["buscarProductos", "getProducts"]),

        handleInput() {
            this.$emit("blur", this.localSelectedProduct);
        },

        filterObjectProduct(item, queryText, itemText) {
            return (
                (item.producto &&
                    item.producto
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.codigo &&
                    item.codigo
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.descripcion &&
                    item.descripcion
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.marca &&
                    item.marca
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.categoria &&
                    item.categoria
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1)
            );
        },
    },
};
</script>

<style scoped></style>
