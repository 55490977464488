import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import html2canvas from "html2canvas";
import printJS from "print-js";
import { convertMoney } from "../../formatos";
import product from "./product";
const { numToText } = require("numero-a-texto");

const state = {
    dialogReportBySeller: false,
    dialogReportAllSeller: false,
    dialogPurchaseReport: false,
    sellerUsers: [],
    loadingVentas: false,
    loadingInformeCompras: false,
    isFacturaInforme: false,
    dialogReportByDep: false,
    loadingFactura: false,
};

const actions = {
    getSellerUsers({ commit }) {
        axios
            .get("seller_list")
            .then((response) => {
                commit("SET_SELLER_USERS", response.data);
            })
            .catch((error) => {
                console.log(error.data);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    getReportBySellerUser({ state, commit }, payload) {
        state.loadingVentas = true;
        axios
            .post("get_report_by_seller_user", payload)
            .then((response) => {
                commit("GENERATE_REPORT_BY_SELLER_USER", response.data);
            })
            .catch((error) => {
                state.loadingVentas = false;
                console.log(error);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    getReportAllSeller({ state, commit }, payload) {
        state.loadingVentas = true;
        axios
            .post("get_report_all_seller", payload)
            .then((response) => {
                commit("GENERATE_REPORT_ALL_SELLER", response.data);
            })
            .catch((error) => {
                state.loadingVentas = false;
                console.log(error.response.data);
                this.commit("app/setSnackbarAlert", error).response.data
                    .message;
            });
    },
    getReportByFactura({ state, commit }, payload) {
        state.loadingVentas = true;
        axios
            .post("get_report_by_factura", payload)
            .then((response) => {
                response.data.fechaDesde = payload.fechaDesde; //TODO: VER PARA QUITAR ESTE
                response.data.fechaHasta = payload.fechaHasta; // Y ESTE JEJE

                commit("GENERATE_REPORT_BY_FACTURA", response.data);
            })
            .catch((error) => {
                state.loadingVentas = false;
                console.log(error);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    getPurchaseReport({ state, commit }, payload) {
        state.loadingInformeCompras = true;
        axios.post("get_purchase_report", payload).then((response) => {
            /* const reporte = {
                fechaDesde: payload.fechaDesde,
                fechaHasta: payload.fechaHasta,
                arrayForTable: response.data,
            };*/
            commit("GENERATE_PURCHASE_REPORT", response.data);
        });
    },
    getInvoiceReport({ state, commit, rootState }, payload) {
        state.loadingFactura = true;
        let temp;
        axios
            .get(`/sales/${payload}/edit`)
            .then(async (response) => {
                //llamar a url de impresion
                // await this.dispatch("config/getOneParameter", "print", {
                //     root: true,
                // });

                temp = { ...response.data };
            })
            .then(() => {
                //asignar valor recibido\

                if (temp.client != null) {
                    if (temp.client.nacionalidad !== "Paraguay") {
                        temp.sale_detail.forEach((e) => {
                            e.product.newIva = "exenta";
                        });
                    }
                }

                const ticket = JSON.parse(localStorage.getItem("ticket"));
                if (temp.invoice_header.length > 0) {
                    const printParameter = localStorage.getItem("print");

                    if (printParameter != null) {
                        commit("printFactura", {
                            productos: temp,
                            params: JSON.parse(printParameter),
                        });
                    }
                } else {
                    const printParameter = localStorage.getItem("hoja_ticket");
                    if (ticket && printParameter != null) {
                        commit("printTicket", {
                            productos: temp,
                            params: JSON.parse(printParameter),
                        });
                    } else {
                        this.commit(
                            "app/setSnackbarInfo",
                            "Ticket no configurada"
                        );
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
};

const mutations = {
    setDialogReportBySeller(state, payload) {
        state.dialogReportBySeller = payload;
    },
    setDialogReportAllSeller(state, payload) {
        state.dialogReportAllSeller = payload;
    },
    setDialogPurchaseReport(state, payload) {
        state.dialogPurchaseReport = payload;
    },
    setDialogReportByDep(state, payload) {
        state.dialogReportByDep = payload;
    },
    SET_SELLER_USERS(state, payload) {
        state.sellerUsers = payload;
    },
    GENERATE_REPORT_BY_SELLER_USER(state, payload) {
        payload.reporte.forEach((element, index) => {
            payload.reporte[index][4] = element[4].toLocaleString("es");
        });
        const doc = new jsPDF("l", "mm", [330, 216]);
        autoTable(doc, {
            startY: 15,
            body: [
                [
                    {
                        content: "Informe de ventas " + payload.usuario,
                        styles: {
                            halign: "center",
                            fontSize: 15,
                        },
                    },
                ],
            ],
            theme: "plain",
        });
        autoTable(doc, {
            startY: 20,
            body: [
                [
                    {
                        content:
                            "Desde: " +
                            payload.fecha_desde.split("-").reverse().join("-"),
                        styles: {
                            halign: "left",
                            fontSize: 11,
                        },
                    },
                    {
                        content:
                            "Hasta:  " +
                            payload.fecha_hasta.split("-").reverse().join("-"),
                        styles: {
                            halign: "right",
                            fontSize: 11,
                        },
                    },
                ],
                [
                    {
                        content:
                            "Cantidad de ventas: " + payload.reporte.length,
                        styles: {
                            halign: "left",
                            fontSize: 11,
                        },
                    },
                    {
                        content:
                            "Total de ventas: " +
                            convertMoney(payload.total, payload.codigo),
                        styles: {
                            halign: "right",
                            fontSize: 11,
                        },
                    },
                ],
            ],
            theme: "plain",
        });

        autoTable(doc, {
            head: [
                [
                    "Nº Venta",
                    "Factura",
                    "Cliente",
                    "Fecha",
                    "Contado",
                    "Total",
                    "Moneda",
                    "Cotización",
                ],
            ],
            body: payload.reporte,
        });
        doc.save(
            "InformeVentas" +
                payload.usuario +
                payload.fecha_desde.split("-").reverse().join("-") +
                "_" +
                payload.fecha_hasta.split("-").reverse().join("-") +
                ".pdf"
        );
        state.loadingVentas = false;
    },
    GENERATE_REPORT_ALL_SELLER(state, payload) {
        payload.ventas.forEach((element, index) => {
            payload.ventas[index][4] = element[4].toLocaleString("es");
        });
        const doc = new jsPDF("l", "mm", [330, 216]);
        doc.setFontSize(15);
        doc.text("Informe de ventas", 145, 20);
        doc.setFontSize(11);
        doc.text(
            "Desde: " + payload.fecha_desde.split("-").reverse().join("-"),
            13,
            26
        );
        doc.text(
            "Hasta:  " + payload.fecha_hasta.split("-").reverse().join("-"),
            13,
            32
        );
        doc.text("Cantidad de ventas: " + payload.ventas.length, 250, 26);
        doc.text(
            "Total de ventas: " +
                convertMoney(payload.total, payload.moneda.codigo),
            250,
            32
        );

        autoTable(doc, {
            startY: 38,
            head: [
                [
                    "Número",
                    "Factura",
                    "Cliente",
                    "Fecha",
                    "Contado",
                    "Total",
                    "Moneda",
                    "Cotización",
                    "Usuario",
                    "Vendedor",
                ],
            ],
            body: payload.ventas,
        });

        doc.save(
            "InformeVentas" +
                payload.fecha_desde.split("-").reverse().join("-") +
                "_" +
                payload.fecha_hasta.split("-").reverse().join("-") +
                ".pdf"
        );
        state.loadingVentas = false;
    },
    GENERATE_PURCHASE_REPORT(state, payload) {
        payload.compras.forEach((element, index) => {
            payload.compras[index][5] = element[5].toLocaleString("es");
        });
        const doc = new jsPDF("l", "mm", [330, 216]);
        doc.setFontSize(15);
        doc.text("Informe de compras", 145, 20);
        doc.setFontSize(11);
        doc.text(
            "Desde: " + payload.fecha_inicio.split("-").reverse().join("-"),
            13,
            26
        );
        doc.text(
            "Hasta:  " + payload.fecha_fin.split("-").reverse().join("-"),
            13,
            32
        );
        doc.text("Cantidad de compras: " + payload.compras.length, 250, 26);
        doc.text(
            "Total de compras: " +
                convertMoney(payload.total, payload.moneda.codigo),
            250,
            32
        );

        autoTable(doc, {
            startY: 38,
            head: [
                [
                    "Factura",
                    "Usuario",
                    "Proveedor",
                    "Fecha",
                    "Credito",
                    "Total",
                    "Moneda",
                    "Cotización",
                ],
            ],
            body: payload.compras,
        });

        doc.save(
            "InformeCompras" +
                payload.fecha_inicio.split("-").reverse().join("-") +
                "_" +
                payload.fecha_fin.split("-").reverse().join("-") +
                ".pdf"
        );
        state.loadingInformeCompras = false;
    },
    GENERATE_REPORT_BY_FACTURA(state, payload) {
        const doc = new jsPDF("l", "mm", [330, 216]);
        /**TITULO DEL INFORME */
        autoTable(doc, {
            startY: 10,
            body: [
                [
                    {
                        content: "Informe de facturas emitidas",
                        styles: {
                            halign: "center",
                            fontSize: 14,
                        },
                    },
                ],
            ],
            theme: "plain",
        });

        autoTable(doc, {
            startY: 24,
            body: [
                [
                    {
                        content:
                            "Desde: " +
                            payload.fechaDesde.split("-").reverse().join("-"),
                        styles: {
                            halign: "left",
                        },
                    },
                    {
                        content: "Cantidad de compras: " + payload.cantidad,
                        styles: {
                            halign: "right",
                        },
                    },
                ],
            ],
            theme: "plain",
        });

        autoTable(doc, {
            startY: 32,
            body: [
                [
                    {
                        content:
                            "Hasta:  " +
                            payload.fechaHasta.split("-").reverse().join("-"),
                        styles: {
                            halign: "left",
                        },
                    },
                    {
                        content:
                            "Total de compras: " +
                            convertMoney(payload.total, payload.codigo_moneda),
                        styles: {
                            halign: "right",
                        },
                    },
                ],
            ],
            theme: "plain",
        });

        /**EMPIEZA  A CREARSE LA LISTA DE FACTURAS */
        let alturaCabecera = 37;
        let alturaDetalle = 0;
        payload.facturas.forEach((factura, index) => {
            /**CABECERA DE LA FACTURA */
            alturaCabecera =
                index === 0
                    ? alturaCabecera + alturaDetalle + 10
                    : alturaDetalle + 10;
            let paginas = 0;
            autoTable(doc, {
                startY: alturaCabecera,
                body: [
                    [
                        {
                            content: "Factura N°: " + factura.factura_numero,
                            styles: {
                                halign: "left",
                                fontSize: 14,
                            },
                        },
                        { content: "" },
                        { content: "" },
                    ],
                    [
                        {
                            content: "Cliente: " + factura.nombre_y_apellido,
                            styles: {
                                halign: "left",
                            },
                        },
                        {
                            content: "Vendedor/a: " + factura.vendedor,
                            styles: {
                                halign: "center",
                            },
                        },
                        {
                            content: "RUC: " + factura.ruc,
                            styles: {
                                halign: "right",
                            },
                        },
                    ],
                    [
                        {
                            content: "Fecha: " + factura.created_at,
                            styles: {
                                halign: "left",
                            },
                        },
                        {
                            content: "Cond. de venta: " + factura.credito,
                            styles: {
                                halign: "center",
                            },
                        },
                        {
                            content: "Total: " + factura.total,
                            styles: {
                                halign: "right",
                            },
                        },
                    ],
                ],
                theme: "plain",
                didDrawPage: function (data) {
                    alturaCabecera = data.cursor.y;
                },
            });

            /**DETALLE DE LA FACTURA */
            autoTable(doc, {
                startY: alturaCabecera + 2,
                head: [
                    [
                        "Cantidad",
                        "Producto",
                        "Precio",
                        "SubTotal",
                        "Moneda",
                        "Cotización",
                    ],
                ],
                body: factura.sale_detail,
                didDrawPage: function (data) {
                    alturaDetalle = data.cursor.y;
                    paginas = data.pageNumber;
                },
            });
            if (
                alturaDetalle + 40 >= 170 &&
                paginas === 1 &&
                index != payload.facturas.length - 1
            ) {
                alturaCabecera = 0;
                alturaDetalle = 0;
                doc.addPage();
            }
        });
        const nombre =
            "InformeVentasxFacturas" +
            payload.fechaDesde.split("-").reverse().join("-") +
            "_" +
            payload.fechaHasta.split("-").reverse().join("-") +
            ".pdf";
        doc.save(nombre);
        state.loadingVentas = false;
    },
    setIsFacturaInforme(state, payload) {
        state.isFacturaInforme = payload;
    },
    budgetPDF(state, payload) {
        const doc = new jsPDF();
        const fechaActual = new Date(); // Obtiene la fecha y hora actual

        const year = fechaActual.getFullYear(); // Obtiene el año actual (en formato AAAA)
        const month = fechaActual.getMonth() + 1; // Obtiene el mes actual (en formato MM), se suma 1 porque los meses empiezan en 0
        const day = fechaActual.getDate(); // Obtiene el día actual (en formato DD)
        const fechaSeparada = `${day}-${month}-${year}`; // Concatena los valores con guiones

        const nombre = payload.client + "_" + fechaSeparada;

        html2canvas(payload.container)
            .then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                doc.addImage(imgData, "PNG", 10, 10, 190, 0);
                doc.save(nombre); // Descargar el documento PDF
            })
            .catch((error) => {
                console.error("Error al generar el PDF", error.response.data);
                this.commit(
                    "app/setSnackbarAlert",
                    "Error al generar el archivo: " +
                        error.response.data.message
                );
            });
    },
    budgetPNG(state, payload) {
        const doc = new jsPDF();
        const fechaActual = new Date(); // Obtiene la fecha y hora actual

        const year = fechaActual.getFullYear(); // Obtiene el año actual (en formato AAAA)
        const month = fechaActual.getMonth() + 1; // Obtiene el mes actual (en formato MM), se suma 1 porque los meses empiezan en 0
        const day = fechaActual.getDate(); // Obtiene el día actual (en formato DD)
        const fechaSeparada = `${day}-${month}-${year}`; // Concatena los valores con guiones

        const nombre = payload.client + "_" + fechaSeparada;

        html2canvas(payload.container)
            .then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                // Crear un enlace de descarga
                const link = document.createElement("a");
                link.href = imgData;
                link.download = nombre; // Nombre del archivo de descarga
                link.click(); // Hacer clic en el enlace para descargar la imagen
            })
            .catch((error) => {
                console.error(
                    "Error al generar el archivo de imagen",
                    error.response.data
                );
                this.commit(
                    "app/setSnackbarAlert",
                    "Error al generar el archivo: " +
                        error.response.data.message
                );
            });
    },

    printFactura(state, payload) {
        state.loadingFactura = false;
        // ---------------- //
        // params devuelve toda la posicion de impresion
        // productos devuelve los array de 10 item de compra
        const { params, productos } = payload;

        var pdf = new jsPDF("p", "mm", [330, 216]);
        pdf.setFontSize(11);
        let count = 0;
        productos.invoice_header.forEach((arraySale) => {
            let doble = 0;
            let index;
            if (count !== 0) {
                pdf.addPage([216, 330], "p");
            }
            for (index = 0; index < 2; index++) {
                const date = new Date(productos.created_at) /// se agrega created_at para que se genere con la fecha de la venta
                    .toLocaleDateString("es-PY", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                    })
                    .split("de");
                pdf.text(date[0], params[0].ancho, params[0].alto + doble);
                pdf.text(date[1], params[0].ancho + 10, params[0].alto + doble);
                pdf.text(date[2], params[0].ancho + 30, params[0].alto + doble);
                pdf.text(
                    productos.client?.ruc,
                    params[1].ancho,
                    params[1].alto + doble
                );
                pdf.text(
                    productos.client?.nombre_y_apellido,
                    params[2].ancho,
                    params[2].alto + doble
                );
                if (productos.credito) {
                    pdf.text("x", params[4].ancho, params[4].alto + doble);
                } else {
                    pdf.text("x", params[3].ancho, params[3].alto + doble);
                }

                if (productos.client?.direccion != null) {
                    pdf.text(
                        productos.client?.direccion,
                        params[5].ancho,
                        params[5].alto + doble
                    );
                }
                let i = 0;
                let iva5 = 0;
                let iva10 = 0;
                let ivaEx = 0;
                arraySale.invoice_detail.forEach((item) => {
                    if (productos.client.nacionalidad === "Paraguay") {
                        if (item.iva === "5%") {
                            iva5 += item.subtotal;
                        }
                        if (item.iva === "10%") {
                            iva10 += item.subtotal;
                        }
                    }
                    if (item.iva === "exenta" || item.newIva === "exenta") {
                        ivaEx += item.subtotal;
                    }
                    i += 5;
                });

                autoTable(pdf, {
                    startY: params[6].alto + doble,
                    theme: "plain",
                    showHead: "never",
                    bodyStyles: {
                        fontStyle: "normal",
                        fontSize: 10,
                    },
                    body: arraySale.arrayForPrint,
                    columnStyles: {
                        0: { cellWidth: params[7].largo, fontSize: 8 },
                        1: {
                            cellWidth: params[8],
                            fontSize: 8,
                            overflow: "ellipsize",
                        },
                        2: { cellWidth: params[9].largo, fontSize: 8 },
                        3: { cellWidth: params[10].largo, fontSize: 8 },
                        4: { cellWidth: params[11].largo, fontSize: 8 },
                        5: { cellWidth: params[12].largo, fontSize: 8 },
                    },
                    styles: {
                        cellPadding: 0.5,
                    },
                    margin: {
                        right: params[6].derecha,
                        left: params[6].izquierda,
                    },
                });

                pdf.text(
                    "                        " +
                        numToText.translate(productos.total).toUpperCase(),
                    params[13].ancho,
                    params[13].alto + doble,
                    {
                        maxWidth: params[13].largo,
                        align: "justify",
                    }
                );
                pdf.text(
                    productos.total.toLocaleString("es-Es", {
                        maximumFractionDigits: 2,
                    }),
                    params[14].ancho,
                    params[14].alto + doble
                );

                //iva
                let ivaTotal = iva5 / 21 + iva10 / 11;
                pdf.text(
                    Math.round(iva5 / 21).toLocaleString("es-Es", {
                        maximumFractionDigits: 2,
                    }),
                    params[15].ancho,
                    params[15].alto + doble
                );
                pdf.text(
                    Math.round(iva10 / 11).toLocaleString("es-Es", {
                        maximumFractionDigits: 2,
                    }),
                    params[16].ancho,
                    params[16].alto + doble
                );
                pdf.text(
                    Math.round(ivaTotal).toLocaleString("es-Es", {
                        maximumFractionDigits: 2,
                    }),
                    params[17].ancho,
                    params[17].alto + doble
                );
                doble += 158;
            }

            count++;
        });

        // -------------------- //
        //Inicio de alineacion para factura

        // ------------------------- //
        //Impresion de la factura
        const data = pdf.output("blob");
        const blobUrl = URL.createObjectURL(data);
        printJS(blobUrl);
    },

    printTicket(state, payload) {
        //Todo configurable taman para papel
        //Todo configurable el tamanho de fuente

        state.loadingFactura = false;
        const empresa = JSON.parse(localStorage.getItem("empresa"));
        const hoja = JSON.parse(localStorage.getItem("hoja_ticket"));
        const mensaje = JSON.parse(localStorage.getItem("mensaje"));

        var pdf = new jsPDF("p", "mm", [hoja.alto, hoja.ancho]);
        const { params, productos } = payload;

        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1; // The month index starts from 0
        const year = date.getFullYear();

        const hour = date.getHours();
        const min = date.getMinutes();

        //Nombre empresa
        pdf.setFontSize(12);
        pdf.text(2, 5, empresa.nombre);
        pdf.setFontSize(8);
        pdf.text(2, 10, empresa.cell);

        //Cliente
        pdf.text(
            2,
            15,
            `Cliente: ${
                productos.client != null
                    ? productos.client.nombre_y_apellido
                    : "Venta Publico"
            }`
        );
        //Ruc
        pdf.text(
            2,
            20,
            `Ruc: ${productos.client != null ? productos.client.ruc : "--"}`
        );
        //fecha emision

        pdf.text(2, 25, `Fecha emision: ${day}/${month}/${year}`);
        pdf.text(2, 30, `Hora emision: ${hour}:${min} `);
        pdf.text(2, 35, `N. Doc: ${productos.numero}`);

        pdf.text(2, 40, `Vendedor: ${productos.seller.username}`);

        let alturaDetalle = 0;

        // todo: Devolver arrayforprint diferente cuando es ticket
        autoTable(pdf, {
            startY: 45,
            margin: {
                left: 0,
            },
            tableWidth: hoja.ancho,
            head: [["Cant.", "Producto", "P. Unit.", "Sub. T."]],
            body: productos.arrayForPrint,
            didDrawPage: function (data) {
                alturaDetalle = data.cursor.y;
            },
            styles: { fontSize: 8, cellPadding: 0.5, halign: "right" },

            theme: "plain",
        });

        const price = new Intl.NumberFormat("es-PY").format(productos.total);

        pdf.text(
            2,
            alturaDetalle + 5,
            `TOTAL: ${price} ${productos.currency.simbolo}`
        );

        pdf.text(2, alturaDetalle + 10, mensaje.datos);

        // ------------------------- //
        //Impresion de la factura
        const data = pdf.output("blob", {
            margin: [0, 10, 0, 10],
        });
        const blobUrl = URL.createObjectURL(data);
        printJS(blobUrl);
    },
};
const getters = {
    getTomorrow() {
        // Obtener la fecha de mañana en formato aaaa-mm-dd
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm = String(today.getMonth() + 1).padStart(2, "0");
        let dd = String(today.getDate() + 1).padStart(2, "0");

        let fecha = `${yyyy}-${mm}-${dd}`;

        return fecha;
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
