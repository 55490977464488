<template>
    <v-dialog
        v-model="dialogCreditPay"
        width="500"
        @click:outside="setDialogCreditPay(false)"
        @keydown.esc="setDialogCreditPay(false)"
    >
        <v-form v-model="valid" ref="form" lazy-validation>
            <v-card>
                <v-card-title class="text-h6 primary text-white justify-center">
                    Venta a crédito
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row justify="center">
                            <v-col md="8">
                                <v-autocomplete
                                    v-model="selectedClient"
                                    :filter="filterObjectClient"
                                    :items="clients"
                                    :search-input.sync="buscarCliente"
                                    :loading="loadingClient"
                                    return-object
                                    clearable
                                    label="Cliente"
                                    ref="cliente"
                                    :rules="[required('Cliente')]"
                                    @change="(e) => changeClient(e)"
                                >
                                    <!-- En caso que no encuentra -->
                                    <template v-slot:no-data>
                                        <v-sheet
                                            class="d-flex justify-center ma-2"
                                        >
                                            <p>Cliente no registrado</p>
                                        </v-sheet>
                                        <v-sheet class="d-flex justify-center">
                                            <v-btn
                                                @click="
                                                    setDialog();
                                                    setIsClientForOther();
                                                "
                                            >
                                                Nuevo Cliente
                                            </v-btn>
                                        </v-sheet>
                                    </template>

                                    <template slot="item" slot-scope="data">
                                        {{ data.item.nombre_y_apellido }} -
                                        {{ data.item.ruc }}
                                    </template>
                                    <template v-slot:selection="data"
                                        >{{ data.item.nombre_y_apellido }} -
                                        {{ data.item.ruc }}</template
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col md="4">
                                <v-checkbox
                                    v-model="sale.factura"
                                    :disabled="habilitarFactura"
                                    label="Factura"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col md="4">
                                <v-text-field
                                    label="Plazo"
                                    ref="plazo"
                                    suffix="días"
                                    v-model="sale.plazo"
                                    type="number"
                                    required
                                    :rules="[required('Plazo')]"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="d-flex justify-center align-center"
                                md="8"
                            >
                                <h4>
                                    Total
                                    {{
                                        convertMoney(
                                            totalVenta,
                                            selectCurrency.codigo
                                        )
                                    }}
                                </h4>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <FormClient />

                <v-divider class="pa-0 ma-0"></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        outlined
                        @click="btnPagoCredito()"
                        :disabled="isPagando"
                        :loading="isPagando"
                    >
                        guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import FormClient from "../Clients/FormClient.Component.vue";
import { convertMoney } from "../../formatos";
import { required, requiredSelection } from "../../utils/validate";
import { mapGetters } from "vuex/dist/vuex.common.js";

export default {
    data: () => ({
        buscarCliente: "",
        selectedClient: null,
        disabledFactura: false,
        required,
        requiredSelection,
        valid: false,
    }),
    mounted() {
        if (!!this.sale.client) {
            this.selectedClient = { ...this.sale.client };
        }
        this.sale.factura = false;
    },
    computed: {
        ...mapState("sale", [
            "dialogCreditPay",
            "sale",
            "isPagando",
            "totalVenta",
            "selectCurrency",
        ]),
        ...mapState("client", [
            "clients",
            "client",
            "clientForOther",
            "isClientForOther",
            "loadingClient",
        ]),
        ...mapGetters("app", ["checkPermiso"]),

        habilitarFactura() {
            return this.selectedClient == null;
        },
    },
    watch: {
        dialogCreditPay: {
            handler: function (val) {
                if (val) {
                    this.disabledFactura = false;
                    setTimeout(() => {
                        this.$refs.cliente.$refs.input.focus();
                    });
                } else {
                    this.buscarCliente = "";
                    this.selectedClient = null;
                    this.montoCobrado = "";
                    this.vuelto = 0;
                    this.sale.client = null;
                    this.sale.client_id = null;
                    this.sale.cliente = null;
                    this.sale.factura = null;
                }
            },
            deep: true,
        },

        buscarCliente(val) {
            if(val){
                this.searchClient(val);
                if (this.isClientForOther) {
                    this.client.nombre_y_apellido = val;
                }
            }
        },
        selectedClient(val) {
            this.disabledFactura = false;
            this.sale.factura = false;
            if (val != null) {
                this.sale.factura = true;
            }
        },
        clientForOther(val) {
            if (this.isClientForOther) {
                this.selectedClient = this.clientForOther;
                this.clearClientForOther();
            }
        },
    },
    components: {
        FormClient,
    },
    methods: {
        convertMoney,
        ...mapActions("client", ["searchClient"]),
        ...mapActions("sale", ["pagoCredito"]),
        ...mapMutations("app", ["setSnackbarInfo"]),
        ...mapMutations("sale", [
            "setDialogCreditPay",
            "setIsPagando",
            "setDialogImpresion",
            "setIsPrint",
            "setPriceForeign",
            "updateTotal",
            "revertPriceForeign",
        ]),
        ...mapMutations("client", [
            "setDialog",
            "setIsClientForOther",
            "clearClientForOther",
        ]),
        btnPagoCredito() {
            if (this.$refs.form.validate()) {
                this.sale.client_id = this.selectedClient.id;
                this.sale.cliente = this.selectedClient.nombre_y_apellidos;
                this.sale.client = { ...this.selectedClient };
                this.sale.credito = true;
                this.sale.pagado = false;

                this.pagoCredito();
            } else {
                this.setSnackbarInfo("Complete los campos");
            }
        },
        filterObjectClient(item, queryText, itemText) {
            return (
                item.nombre_y_apellido
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.ruc
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },
        changeClient(payload) {
            if (
                payload != null &&
                payload.nacionalidad !== "Paraguay" &&
                this.checkPermiso("export_create")
            ) {
                this.setPriceForeign();
            } else {
                this.revertPriceForeign();
            }

            this.updateTotal();
        },
    },
};
</script>
