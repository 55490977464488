var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-10"},[_c('v-data-table',{attrs:{"headers":_vm.headersSale,"items":_vm.sales,"search":_vm.search,"sort-by":"nombre","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:`item.ruc`,fn:function({ item }){return [(item.ruc)?_c('v-sheet',{staticClass:"pa-2 ma-md-2 d-flex flex-column rounded"},[_c('div',{staticClass:"subtitle-2"},[_vm._v("\n                    "+_vm._s(item.nombre_y_apellido)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"body-2"},[_vm._v("\n                    "+_vm._s(item.ruc)+"\n                ")])]):_vm._e()]}},{key:`item.credito`,fn:function({ item }){return [_c('span',[_vm._v("\n                "+_vm._s(_vm.getCreditText(
                        item.credito,
                        item.pagado,
                        item.plazo,
                        item.created_at
                    ))+"\n            ")])]}},{key:`item.factura_numero`,fn:function({ item }){return [_vm._v("\n            "+_vm._s(item.factura_numero === null
                    ? "Sin Factura"
                    : item.factura_numero)+"\n            "),(item.anulado)?_c('span',{staticClass:"text-danger"},[_vm._v("anulado")]):_vm._e()]}},{key:`item.total`,fn:function({ item }){return [_vm._v("\n            "+_vm._s(_vm.convertMoney(item.total, item?.codigo || "PYG"))+"\n        ")]}},{key:`item.created_at`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.fecha))])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.imprimirVenta(item.id)}}},[_vm._v("\n                mdi-printer\n            ")]),_vm._v(" "),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.showSaleDetail(item.id)}}},[_vm._v("\n                mdi-eye\n            ")]),_vm._v(" "),(_vm.canEdit)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){_vm.setDialog();
                    _vm.setEdit();
                    _vm.getEditSale(item);}}},[_vm._v("\n                mdi-pencil\n            ")]):_vm._e(),_vm._v(" "),(_vm.canDelete)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){_vm.localSale = item;
                    _vm.setDeleteDialog();}}},[_vm._v("\n                mdi-delete\n            ")]):_vm._e(),_vm._v(" "),(_vm.isCanCancel && !item.anulado)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.setAnularVenta(item.id)}}},[_vm._v("\n                mdi-cancel\n            ")]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('DeleteDialog',{attrs:{"dialog":_vm.deleteDialog,"setDialog":_vm.setDeleteDialog},on:{"deleted":function($event){return _vm.setDeleteSale(_vm.localSale)}}}),_vm._v(" "),_c('AvisoAperturaDialog'),_vm._v(" "),_c('v-dialog',{staticClass:"ma-0 pa-0",attrs:{"max-width":"500"},model:{value:(_vm.dialogAnular),callback:function ($$v) {_vm.dialogAnular=$$v},expression:"dialogAnular"}},[_c('v-alert',{staticClass:"bg-white",attrs:{"text":"","outlined":"","color":"deep-orange","type":"warning"}},[_vm._v("\n            ¿Desea anular esta venta?\n            "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.anularFactura(_vm.idAnular);
                        _vm.dialogAnular = false;}}},[_vm._v("Anular")])],1)],1)],1),_vm._v(" "),_c('DialogShowSale')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }