<template v-slot:activator="{ on, attrs }">
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-row justify="center">
            <!-- Dialog (Modal Brand) -->
            <v-dialog
                v-model="dialog"
                persistent
                max-width="600px"
                @click:outside="
                    setDialog();
                    resetValidate();
                    clearBrandForOther();
                    resetImage();
                "
                @keydown.esc="
                    setDialog();
                    resetValidate();
                    clearBrandForOther();
                    resetImage();
                "
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Nombre"
                                        v-model="brand.nombre"
                                        :rules="[required('Nombre')]"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    class="d-flex align-end"
                                    cols="12"
                                    sm="8"
                                    md="8"
                                >
                                    <v-file-input
                                        v-model="filename"
                                        accept="image/*"
                                        label="Selccionar Imagen"
                                        :rules="sizeRules"
                                        show-size
                                        clearable
                                        @change="onFileChange"
                                        @click:clear="resetImage"
                                    >
                                    </v-file-input>
                                </v-col>
                                <v-col
                                    class="d-flex align-end mb-5"
                                    cols="12"
                                    sm="4"
                                    md="4"
                                >
                                    <v-img
                                        v-if="showImg"
                                        v-bind:src="showImg"
                                        height="100"
                                        width="120"
                                        class="rounded text-center align-end pa-1"
                                        aspect-ratio="2"
                                        contain
                                    >
                                        <v-tooltip bottom>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-fab-transition>
                                                    <v-btn
                                                        x-small
                                                        dark
                                                        bottom
                                                        color="red"
                                                        v-on="on"
                                                        v-bind="attrs"
                                                        @click="resetImage"
                                                        >Eliminar
                                                    </v-btn>
                                                </v-fab-transition>
                                            </template>
                                            <span>Eliminar Imagen</span>
                                        </v-tooltip>
                                    </v-img>
                                </v-col>
                            </v-row>
                        </v-container>
                        <small>*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="edit"
                            color="red darken-1"
                            outlined
                            @click="
                                setDialog();
                                setEdit();
                                resetValidate();
                                resetImage();
                            "
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            v-else
                            color="red darken-1"
                            outlined
                            @click="
                                setDialog();
                                resetValidate();
                                clearBrandForOther();
                                resetImage();
                            "
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            v-if="edit"
                            color="blue darken-1"
                            outlined
                            @click="validateSubmit(edit, brand)"
                        >
                            Editar
                        </v-btn>
                        <v-btn
                            :loading="isLoading"
                            :disabled="!valid || isLoading"
                            v-else
                            color="blue darken-1"
                            outlined
                            @click="validateSubmit(edit, brand)"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-form>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { required } from "../../utils/validate";

export default {
    data: () => ({
        valid: true,
        sizeRules: [
            (v) =>
                !v ||
                v.size < 2000000 ||
                "No subir archivo superando los 2 MB!",
        ],
        showImg: null,
        filename: null,
        required,
    }),

    computed: {
        //llamada de variables del store
        ...mapState("brand", ["dialog", "edit", "brand", "img"]),
        ...mapState("app", ["isLoading"]),
        //Dependiendo del edit (boolean) cambia el titulo del form
        formTitle() {
            return this.edit === false ? "Nueva Marca" : "Editar Marca";
        },
    },

    watch: {
        dialog: function () {
            if (this.brand.img_url !== null) {
                this.showImg = this.brand.img_url;
                this.setImage(this.brand.img_url);
            } else {
                this.showImg = null;
                this.setImage(null);
            }
            this.resetValidate();
        },
    },
    methods: {
        //llamada de metodos del store
        ...mapMutations("brand", [
            "setDialog",
            "setEdit",
            "clearBrandForOther",
            "setImage",
        ]),
        //llamada de peticiones del store
        ...mapActions("brand", [
            "createBrand",
            "editBrand",
            "getBrands",
            "deleteBrand",
        ]),

        //validar campo requerido
        //Dependiendo del edit (boolean), crea o edita la marca
        //resetea validacion, obtiene las listas marca, borra los campos nombre url
        validateSubmit(edit, brand) {
            brand.img_url = this.img;
            if (this.$refs.form.validate()) {
                if (edit) {
                    this.editBrand(brand);
                } else {
                    this.createBrand(brand);
                }

                this.resetImage();
            }
        },
        //resetea las validaciones activadas
        resetValidate() {
            this.$refs.form.resetValidation();
        },
        resetImage() {
            this.showImg = null;
            this.setImage(null);
            this.filename = null;
        },
        onFileChange(payload) {
            let formData = new FormData();
            const file = payload; //obtener datos de la variable
            if (file) {
                this.showImg = URL.createObjectURL(file); //guardar solo la ruta guardada
                URL.revokeObjectURL(file); // liberar memoria
                formData.append("image", file);
                this.setImage(formData);
            } else {
                this.setImage(null);
            }
        },
    },
};
</script>
