import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import Dashboard from "../page/Dashboard.vue";
import Main from "../page/Main.vue";
import Brand from "../components/Brands/Brands.Component.vue";
import Client from "../components/Clients/Clients.Component.vue";
import Product from "../components/Products/Product.Component.vue";
import Service from "../components/Products/Service.Component.vue";
import Purchase from "../components/Purchases/Purchases.Component.vue";
import Supplies from "../components/Supplies/Supplies.Component.vue";
import Sale from "../components/Sales/Sales.Component.vue";
import Category from "../components/Category/Category.Component.vue";
import Permission from "../components/Permission.vue";
import Rol from "../components/Rol.vue";
import User from "../components/Users.vue";
import Pos from "../components/PointOfSale/PointOfSale.Component.vue";
import Stock from "../components/StockManage.Component.vue";
import Debt from "../components/Debts/Debt.Component.vue";
import DetailsDebt from "../components/Debts/DetailsDebt.Component.vue";
import ListBox from "../components/Box/ListBox.Component.vue";
import BoxDetail from "../components/Box/BoxDetail.vue";
import Report from "../components/Report/Report.Component.vue";
import Creditor from "../components/Creditors/Creditor.Component.vue";
import CreditorDetail from "../components/Creditors/CreditorDetail.Component.vue";
import Configuration from "../components/Configuration.Component.vue";
import Budget from "../components/Budget/Budget.Component.vue";
import FormBudget from "../components/Budget/FormBudget.Component.vue";
import Store from "../components/Store/Store.Component.vue";
import BoxMoney from "../components/Box/MoneyInOut/MoneyList.vue";
import BoxSale from "../components/Box/Sale/Sale.vue";
import BoxPurchase from "../components/Box/Purchase/Purchase.vue";
import BoxPayment from "../components/Box/Pyment/Payment.vue";
import Vehicle from "../components/Vehicle/Vehicle.Component.vue";
import ListVehicle from "../components/Vehicle/ListVehicle.Component.vue";
import Driver from "../components/Driver/Driver.Component.vue";
import ListDriver from "../components/Driver/ListDriver.Component.vue";
import Recipient from "../components/Recipient/Recipient.Component.vue";
import ListRecipient from "../components/Recipient/ListRecipient.Component.vue";
import TransferData from "../components/TransferData/TranferData.Component.vue";
import ListTransferData from "../components/TransferData/ListTransferData.Component.vue";
import Remission from "../components/Remission/Remission.Component.vue";
import Order from "../components/Order/Order.Component.vue";
import FormOrder from "../components/Order/FormOrder.Component.vue";
import ParametersComponent from "../components/Parameter/Parameters.Component.vue";

const routes = [
    {
        path: "/",
        component: Dashboard,
        children: [
            {
                path: "",
                name: "main",
                component: Main,
            },
            {
                path: "/brand",
                name: "marca",
                component: Brand,
            },

            {
                path: "/client",
                name: "cliente",
                component: Client,
            },
            {
                path: "/product",
                name: "producto",
                component: Product,
            },
            {
                path: "/service",
                name: "servicio",
                component: Service,
            },
            {
                path: "/purchase",
                name: "compra",
                component: Purchase,
            },
            {
                path: "/supplier",
                name: "proveedor",
                component: Supplies,
            },
            {
                path: "/sale",
                name: "venta",
                component: Sale,
            },
            {
                path: "/category",
                name: "categoria",
                component: Category,
            },
            {
                path: "/permission",
                name: "permiso",
                component: Permission,
            },
            {
                path: "/role",
                name: "rol",
                component: Rol,
            },
            {
                path: "/user",
                name: "user",
                component: User,
            },
            {
                path: "/pos",
                name: "pos",
                component: Pos,
            },
            {
                path: "/pos/presupuesto",
                name: "budget",
                component: Budget,
            },
            {
                path: "/stock",
                name: "stock",
                component: Stock,
            },
            {
                path: "/debt",
                name: "debt",
                component: Debt,
            },
            {
                path: "/debt/:id/currency/:currency_id",
                name: "detailsDebt",
                component: DetailsDebt,
            },
            {
                path: "/box",
                name: "box",
                component: ListBox,
            },
            {
                path: "/box/:id",
                name: "boxDetail",
                component: BoxDetail,
            },
            {
                path: "/report",
                name: "report",
                component: Report,
            },
            {
                path: "/creditor",
                name: "creditor",
                component: Creditor,
            },
            {
                path: "/creditor/:id/currency/:currency_id",
                name: "creditorDetail",
                component: CreditorDetail,
            },
            {
                path: "/setting",
                name: "configuration",
                component: Configuration,
            },
            {
                path: "/budget",
                name: "presupuestos",
                component: Budget,
            },
            {
                path: "/budget/form",
                name: "form_budget",
                component: FormBudget,
            },
            {
                path: "/store",
                name: "deposito",
                component: Store,
            },
            {
                path: "/box/:id/money_list/currency/:currency_id",
                name: "money_list",
                component: BoxMoney,
            },
            {
                path: "/box/:id/box_sale",
                name: "box_sale",
                component: BoxSale,
            },
            {
                path: "/box/:id/box_purchase",
                name: "box_purchase",
                component: BoxPurchase,
            },
            {
                path: "/box/:id/box_payment",
                name: "box_payment",
                component: BoxPayment,
            },
            {
                path: "/vehicle",
                component: Vehicle,

                children: [
                    {
                        path: "",
                        name: "vehicle",
                        component: ListVehicle,
                    },
                ],
            },
            {
                path: "/driver",
                component: Driver,

                children: [
                    {
                        path: "",
                        name: "driver",
                        component: ListDriver,
                    },
                ],
            },
            {
                path: "/recipient",
                component: Recipient,

                children: [
                    {
                        path: "",
                        name: "recipient",
                        component: ListRecipient,
                    },
                ],
            },
            {
                path: "/transfer_data",
                component: TransferData,

                children: [
                    {
                        path: "",
                        name: "transfer_data",
                        component: ListTransferData,
                    },
                ],
            },
            {
                path: "/remission",
                name: "remission",
                component: Remission,
            },
            {
                path: "/order",
                name: "order",
                component: Order,
            },
            {
                path: "/order/form",
                name: "form_order",
                component: FormOrder,
            },
            {
                path: "/money",
                name: "money",
                component: () =>
                    import("../components/Money/Money.Component.vue"),
            },
            {
                path: "/quote",
                name: "quote",
                component: () =>
                    import("../components/Money/Quote/Quote.Component.vue"),
            },
            {
                path: "/bill",
                name: "bill",
                component: () =>
                    import("../components/Money/Bill/Bill.Component.vue"),
            },
            {
                path: "/parameter",
                name: "parameter",
                component: () =>
                    import("../components/Parameter/Parameters.Component.vue"),
            },
            {
                path: "/product_family",
                name: "product_family",
                component: () =>
                    import(
                        "../components/ProductFamily/ProductFamily.Component.vue"
                    ),
            },
            {
                path: "/product_per_box",
                name: "product_per_box",
                component: () =>
                    import("../components/ProdPerBox/ProdPerBox.Component.vue"),
            },
            {
                path: "/invoice",
                name: "invoice",
                component: () =>
                    import("../components/Invoice/Invoice.Component.vue"),
            },
            {
                path: "*",
                redirect: "/",
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});
router.beforeEach(async (to, from, next) => {
    //TODO SE DEBE AGREGAR VALIDACIONES PARA NAVEGAR

    next();
});

export default router;
