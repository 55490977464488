<template>
    <v-card class="overflow-hidden">
        <v-app-bar app color="white">
            <v-app-bar-nav-icon icon="local_offer" @click="setMini()"
                ><v-icon>{{ appBarIcon }}</v-icon>
            </v-app-bar-nav-icon>

            <v-spacer></v-spacer>

            <v-btn
                active-class="primary white--text"
                to="/"
                icon
                class="mr-2"
                style="text-decoration: none; color: inherit"
            >
                <v-icon>mdi-apps</v-icon>
            </v-btn>

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" class="mr-2">
                        <v-icon> account_circle </v-icon>
                    </v-btn>
                </template>

                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon color="blue"> account_circle </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{
                                    user.name
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    ><span
                                        v-for="role in user.roles"
                                        :key="role.id"
                                        >{{ role.name }}
                                    </span></v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider class="pa-0 ma-0 mx-5"></v-divider>

                    <v-list>
                        <v-list-item link to="setting">
                            <v-list-item-title>
                                <v-icon class="mx-3" color="blue">
                                    mdi-settings
                                </v-icon>
                                <span class="">Configuración</span>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item link>
                            <v-list-item-title @click="logout">
                                <v-icon class="mx-3 text-danger">
                                    logout
                                </v-icon>
                                <span class="">Cerrar Sesión</span>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>
        </v-app-bar>
    </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    data: () => ({
        groupOpened: false,
    }),
    mounted() {
        this.getUser();
    },
    computed: {
        ...mapState("app", ["mini", "user"]),
        appBarIcon() {
            return this.mini ? "mdi-chevron-right" : "mdi-chevron-left";
        },
    },
    methods: {
        ...mapMutations("app", ["setMini"]),
        ...mapActions("app", ["getUser", "logout"]),
    },
};
</script>
