var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"1000px"},on:{"click:outside":function($event){return _vm.closeDialog()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeDialog()}},model:{value:(_vm.showDialogBudget),callback:function ($$v) {_vm.showDialogBudget=$$v},expression:"showDialogBudget"}},[_c('v-card',[_c('v-row',{staticClass:"mx-1",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"auto":"","align-self":"start"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 pb-2"},[_c('v-icon',[_vm._v(" mdi-account ")]),_vm._v("\n                            "+_vm._s(this.budget.client?.nombre_y_apellido || "")+"\n                        ")],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"text-h6 pb-2"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-domain")]),_vm._v("\n                            "+_vm._s(this.budget.client?.ruc || "")+"\n                        ")],1),_vm._v(" "),_c('v-list-item-title',{staticClass:"text-h6"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-calendar")]),_vm._v("\n                            "+_vm._s(_vm.returnDate)+"\n                        ")],1)],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"auto":"","align-self":"start"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 mb-2 text-end"},[_vm._v("\n                            Presupuesto #"+_vm._s(this.budget.numero)+"\n                        ")]),_vm._v(" "),_c('v-list-item-title',{staticClass:"text-h6 text-end"},[_vm._v("\n                            Total:\n                            "+_vm._s(_vm.convertMoney(
                                    _vm.returnTotalPrices,
                                    _vm.budget.cotizacion.codigo || "PYG"
                                ))+"\n                        ")])],1)],1)],1)],1),_vm._v(" "),_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"md":"10"}},[_c('v-divider',{staticClass:"info"})],1)],1),_vm._v(" "),_c('v-col',[(
                    this.budget.estado !== 'Pedido-pendiente' &&
                    this.budget.estado !== 'Vendido'
                )?_c('v-alert',{attrs:{"color":"red lighten-2","close-text":"Close Alert","type":"warning","outlined":"","dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v("\n                Hay productos que no tiene stock suficiente\n            ")]):_vm._e()],1),_vm._v(" "),_c('v-row',{staticClass:"p-0 m-0"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"mx-1",attrs:{"headers":_vm.localShowHeaders,"items":_vm.budget.budget_detail,"item-class":_vm.itemRowBackground,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.codigo`,fn:function(props){return [_c('span',[_vm._v(_vm._s(props.item.product?.codigo))])]}},{key:`item.producto`,fn:function(props){return [_c('span',[_vm._v(_vm._s(props.item.product?.nombre))])]}},{key:`item.precio_unitario`,fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.convertMoney(
                                props.item.precio_unitario,
                                _vm.budget.cotizacion.codigo || "PYG"
                            )))])]}},{key:`item.subtotal`,fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.convertMoney(
                                props.item.cantidad *
                                    props.item.precio_unitario,
                                _vm.budget.cotizacion.codigo || "PYG"
                            )))])]}},{key:`item.store_id`,fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.returnNameStore(props.item.store_id)))])]}}],null,true)})],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"ma-3",attrs:{"disabled":_vm.budget.estado !== 'Vendido' ? false : true,"color":"primary lighten-2","text":"","outlined":""},on:{"click":_vm.goToPointOfSale}},[_vm._v("\n                Vender\n            ")]),_vm._v(" "),_c('v-btn',{staticClass:"ma-3",attrs:{"disabled":(_vm.budget.estado !== 'Pedido-pendiente' ? false : true) ||
                    (_vm.budget.estado !== 'Vendido' ? false : true),"color":"green lighten-2","text":"","outlined":""},on:{"click":function($event){return _vm.handleBudgetToOrder()}}},[_vm._v("\n                Pedir\n            ")]),_vm._v(" "),_c('v-btn',{staticClass:"ma-3",attrs:{"color":"red lighten-2","text":"","outlined":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("\n                Cerrar\n            ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }