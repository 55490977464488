var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},on:{"click:outside":function($event){return _vm.setDialogPayment(false)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.setDialogPayment(false)}},model:{value:(_vm.paymentDialog),callback:function ($$v) {_vm.paymentDialog=$$v},expression:"paymentDialog"}},[_c('v-form',{ref:"form",model:{value:(_vm.validMethod),callback:function ($$v) {_vm.validMethod=$$v},expression:"validMethod"}},[_c('v-card',{staticStyle:{"overflow-x":"hidden"}},[_c('v-row',{staticClass:"pa-4"},[_c('v-col',{attrs:{"lg":"8","md":"8","sm":"12","xs":"12"}},[_c('v-row',{staticClass:"mx-2",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"lg":"8","md":"8","sm":"8","xs":"8"}},[_c('v-autocomplete',{ref:"cliente",attrs:{"filter":_vm.filterObjectClient,"items":_vm.clients,"search-input":_vm.buscarCliente,"item-text":"ruc","return-object":"","cache-items":"","label":"Cliente","clearable":""},on:{"update:searchInput":function($event){_vm.buscarCliente=$event},"update:search-input":function($event){_vm.buscarCliente=$event},"change":(event) => _vm.chengeClient(event)},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-sheet',{staticClass:"d-flex justify-center ma-2"},[_c('p',[_vm._v("Cliente no registrado")])]),_vm._v(" "),_c('v-sheet',{staticClass:"d-flex justify-center"},[_c('v-btn',{on:{"click":function($event){_vm.setDialog();
                                                    _vm.setIsClientForOther();}}},[_vm._v("\n                                                Nuevo Cliente\n                                            ")])],1)]},proxy:true},{key:"item",fn:function(data){return [_vm._v("\n                                        "+_vm._s(data.item?.nombre_y_apellido)+" -\n                                        "+_vm._s(data.item?.ruc)+"\n                                    ")]}},{key:"selection",fn:function(data){return [_vm._v(_vm._s(data.item.ruc))]}}]),model:{value:(_vm.selectedClient),callback:function ($$v) {_vm.selectedClient=$$v},expression:"selectedClient"}})],1),_vm._v(" "),_c('v-col',{attrs:{"lg":"4","md":"4","sm":"4","xs":"4"}},[_c('v-checkbox',{attrs:{"disabled":_vm.habilitarFactura,"label":"Factura"},model:{value:(_vm.sale.factura),callback:function ($$v) {_vm.$set(_vm.sale, "factura", $$v)},expression:"sale.factura"}})],1)],1)],1),_vm._v(" "),_c('v-divider',{staticClass:"my-6",attrs:{"vertical":""}}),_vm._v(" "),_c('v-col',{attrs:{"lg":"4","md":"4","sm":"12","xs":"12"}},[_c('v-card',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[(
                                            !!_vm.selectedClient?.nombre_y_apellido
                                        )?_c('v-list-item-title',[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-account")]),_vm._v("\n                                        "+_vm._s(_vm.selectedClient?.nombre_y_apellido))],1):_vm._e(),_vm._v(" "),(!!_vm.selectedClient?.ruc)?_c('v-list-item-subtitle',[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-domain")]),_vm._v("\n                                        "+_vm._s(_vm.selectedClient?.ruc))],1):_vm._e(),_vm._v(" "),(!!_vm.selectedClient?.direccion)?_c('v-list-item-subtitle',{staticClass:"mt-2"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-map-marker")]),_vm._v("\n                                        "+_vm._s(_vm.selectedClient?.direccion))],1):_vm._e()],1)],1)],1)],1)],1),_vm._v(" "),[_c('v-data-table',{staticClass:"mx-5",attrs:{"headers":[
                            { text: 'Metodo', value: 'metodo_pago' },
                            { text: 'Código', value: 'codigo' },
                            {
                                text: 'Entidad',
                                value: 'entidad_metodo_pago',
                            },
                            { text: 'Monto', value: 'monto' },
                            {
                                text: 'Opciones',
                                value: 'actions',
                                align: 'center',
                            },
                        ],"items":_vm.paymentReceipt.details,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.monto`,fn:function({ item }){return [_vm._v("\n                            "+_vm._s(_vm.convertMoney(
                                    item.monto,
                                    _vm.getCurrencyCode
                                ))+"\n                        ")]}},{key:`item.actions`,fn:function({ index }){return [_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.eliminarItemDetail(index)}}},[_vm._v("\n                                mdi-delete\n                            ")])]}}],null,true)}),_vm._v(" "),[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"550"},on:{"click:outside":_vm.setDialogAddMount,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.setDialogAddMount.apply(null, arguments)}},model:{value:(_vm.dialogAddMount),callback:function ($$v) {_vm.dialogAddMount=$$v},expression:"dialogAddMount"}},[_c('v-card',[_c('v-row',{staticClass:"mx-2 mt-2"},[_c('v-col',[_c('v-select',{attrs:{"label":"Metodo de pago","items":_vm.metodoPago,"item-value":"method","item-text":"name"},model:{value:(
                                                    _vm.nuevoDetalle.metodo_pago
                                                ),callback:function ($$v) {_vm.$set(_vm.nuevoDetalle, "metodo_pago", $$v)},expression:"\n                                                    nuevoDetalle.metodo_pago\n                                                "}})],1),_vm._v(" "),_c('v-col',[_c('vuetify-money',{attrs:{"label":"Monto","options":_vm.vmOptions},model:{value:(
                                                    _vm.nuevoDetalle.monto
                                                ),callback:function ($$v) {_vm.$set(_vm.nuevoDetalle, "monto", _vm._n($$v))},expression:"\n                                                    nuevoDetalle.monto\n                                                "}})],1)],1),_vm._v(" "),(
                                            _vm.nuevoDetalle.metodo_pago !=
                                            'efectivo'
                                        )?_c('v-row',{staticClass:"mx-2"},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Entidad de pago"},model:{value:(
                                                    _vm.nuevoDetalle.entidad_metodo_pago
                                                ),callback:function ($$v) {_vm.$set(_vm.nuevoDetalle, "entidad_metodo_pago", $$v)},expression:"\n                                                    nuevoDetalle.entidad_metodo_pago\n                                                "}})],1),_vm._v(" "),_c('v-col',[_c('v-text-field',{attrs:{"label":"Código"},model:{value:(
                                                    _vm.nuevoDetalle.codigo
                                                ),callback:function ($$v) {_vm.$set(_vm.nuevoDetalle, "codigo", $$v)},expression:"\n                                                    nuevoDetalle.codigo\n                                                "}})],1)],1):_vm._e(),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.setNewDetail(_vm.nuevoDetalle)}}},[_vm._v("\n                                            Agregar\n                                        ")])],1)],1)],1)],1)]],_vm._v(" "),[_c('v-btn',{staticClass:"mt-2 mx-2",attrs:{"color":"green lighten-2 text-white"},on:{"click":_vm.setDialogAddMount}},[_vm._v("\n                        + monto\n                    ")])],_vm._v(" "),_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-end text-h8 mb-2",class:_vm.sumPaymentDetails < _vm.totalVenta
                                    ? 'red--text'
                                    : 'green--text'},[_vm._v("\n                            Total Gs.\n                            "+_vm._s(_vm.convertMoney(
                                    _vm.sumPaymentDetails,
                                    _vm.getCurrencyCode
                                ))+"\n                        ")]),_vm._v(" "),_c('v-list-item-subtitle',{staticClass:"text-end text-h8 mb-2"},[_vm._v("\n                            Total a pagar Gs.\n                            "+_vm._s(_vm.convertMoney(
                                    _vm.totalVenta,
                                    _vm.getCurrencyCode
                                ))+"\n                        ")])],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.btnCancelarDialogPaymentSale}},[_vm._v("\n                        Cancelar\n                    ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!(_vm.sumPaymentDetails >= _vm.totalVenta || _vm.isPagando),"loading":_vm.isPagando},on:{"click":function($event){return _vm.pagar()}}},[_vm._v("\n                        Pagar\n                    ")])],1)],2)],1)],1),_vm._v(" "),_c('FormClientComponent'),_vm._v(" "),_c('PrintDialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }