const state = {
    dialogImpresion: false,
    print: false,
    printDialog: false,
    totalVenta: 0,
    budgetNumber: 0,
    isPrint: false,
    sinDescuetoStock: false,
    loadingCredit: false,
    products: [],
    sales: [
        {
            id: 1,
            factura_numero: "",
            credito: true,
            pagado: true,
            plazo: "",
            client_id: "",
            nacionalidad: "Paraguay",
            client: {
                id: 0,
                nombre_y_apellido: "",
                ruc: "",
            },
            sale_detail: [],
            currency_id: 1,
        },
    ],
    dialog: false,
    dialogDelete: false,
    dialogQuickPay: false,
    dialogCreditPay: false,
    dialogEditarItem: false,
    dialogEliminarItem: false,
    deleteDialog: false,
    dialogShowSale: false,
    budgetDialog: false,
    loadingSearchProduct: false,
    isPagando: false,
    isCanCancel: false,
    isSaved: false,
    paymentReceiptDetail: {},
    saleProduct: {
        codigo: "",
        product_id: "",
        producto: "",
        cantidad: 1,
        precio_unitario: "",
        subtotal: 0,
    },
    saleProducts: [],
    loading: false,
    salesPage: {
        current: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        firstPage: 0,
    },
    sale: {
        factura_numero: "",
        credito: false,
        factura: false,
        plazo: "",
        client_id: "",
        cliente: "",
        currency_id: 1,
        client: null,
        export: false,
        products: [
            {
                cantidad: "",
                precio_unitario: "",
                product_id: "",
                subtotal: "",
                product: {
                    id: "",
                    nombre: "",
                    codigo: "",
                    img_url: "",
                },
            },
        ],
        paymentReceipt: {
            recibo_numero: "",
            details: [],
        },
        moneda: {
            codigo: "PYG",
        },
    },
    headersProduct: [
        { text: "Codigo", value: "product.codigo" },
        { text: "Producto", value: "product.nombre" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Precio Unitario", value: "precio_unitario" },
        { text: "SubTotal", value: "subtotal" },
        { text: "Dep.", value: "store.nombre" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
    headersProductPOS: [
        { text: "Codigo", value: "codigo", align: "center" },
        { text: "Producto", value: "producto", align: "center" },
        { text: "Cantidad", value: "cantidad", align: "center" },
        { text: "Precio Unitario", value: "precio_unitario", align: "center" },
        { text: "SubTotal", value: "subtotal", align: "center" },
        { text: "Opciones", value: "actions", align: "center" },
    ],
    cantidad: 1,
    headersSale: [
        { text: "Número", value: "numero" },
        { text: "Factura número", value: "factura_numero" },
        { text: "Cliente", value: "ruc" },
        //    { text: "Factura", value: "factura"},
        { text: "Credito", value: "credito", align: "center" },
        //{ text: "Plazo", value: "plazo" },
        {
            text: "Total",
            value: "total",
        },
        { text: "Vendedor", value: "seller" },
        { text: "Usuario", value: "username" },
        { text: "Fecha", value: "fecha" },
        {
            text: "Opciones",
            value: "actions",
            sortable: false,
            width: "15%",
        },
    ],
    showSale: {
        id: "",
        factura_numero: "",
        credito: "",
        plazo: "",
        client_id: "",
        sale_detail_sum_precio_unitario: "",
        client: {
            id: "",
            nombre_y_apellido: "",
            ruc: "",
        },
        sale_detail: [
            {
                id: "",
                cantidad: "",
                precio_unitario: "",
                precio_mayorista: "",
                precio_descuento: "",
                purchase_header_id: "",
                product_id: "",
                product: {
                    id: "",
                    nombre: "",
                    codigo: "",
                    img_url: "",
                },
            },
        ],
    },
    itemEditar: {
        product: {
            nombre: "",
        },
    },
    ruta: "",
    selectCurrency: {
        currency_id: 1,
        quotation_id: 1,
        nombre: "Guaranies",
        simbolo: "Gs.",
        codigo: "PYG",
        cotizacion: 1,
    },
};

const actions = {
    getSales({ commit, state }) {
        state.loading = true;
        if (state.ruta === "") commit("setRuta", "/sales");
        axios
            .get(state.ruta)
            .then((response) => {
                commit("GET_SALES", response.data);
            })
            .catch((error) => {
                console.log(error.response.data.message);
            })
            .finally(() => {
                state.loading = false;
            });
    },
    getVentasContado({ commit }, payload) {
        axios
            .get(
                "/boxes/" +
                    payload.box_id +
                    "/ventas_contado/currency/" +
                    payload.currency_id
            )
            .then((response) => {
                commit("GET_SALES", response.data);
            })
            .catch((error) => {
                console.log(error.response.data.message);
            });
    },
    buscarProductos({ state, commit }, payload) {
        state.loadingSearchProduct = true;
        if (state.setTimeoutBuscador) clearTimeout(state.setTimeoutBuscador);
        state.setTimeoutBuscador = setTimeout(function () {
            axios
                .post("/search_products", payload)
                .then((res) => {
                    commit("GET_PRODUCTS", res.data);
                })
                .catch((error) => {
                    console.log("hay error: " + error);
                    this.commit(
                        "app/setSnackbarAlert",
                        error.response.data.message
                    );
                });
            state.loadingSearchProduct = false;
        }, 500);
    },
    editSale({ commit, dispatch, state }) {
        axios
            .put("/sales/" + state.sale.id, state.sale)
            .then((response) => {
                commit("EDIT_SALE", response.data);
            })
            .then((response) => {
                this.commit("app/setSnackbarSuccess", "Operación exitosa");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });

        dispatch("getSales");
    },
    deleteSale({ commit }, payload) {
        axios
            .delete("/sales/" + payload.id)
            .then((response) => {
                this.commit("app/setSnackbarSuccess", "Operación exitosa");
                commit("DELETE_SALE", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    anularFactura({ dispatch }, payload) {
        axios
            .post("/sales/" + payload + "/anular")
            .then((response) => {
                /*this.commit("app/setSnackbarSuccess", "Operación exitosa");
                commit("DELETE_SALE", response.data);*/
                dispatch("getSales");
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    /**SE BUSCA POR CODIGO Y SE INGRESA AL PRESIONAR ENTER (PARA LECTORES DE CODIGO DE BARRA) */
    searchProductsByCode({ commit }, payload) {
        axios
            .post("/search_products_by_code", {
                params: {
                    codigo: payload.codigo,
                    dep: payload.dep,
                },
            })
            .then((res) => {
                if (!!res.data) {
                    res.data.precio_unitario = res.data.precio_venta;
                    commit("setSaleProduct", res.data);
                    commit("agregarAlCarrito");
                } else {
                    this.commit(
                        "app/setSnackbarInfo",
                        "No se encontro el producto"
                    );
                }
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },

    printSale({ state, commit }) {
        let ventana = window.open("", "", "width=800, height=600");
        let fecha = new Date().toLocaleDateString();
        let productos = "";
        state.saleProducts.forEach((e) => {
            productos += `
                <tr>
                    <td>${e.cantidad}</td>
                    <td>${e.producto}</td>
                    <td>${e.precio_unitario}</td>
                    <td>${e.subtotal}</td>
                </tr>
            `;
        });

        ventana.document.write(`
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8" />
                <title>IdevPy</title>
            </head>
            <body>
                <div>
                    <h3> Ticket de compras </h3>
                    <h3>=========================</h3>
                    <h3>Cliente: ${
                        !state.sale.client.id
                            ? "-----------------"
                            : state.sale.client.nombre_y_apellido
                    }</h3>
                    <h3>Ruc: ${
                        !state.sale.client.id
                            ? "-----------------"
                            : state.sale.client.ruc
                    }</h3>
                    <h3>Fecha: ${fecha}</h3>
                    <h3>=========================</h3>

                    <table>
                        <tr>
                            <th>Cantidad</th>
                            <th>Descripcion</th>
                            <th>P Unitario</th>
                            <th>Subtotal</th>
                        </tr>

                        ${productos}

                    </table>
                    <h3>=========================</h3>
                    <h2>Total: ${state.paymentReceiptDetail.monto}</h2>
                    <h3>Metodo Pago: ${
                        state.paymentReceiptDetail.metodo_pago
                    }</h3>
                    </div>
            </body>
            </html>
        `);

        ventana.document.close();
        ventana.focus();
        ventana.print();
        ventana.close();
    },

    async pagoRapido({ state, commit, getters, rootState, rootGetters }) {
        const data = rootGetters["quote/getQuote"].currency_id;

        state.sale.products = { ...state.saleProducts };
        state.sale.credito = false;
        commit("createStructurePaymentSale");
        state.sale.paymentReceipt.details.push(state.paymentReceiptDetail);
        await axios
            .post("/sales", state.sale)
            .then(async (res) => {
                this.commit("app/setSnackbarSuccess", "Operación exitosa");

                if (res.data.invoice_header.length > 0) {
                    const params = localStorage.getItem("print");
                    if (params != null && params.length !== 0) {
                        this.commit("report/printFactura", {
                            productos: res.data,
                            params: JSON.parse(params),
                        });
                    } else {
                        this.commit(
                            "app/setSnackbarWarn",
                            "Facturacion no configurada"
                        );
                    }
                } else {
                    const params = localStorage.getItem("hoja_ticket");
                    if (params != null && params.length !== 0) {
                        this.commit("report/printTicket", {
                            productos: res.data,
                            params: JSON.parse(params),
                        });
                    } else {
                        this.commit(
                            "app/setSnackbarWarn",
                            "Ticket no configurada"
                        );
                    }
                }
            })
            .then(() => {
                commit("limpiarPOS", data);
            })
            .catch(function (error) {
                console.log(error);
                commit("mostrarError", error.response.data.message);
                commit("ERROR_PAGO", data);
            })
            .finally(async () => {
                this.dispatch(
                    "product/buscarProductos",
                    {
                        buscador: "",
                        dep: 1,
                    },
                    { root: true }
                );
            });
    },
    async pagoCredito({ state, getters, commit, rootGetters, rootState }) {
        const currency_id = rootGetters["quote/getQuote"].currency_id;

        const temp = { ...state.sale };
        if (state.sale.client_id) {
            if (state.sale.plazo > 0) {
                state.sale.products = { ...state.saleProducts };

                await axios
                    .post("/sales", state.sale)
                    .then(async (res) => {
                        this.commit(
                            "app/setSnackbarSuccess",
                            "Operación exitosa"
                        );

                        if (res.data.invoice_header.length > 0) {
                            const params = localStorage.getItem("print");
                            const ticket = JSON.parse(
                                localStorage.getItem("ticket")
                            );

                            if (params != null && params.length !== 0) {
                                this.commit("report/printFactura", {
                                    productos: res.data,
                                    params: JSON.parse(params),
                                });
                            } else {
                                this.commit(
                                    "app/setSnackbarWarn",
                                    "Facturacion no configurada"
                                );
                            }
                        } else {
                            const params = localStorage.getItem("hoja_ticket");
                            if (params != null && params.length !== 0) {
                                this.commit("report/printTicket", {
                                    productos: res.data,
                                    params: JSON.parse(params),
                                });
                            } else {
                                this.commit(
                                    "app/setSnackbarWarn",
                                    "Ticket no configurada"
                                );
                            }
                        }
                    })
                    .then(() => {
                        commit("CREDIT_PAY");
                        commit("limpiarPOS", currency_id); //TODO: ver que enviar aca
                    })
                    .catch(function (error) {
                        console.log(error.response);
                        commit("mostrarError", error.response.data.message);
                        commit("ERROR_PAGO", currency_id);
                    })
                    .finally(async () => {
                        this.dispatch(
                            "product/buscarProductos",
                            {
                                buscador: "",
                                dep: 1,
                            },
                            { root: true }
                        );
                    });
            } else {
                this.commit(
                    "app/setSnackbarAlert",
                    `Ingrese el plazo del crédito`
                );
            }
        } else {
            this.commit("app/setSnackbarAlert", `Debe seleccionar un cliente`);
        }
    },

    async pagoMetodo({ state, getters, commit, rootGetters, rootState }) {
        const currency_id = rootGetters["quote/getQuote"].currency_id;

        state.sale.products = { ...state.saleProducts };
        const temp = { ...state.sale };

        await axios
            .post("/sales", state.sale)
            .then(async (res) => {
                this.commit("payment/setDialogPayment");

                if (res.data.invoice_header.length > 0) {
                    const params = localStorage.getItem("print");

                    if (params != null && params.length !== 0) {
                        this.commit("report/printFactura", {
                            productos: res.data,
                            params: JSON.parse(params),
                        });
                    } else {
                        this.commit(
                            "app/setSnackbarWarn",
                            "Facturacion no configurada"
                        );
                    }
                } else {
                    const params = localStorage.getItem("hoja_ticket");
                    if (params != null && params.length !== 0) {
                        this.commit("report/printTicket", {
                            productos: res.data,
                            params: JSON.parse(params),
                        });
                    } else {
                        this.commit(
                            "app/setSnackbarWarn",
                            "Ticket no configurada"
                        );
                    }
                }
                this.commit("app/setSnackbarSuccess", "Operación exitosa");

                commit("METHOD_PAY");
                commit("limpiarPOS", currency_id);
            })
            .catch(function (error) {
                console.log(error);
                commit("mostrarError", error.response.data.message);
                commit("ERROR_PAGO", currency_id);
            })
            .finally(() => {
                this.dispatch(
                    "product/buscarProductos",
                    {
                        buscador: "",
                        dep: 1,
                    },
                    { root: true }
                );
            });
    },
    createBudget({ state, dispatch }, payload) {
        this.commit("app/setIsLoading", true);
        const presupuesto = {
            client_id: payload.client_id,
            credito: payload.credito,
            currency_id: payload.currency_id,
            products: state.saleProducts,
        };
        axios
            .post("/budgets", presupuesto)
            .then(() => {
                this.commit(
                    "app/setSnackbarSuccess",
                    "Presupuesto creado correctamente"
                );
                state.isSaved = true;
                // dispatch("getBudgets");
            })
            .catch((error) => {
                console.log(error);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
        this.commit("app/setIsLoading", false);
    },
    canCancel({ commit }) {
        let hasAccess = false;
        axios
            .get("/permission/sale_cancel")
            .then((response) => {
                hasAccess = true;
                commit("SETISCANCANCEL", hasAccess);
            })
            .catch(() => {
                hasAccess = false;
                commit("SETISCANCANCEL", hasAccess);
            });
    },
    getOneSale({ commit }, payload) {
        axios.get(`/sales/${payload.id}`).then((response) => {
            commit("GET_ONE_SALE", response.data);
            commit("setTotalVentaEditar");
        });
    },
    getEditSale({ commit }, payload) {
        axios.get(`/sales/${payload.id}/edit`).then((response) => {
            commit("GET_ONE_SALE", response.data);
            commit("setTotalVentaEditar");
        });
    },
    getShowSale({ commit }, id) {
        axios.get(`/sales/${id}`).then((response) => {
            commit("setShowSale", response.data);
        });
    },
    getInvoiceNumber({ commit }) {
        axios
            .get("get_last_invoice_number")
            .then((response) => {
                commit("SET_INVOICE_NUMBER", response.data);
            })
            .catch((error) => {
                console.log(error);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    printSaleInvoice({ commit, state }, payload) {
        axios.get(`/sales/${payload}/edit`).then((response) => {
            commit("printSaleInvoiceMut", response.data);
        });
    },
    async getBudgetNumber({ commit }) {
        await axios
            .get("/get_budget_number")
            .then((response) => {
                commit("SET_BUDGET_NUMBER", response.data);
            })
            .catch((error) => {
                console.log(error.response.data.message);
                this.commit(
                    "app/setSnackbarAlert",
                    error.response.data.message
                );
            });
    },
    agregarItem({ state, commit }) {
        let isItem = state.sale.sale_detail.find(
            (x) => x.product_id === state.itemEditar.product_id
        );

        if (state.itemEditar.product.nombre !== "") {
            if (isItem != null) {
                commit("setDialogEditarItem", false);
                this.commit(
                    "app/setSnackbarAlert",
                    "Este producto ya esta agregado, edite en la tabla"
                );
            } else if (
                state.itemEditar.cantidad == null ||
                state.itemEditar.cantidad < 0
            ) {
                commit("setDialogEditarItem", false);
                this.commit(
                    "app/setSnackbarAlert",
                    "Debe ingresar una cantidad válida."
                );
            } else {
                if (state.itemEditar.cantidad <= state.itemEditar.stock) {
                    //controlar si hay stock
                    state.itemEditar.subtotal =
                        parseFloat(state.itemEditar.precio_unitario) *
                        parseFloat(state.itemEditar.cantidad);
                    state.sale.sale_detail.push(state.itemEditar);
                    commit("setTotalVentaEditar");
                    state.itemEditar = {
                        product: {
                            nombre: "",
                        },
                    };
                    state.dialogEditarItem = false;
                } else {
                    commit("setDialogEditarItem", false);
                    this.commit(
                        "app/setSnackbarAlert",
                        `Stock insuficiente. Disponible:  ${state.itemEditar.stock}`
                    );
                }
            }
        } else {
            commit("setDialogEditarItem", false);
            this.commit("app/setSnackbarAlert", "Seleccione el producto");
        }
    },
    /** este metodo es llamado desde el watch a traves de una variable en el autocmplete y tambien al momento de hacer click en el boton editar dentro de la tabla editar que está en dialog editar*/
    setItemEditar({ state }, payload) {
        if (payload.isFromAutocomplete) {
            //se consulta desde donde se envia el objeto porque son diferentes las propiedades
            state.itemEditar.product_id = payload.payload.product_id;
            state.itemEditar.stock = payload.payload.stock;
            state.itemEditar.product.nombre = payload.payload.producto;
            state.itemEditar.product.codigo = payload.payload.codigo;
            state.itemEditar.img_url = payload.payload.img_url;
            state.itemEditar.descripcion = payload.payload.descripcion;
            state.itemEditar.marca = payload.payload.marca;
            state.itemEditar.categoria = payload.payload.categoria;
            state.itemEditar.precio_venta = payload.payload.precio_venta;
            state.itemEditar.precio_mayorista =
                payload.payload.precio_mayorista;
            state.itemEditar.precio_descuento =
                payload.payload.precio_descuento;
            state.itemEditar.precio_unitario = payload.payload.precio_venta;
        } else {
            state.itemEditar = payload.payload;
            axios
                .get("/search_products_by_code", {
                    /* se consulta el stock a la base de datos */
                    params: {
                        codigo: state.itemEditar.product.codigo,
                    },
                })
                .then((res) => {
                    state.itemEditar.stock = res.data.stock;
                })
                .catch((error) => {
                    this.commit(
                        "app/setSnackbarAlert",
                        error.response.data.message
                    );
                });
        }
        if (payload.index) state.itemEditar.index = payload.index;
        payload = {};
    },
    editarItem({ state, commit }) {
        if (
            parseFloat(state.itemEditar.stock) >=
            parseFloat(state.itemEditar.cantidad)
        ) {
            //se consulta si hay stock
            state.itemEditar.subtotal =
                state.itemEditar.cantidad * state.itemEditar.precio_unitario;

            state.sale.sale_detail.splice(
                state.itemEditar.index,
                1,
                state.itemEditar
            );
            state.dialogEditarItem = false;
            commit("setTotalVentaEditar");
            state.itemEditar = {
                product: {
                    producto: "",
                },
            };
        } else {
            commit("setDialogEditarItem", false);

            this.commit(
                "app/setSnackbarAlert",
                `Stock insuficiente. Cantidad disponible: ${state.itemEditar.stock}`
            );
        }
    },
    eliminarItem({ state, commit }, index) {
        state.sale.sale_detail.splice(index, 1);
        state.dialogEliminarItem = false;
        commit("setTotalVentaEditar");
    },
};

const mutations = {
    setDialog(state) {
        state.dialog = !state.dialog;
    },
    setEdit(state) {
        state.edit = !state.edit;
    },
    setIsPrint(state, paylaod) {
        state.isPrint = paylaod;
    },
    GET_PRODUCTS(state, products) {
        state.products = products;
    },
    setSaleProduct(state, payload) {
        state.saleProduct = payload;
    },
    setCantidad(state, payload) {
        state.cantidad = payload;
    },
    setDialogDelete(state, payload) {
        state.dialogDelete = payload;
    },
    setDialogQuickPay(state, payload) {
        state.dialogQuickPay = payload;
    },
    setDialogCreditPay(state, payload) {
        state.dialogCreditPay = payload;
    },
    setPaymentReceiptDetail(state, payload) {
        state.paymentReceiptDetail = payload;
    },
    setDialogImpresion(state, payload) {
        state.dialogImpresion = payload;
    },
    setDialogEditarItem(state, payload) {
        state.dialogEditarItem = payload;
    },
    setDialogEliminarItem(state, payload) {
        state.dialogEliminarItem = payload;
    },
    setDialogShowSale(state, payload) {
        state.dialogShowSale = payload;
    },
    setBudgetDialog(state, payload) {
        state.budgetDialog = payload;
    },
    setIsPagando(state, payload) {
        state.isPagando = payload;
    },
    setIsSaved(state, payload) {
        state.isSaved = payload;
    },
    agregarAlCarrito(state) {
        if (state.saleProduct.product_id && state.cantidad > 0) {
            if (
                state.cantidad <= parseFloat(state.saleProduct.stock) ||
                state.saleProduct.stock_ilimitado == true
            ) {
                //comprueba si hay en stock
                const index = state.saleProducts.findIndex((object) => {
                    return object.product_id === state.saleProduct.product_id;
                });
                if (index > -1) {
                    //comprueba si ya está cargado el producto a agregar al carrito para sumar la cantidad
                    let tempItem = Object.assign({}, state.saleProducts[index]);

                    if (
                        parseFloat(state.cantidad) +
                            parseFloat(tempItem.cantidad) >
                        parseFloat(tempItem.stock)
                    ) {
                        this.commit(
                            "app/setSnackbarAlert",
                            `Stock insuficiente. Cantidad disponible: ${state.saleProduct.stock}`
                        );
                    } else {
                        tempItem.cantidad =
                            parseFloat(state.cantidad) +
                            parseFloat(tempItem.cantidad);
                        tempItem.subtotal =
                            parseFloat(tempItem.precio_unitario) *
                            parseFloat(tempItem.cantidad);
                        state.saleProducts.splice(index, 1, tempItem);
                        state.totalVenta = 0;

                        state.saleProducts.forEach((p) => {
                            state.totalVenta += p.subtotal;
                        });
                    }
                    tempItem = {};
                } else {
                    //si no está cargado se agrega como nuevo producto al carrito

                    state.saleProduct.cantidad = state.cantidad;
                    state.saleProduct.subtotal =
                        state.cantidad * state.saleProduct.precio_venta;

                    state.saleProducts.push(state.saleProduct);
                    state.totalVenta = 0;
                    state.saleProducts.forEach((p) => {
                        state.totalVenta += p.subtotal;
                    });
                }

                state.cantidad = 1;
                state.saleProduct = {};
            } else {
                this.commit(
                    "app/setSnackbarAlert",
                    `Stock insuficiente. Cantidad disponible: ${state.saleProduct.stock}`
                );
            }
        } else {
            this.commit("app/setSnackbarAlert", "Rellene todos los campos");
        }
    },
    eliminarItemCarrito(state) {
        const index = state.saleProducts.findIndex((object) => {
            return object.product_id === state.saleProduct.product_id;
        });
        state.saleProducts.splice(index, 1);
        state.totalVenta = 0;
        state.saleProducts.forEach((p) => {
            state.totalVenta += p.subtotal;
        });
        state.dialogDelete = false;
        state.saleProduct = {};
    },
    GET_SALES(state, sales) {
        state.sales = sales;
        state.salesPage = {
            total: sales.total,
            perPage: sales.per_page,
            current: sales.current_page,
            lastPage: sales.last_page,
            firstPage: sales.first_page,
        };
    },
    GET_ONE_SALE(state, sale) {
        state.sale = sale;
        if (state.sale.client_id === null) {
            state.sale.client = {
                id: null,
                nombre_y_apellido: "",
                ruc: "",
            };
        }
        state.saleProducts = sale.sale_detail;
        state.saleProducts = [];
    },
    setShowSale(state, showSale) {
        state.sale = showSale;
    },
    setHeadersProductsOptions(state, payload) {
        //funcion que agrega o quita la cabecera opciones a las tablas
        if (payload && state.headersProduct.length == 5)
            state.headersProduct.push({
                text: "Opciones",
                value: "actions",
                align: "center",
            });
        if (!payload && state.headersProduct.length == 6)
            state.headersProduct.splice(-1);
    },
    setTotalVentaEditar(state) {
        state.totalVenta = 0;
        state.sale.sale_detail.forEach((e) => {
            state.totalVenta += parseFloat(e.subtotal);
        });
    },
    cancelarEditar(state) {
        state.dialog = false;
        state.sale = {
            factura_numero: "",
            credito: false,
            plazo: "",
            client_id: "",
            cliente: "",
            currency_id: 1,
            client: {
                nombre_y_apellido: "",
            },
            products: [
                {
                    cantidad: "",
                    precio_unitario: "",
                    product_id: "",
                    subtotal: "",
                    product: {
                        id: "",
                        nombre: "",
                        codigo: "",
                        img_url: "",
                    },
                },
            ],
            paymentReceipt: {
                recibo_numero: "",
                details: [],
            },
        };
    },
    setCancelarEditItem(state) {
        state.dialogEditarItem = false;
        state.itemEditar = {
            product: {
                nombre: "",
            },
        };
    },
    limpiarPOS(state, data = {}) {
        state.sale = {
            factura_numero: "",
            credito: false,
            plazo: "",
            client_id: "",
            cliente: "",
            factura: false,
            nacionalidad: "Paraguay",
            currency_id: data, //debe ser currency_id y no un objeto
            client: {},
            export: false,
            products: [
                {
                    cantidad: "",
                    precio_unitario: "",
                    product_id: "",
                    subtotal: "",
                    product: {
                        id: "",
                        nombre: "",
                        codigo: "",
                        img_url: "",
                    },
                },
            ],
            paymentReceipt: {
                recibo_numero: "",
                details: [],
            },
        };
        state.totalVenta = 0;
        state.saleProducts = [];
        state.dialogQuickPay = false;
        state.dialogCreditPay = false;
        state.isPagando = false;
    },
    ERROR_PAGO(state, payload) {
        state.sale = {
            currency_id: payload,
        };
    },

    EDIT_SALE(state) {
        state.dialog = false;
        state.edit = false;
        state.sale = {
            factura_numero: "",
            credito: false,
            plazo: "",
            currency_id: 1,
            client_id: "",
            cliente: "",
            client: {
                nombre_y_apellido: "",
            },
            products: [
                {
                    cantidad: "",
                    precio_unitario: "",
                    product_id: "",
                    subtotal: "",
                    product: {
                        id: "",
                        nombre: "",
                        codigo: "",
                        img_url: "",
                    },
                },
            ],
            paymentReceipt: {
                recibo_numero: "",
                details: [],
            },
        };
        state.saleProducts = [];
    },
    DELETE_SALE(state) {
        state.delete = false;
        state.isDeleted = false;
        state.totalVenta = 0;
    },
    PRINT(state) {
        state.printDialog = false;
        state.print = false;
    },
    QUICK_PAY(state) {},
    CREDIT_PAY(state) {
        state.loadingCredit = false;
        state.saleProducts = [];
        state.dialogCreditPay = false;
        state.totalVenta = 0;
        state.isPagando = false;
    },
    METHOD_PAY(state) {
        state.saleProducts = [];
        state.totalVenta = 0;
        state.isPagando = false;
    },
    ERROR(state) {
        state.saleProducts = [];
        state.dialogQuickPay = false;
        state.isPagando = false;
        state.totalVenta = 0;
    },
    SETISCANCANCEL(state, payload) {
        state.isCanCancel = payload;
    },
    SET_BUDGET_NUMBER(state, payload) {
        state.budgetNumber = payload;
    },
    DELETE_BUDGET_AFTER_SALE(state) {},
    setDeleteDialog(state) {
        state.deleteDialog = !state.deleteDialog;
    },
    descuentoMayorista(state) {
        state.totalVenta = 0;
        state.saleProducts.forEach((p, index) => {
            state.saleProducts[index].precio_unitario = p.precio_mayorista;
            state.saleProducts[index].subtotal =
                p.precio_mayorista * p.cantidad;
            state.totalVenta += p.subtotal;
        });
    },
    descuentoNormal(state) {
        state.totalVenta = 0;

        state.saleProducts.forEach((p, index) => {
            state.saleProducts[index].precio_unitario = p.precio_descuento;
            state.saleProducts[index].subtotal =
                p.precio_descuento * p.cantidad;
            state.totalVenta += p.subtotal;
        });
    },
    revertirDescuento(state) {
        state.totalVenta = 0;

        state.saleProducts.forEach((p, index) => {
            state.saleProducts[index].precio_unitario = p.precio_venta;
            state.saleProducts[index].subtotal =
                p.precio_descuento * p.cantidad;
            state.totalVenta += p.subtotal;
        });
    },
    descuentoArbitrario(state, item) {
        state.saleProducts.find(
            (p) => p.product_id === item.product_id
        ).precio_unitario = parseFloat(item.precio_unitario);
    },
    descuentoSubtotal(state, item) {
        state.saleProducts.find(
            (p) => p.product_id === item.product_id
        ).precio_unitario =
            parseFloat(item.subtotal) / parseFloat(item.cantidad);
    },
    updateTotal(state) {
        state.totalVenta = 0;
        state.saleProducts.forEach((p) => {
            p.subtotal = p.precio_unitario * p.cantidad;
            state.totalVenta += p.subtotal;
        });
    },
    setPaymentReceipt(state, payload) {
        state.sale.paymentReceipt = payload;
    },
    mostrarError(state, payload) {
        this.commit("app/setSnackbarAlert", payload);
        state.isPagando = false;
    },
    setPrintDialog(state, payload) {
        state.printDialog = payload;
    },
    clearSaleProducts(state) {
        state.saleProducts = [];
    },
    createStructurePaymentSale(state) {
        state.sale.paymentReceipt = {
            recibo_numero: "",
            details: [],
        };
    },
    setRuta(state, payload) {
        state.ruta = payload;
    },
    setOrderPos(state, payload) {
        state.saleProducts = [];
        let subtotal = 0;
        payload.saleProducts?.forEach((pr) => {
            let newPr = {
                product_id: pr.product_id,
                codigo: pr.product.codigo,
                producto: pr.product.nombre,
                cantidad: pr.cantidad,
                precio_unitario: pr.precio_unitario,
                precio_venta: pr.product.stock.precio_venta,
                precio_descuento: pr.product.stock.precio_descuento,
                precio_mayorista: pr.product.stock.precio_mayorista,
                subtotal: pr.subtotal,
                store_id: pr.store_id,
                stock_ilimitado: pr.product.stock_ilimitado,
                iva: pr.product.iva,
            };
            subtotal += pr.subtotal;
            state.saleProducts.push(newPr);
        });
        state.sale = Object.assign({}, payload);
        state.sale.seller_id = payload?.seller_id;
        state.sale.client_id = payload.client?.id;
        state.totalVenta = subtotal;

        const cotizaciones = this.getters["quote/getQuotes"];

        state.selectCurrency = cotizaciones.find(
            (co) => co.currency_id === payload.currency_id
        );
    },

    printSaleInvoiceMut(state, payload) {
        if (!!payload.factura_numero) {
            state.sale = payload;
            state.sale.products = [];
            state.totalVenta = payload.total;

            state.dialogImpresion = true;
            payload.sale_detail.forEach((product) => {
                let pr = {
                    product_id: product.product_id,
                    cantidad: product.cantidad,
                    producto: product?.product?.nombre,
                    precio_unitario: product.precio_unitario,
                    iva: product.product.iva,
                    subtotal: product.subtotal,
                };
                state.sale.products.push(pr);
            });
        } else {
            this.commit(
                "app/setSnackbarInfo",
                "No se puede imprimir si no es factura"
            );
        }
    },
    clearAfterImpresion(state) {
        state.totalVenta = 0;
    },
    currencyConversion(state, payload) {
        //metodo llamado desde el watch en listStore, convierte a la moneda seleccionada, desde la moneda principal
        if (payload != 1) {
            const cotizaciones = this.getters["quote/getQuotes"];
            const currency = cotizaciones.find(
                (co) => co.currency_id === payload
            );
            state.saleProducts.forEach((e) => {
                e.precio_venta /= currency.cotizacion;
                e.precio_mayorista /= currency.cotizacion;
                e.precio_descuento /= currency.cotizacion;
                e.precio_unitario /= currency.cotizacion;
            });
        }
    },
    restoreCurrencyConversion(state, payload) {
        //metodo llamado desde el watch en listStore, revierte la conversion de cualquier moneda a la moneda prinvcipal
        if (payload != 1) {
            const cotizaciones = this.getters["quote/getQuotes"];
            const currency = cotizaciones.find(
                (co) => co.currency_id === payload
            );
            state.saleProducts.forEach((e) => {
                e.precio_venta *= currency.cotizacion;
                e.precio_mayorista *= currency.cotizacion;
                e.precio_descuento *= currency.cotizacion;
                e.precio_unitario *= currency.cotizacion;
            });
        }
    },
    setSelectCurrency(state, paylaod) {
        state.selectCurrency = paylaod;
    },
    setPriceForeign(state) {
        if (!state.sale.export) {
            state.saleProducts.forEach((e) => {
                if (e.iva === "10%") {
                    e.precio_unitario /= 1.1;
                    e.newIva = "exenta";
                }
                if (e.iva === "5%") {
                    e.precio_unitario /= 1.19;
                    e.newIva = "exenta";
                }
            });
            state.sale.export = true;
        }
    },
    revertPriceForeign(state) {
        if (!!state.sale.export) {
            state.saleProducts.forEach((e) => {
                if (e.iva === "10%") {
                    Math.round((e.precio_unitario *= 1.1));
                }
                if (e.iva === "5%") {
                    Math.round((e.precio_unitario *= 1.19));
                }
            });
            state.sale.export = false;
        }
    },
    SET_INVOICE_NUMBER(state, paylaod) {
        state.sale.factura_numero = paylaod;
    },
    setSinDescuetoStock(state, paylaod) {
        state.sinDescuetoStock = paylaod;
    },
};

const getters = {
    getSaleProduct: (state) => {
        return state.saleProduct;
    },
    getCantidad: (state) => {
        return state.cantidad;
    },
    getTenProductsArray: (state) => {
        const tenProducts = [];

        for (let i = 0; i < state.saleProducts.length; i += 10) {
            let pedazo = state.saleProducts.slice(i, i + 10);
            tenProducts.push(pedazo);
        }

        return tenProducts;
    },
    getSelectCurrency: (state) => {
        return state.selectCurrency;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
