var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-10"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify"},model:{value:(_vm.searchProduct),callback:function ($$v) {_vm.searchProduct=$$v},expression:"searchProduct"}})],1),_vm._v(" "),(_vm.canCreate)?_c('v-col',{attrs:{"cols":"12","lg":"auto","md":"auto","sm":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.setDialog}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v("\n                        Agregar\n                    ")],1)],1):_vm._e()],1)]},proxy:true},{key:`item.category`,fn:function({ item }){return _vm._l((item.category),function(categoria,index){return _c('span',{key:categoria.id},[_vm._v("\n                "+_vm._s(categoria.nombre +
                    (item.category.length > 1 &&
                    index + 1 != item.category.length
                        ? ", "
                        : ""))+"\n            ")])})}},{key:`item.img_url`,fn:function({ item }){return [_c('span',[_c('v-img',{staticClass:"ma-1 rounded",attrs:{"max-height":"80","max-width":"80","src":item.img_url}})],1)]}},{key:`item.brand.nombre`,fn:function({ item }){return [(item.brand)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"indigo","text-color":"white"}},[_vm._v(_vm._s(item.brand.nombre)+"\n            ")]):_vm._e()]}},{key:`item.actions`,fn:function({ item }){return [(_vm.canEdit)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editar(item)}}},[_vm._v("\n                mdi-pencil\n            ")]):_vm._e(),_vm._v(" "),(_vm.canDelete)?_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){_vm.localProduct = item;
                    _vm.setDeleteDialog();}}},[_vm._v("\n                mdi-delete\n            ")]):_vm._e()]}}],null,true)}),_vm._v(" "),_c('v-pagination',{staticClass:"py-4",attrs:{"length":_vm.productsPage.lastPage},model:{value:(_vm.productsPage.current),callback:function ($$v) {_vm.$set(_vm.productsPage, "current", $$v)},expression:"productsPage.current"}}),_vm._v(" "),_c('DeleteDialog',{attrs:{"dialog":_vm.deleteDialog,"setDialog":_vm.setDeleteDialog},on:{"deleted":function($event){_vm.deleteProduct(_vm.localProduct);
            _vm.getProducts();}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }