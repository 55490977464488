<template>
    <v-row justify="center">
        <v-dialog v-model="dialogFormParams" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h5"
                        >{{ returnTitleForm }} Parametros</span
                    >
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    <v-text-field
                                        v-model="parameter.clave"
                                        label="Clave"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-textarea
                                        v-model="parameter.valor"
                                        label="Valor"
                                        required
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        outlined
                        @click="handlerClose()"
                    >
                        Cerrar
                    </v-btn>
                    <v-btn
                        :color="returnColorBtn"
                        outlined
                        @click="crearParametros()"
                    >
                        {{ returnBtnForm }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    data: () => ({
        valid: true,
    }),
    computed: {
        ...mapState("config", [
            "parameter",
            "isEditParams",
            "dialogFormParams",
        ]),

        returnTitleForm() {
            return this.isEditParams ? "Editar" : "Agregar";
        },
        returnBtnForm() {
            return this.isEditParams ? "Editar" : "Guardar";
        },
        returnColorBtn() {
            return this.isEditParams ? "green darken-1" : "blue darken-1";
        },
    },
    methods: {
        ...mapMutations("config", ["handlerClose"]),
        ...mapActions("config", ["createParameters", "editParameters"]),
        crearParametros() {
            if (this.$refs.form.validate()) {
                if (this.isEditParams) {
                    this.editParameters(this.parameter);
                } else {
                    this.createParameters(this.parameter);
                }
            }
        },
    },
    mounted() {},
};
</script>

<style></style>
