import axios from "axios";

const state = {
    dialog: false,
    edit: false,
    deleteDialog: false,
    isDeleted: false,
    clients: [],
    client: {
        nacionalidad: "Paraguay",
    },
    clientForOther: {},
    isClientForOther: false,
    loadingClient: false,
    clientsPage: {
        current: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        firstPage: 0,
    },
    nationality: ["Paraguay", "Argentina", "Brasil", "Uruguay"],
    headers: [
        {
            text: "Nombre",
            align: "start",
            value: "nombre_y_apellido",
        },
        { text: "ruc", value: "ruc" },
        { text: "telefono", value: "telefono" },
        { text: "email", value: "email" },
        { text: "nacionalidad", value: "nacionalidad" },
        { text: "direccion", value: "direccion" },
        { text: "Opciones", value: "actions", sortable: false },
    ],
};

const actions = {
    getClients({ commit, state }) {
        state.loadingClient = true;
        axios
            .get(`/clients?page=${state.clientsPage.current}`)
            .then((response) => {
                commit("GET_CLIENTS", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    "Ocurrio algun error al obtener."
                );
            })
            .finally(() => {
                state.loadingClient = false;
            });
    },
    createClient({ commit }, params) {
        this.commit("app/setIsLoading", true);
        axios
            .post("/clients", params)
            .then((response) => {
                if (state.isClientForOther) {
                    state.clientForOther = response.data;
                }
                commit("CREATE_CLIENT", params);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    "Ocurrio algun error al crear."
                );
            });
        this.commit("app/setIsLoading", false);
    },
    editClient({ commit }, params) {
        axios
            .put(`/clients/${params.id}`, params)
            .then((response) => {
                commit("EDIT_CLIENT", params);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    "Ocurrio algun error al editar."
                );
            });
    },
    deleteClient({ commit }, params) {
        axios
            .delete(`/clients/${params.id}`)
            .then((response) => {
                commit("DELETE_CLIENT", params);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    "Ocurrio algun error al eliminar."
                );
            });
    },
    findClient({ commit }, params) {
        axios
            .get(`/clients/${params.id}`)
            .then((response) => {
                commit("FIND_CLIENT", response.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    "Ocurrio algun error al buscar."
                );
            });
    },
    searchClient({ state, commit }, buscador) {
        state.loadingClient = true;
        axios
            .post("/search_clients", { buscador: buscador })
            .then((res) => {
                commit("SEARCH_CLIENTS", res.data);
            })
            .catch((error) => {
                this.commit(
                    "app/setSnackbarAlert",
                    "Ocurrio algun error al buscar."
                );
            })
            .finally(() => {
                state.loadingClient = false;
            });
    },
    searchClientRuc({ state, commit }, buscador) {
        state.loadingClient = true;
        if (state.setTimeoutBuscador) clearTimeout(state.setTimeoutBuscador);
        state.setTimeoutBuscador = setTimeout(function () {
            axios
                .post("/search_client_by_ruc", {
                    buscador: buscador,
                })
                .then((res) => {
                    commit("SEARCH_CLIENTS", res.data);
                })
                .catch((error) => {
                    this.commit(
                        "app/setSnackbarAlert",
                        "Ocurrio algun error al buscar."
                    );
                });
            state.loadingClient = false;
        }, 500);
    },
};

const mutations = {
    setDialog(state) {
        state.dialog = !state.dialog;
    },
    setEdit(state) {
        state.edit = !state.edit;
    },
    setDeleteDialog(state) {
        state.deleteDialog = !state.deleteDialog;
    },
    clearClientForOther(state) {
        state.clientForOther = {};
        state.isClientForOther = false;
    },
    setIsClientForOther(state) {
        state.isClientForOther = true;
    },
    setClient(state, item) {
        state.client.id = item.id;
        state.client.nombre_y_apellido = item.nombre_y_apellido;
        state.client.ruc = item.ruc;
        state.client.email = item.email;
        state.client.direccion = item.direccion;
        state.client.telefono = item.telefono;
        state.client.user_id = item.user_id;
    },
    clearClient(state) {
        state.client = {
            nacionalidad: "Paraguay",
        };
    },
    GET_CLIENTS(state, clients) {
        state.clients = clients.data;
        state.clientsPage = {
            total: clients.total,
            perPage: clients.per_page,
            current: clients.current_page,
            lastPage: clients.last_page,
            firstPage: clients.first_page,
        };
    },
    CREATE_CLIENT(state) {
        state.dialog = false;
    },
    EDIT_CLIENT(state) {
        state.dialog = false;
        state.edit = false;
    },
    DELETE_CLIENT(state) {
        state.delete = false;
    },
    FIND_CLIENT(state, payload) {
        state.client = payload;
    },
    SEARCH_CLIENTS(state, clients) {
        state.clients = clients.data;
        state.clientsPage = {
            total: clients.total,
            perPage: clients.per_page,
            current: clients.current_page,
            lastPage: clients.last_page,
            firstPage: clients.first_page,
        };
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
