<template v-slot:activator="{ on, attrs }">
    <v-form ref="form" v-model="valid">
        <v-row justify="center">
            <!-- Dialog (Modal Form Client) -->
            <v-dialog
                v-model="dialog"
                persistent
                max-width="1000px"
                @click:outside="cerrarDialogProduct()"
                @keydown.esc="cerrarDialogProduct()"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="3" md="3">
                                    <v-autocomplete
                                        v-model="referencia"
                                        :items="productFamilies"
                                        label="Buscar referencia"
                                        :search-input.sync="buscarReferencia"
                                        item-text="nombre"
                                        :loading="loading"
                                        :filter="filterObjectProduct"
                                        clearable
                                        return-object
                                        ref="referencia"
                                    >
                                        <!-- No Encuentra el producto -->
                                        <template v-slot:no-data>
                                            <div
                                                class="d-flex flex-column align-items-center pt-4"
                                            >
                                                <h6>Busqueda sin resultados</h6>
                                                <v-btn @click="setDialogFamily"
                                                    >crear familia</v-btn
                                                >
                                            </div>
                                        </template>

                                        <!-- Mostrar la lista de items -->
                                        <template slot="item" slot-scope="data">
                                            <v-list-item-avatar tile>
                                                <img :src="data.item.img_url" />
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{
                                                    data.item.nombre
                                                }}</v-list-item-title>
                                                <v-list-item-subtitle
                                                    >Ref:
                                                    {{
                                                        data.item.referencia
                                                    }}</v-list-item-subtitle
                                                >
                                                <v-list-item-subtitle
                                                    >Código:
                                                    {{
                                                        data.item.codigo
                                                    }}</v-list-item-subtitle
                                                >
                                            </v-list-item-content>
                                        </template>

                                        <!-- Producto seleccionado  -->
                                        <template
                                            slot="selection"
                                            slot-scope="data"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{
                                                        `${data.item.referencia} - ${data.item.nombre}`
                                                    }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="5" md="5">
                                    <v-text-field
                                        label="Nombre"
                                        v-model="product.nombre"
                                        :rules="[required('nombre')]"
                                        ref="nombre_producto"
                                        @keydown.enter="
                                            setFocus('codigo_producto')
                                        "
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" md="4">
                                    <v-text-field
                                        label="Código"
                                        v-model="product.codigo"
                                        :rules="[required('codigo')]"
                                        :error="!errorCodigo"
                                        :error-messages="
                                            !errorCodigo
                                                ? 'Código repetido'
                                                : ''
                                        "
                                        :loading="loadingCompareProductCode"
                                        ref="codigo_producto"
                                        @keydown.enter="
                                            setFocus('marca_producto')
                                        "
                                        @change="
                                            (e) =>
                                                this.searchRepeatingProduct(e)
                                        "
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row v-if="!isFromService">
                                <!-- Seccion Marca -->
                                <v-col cols="12" sm="6" md="6">
                                    <!-- seleccion y busqueda de marca -->
                                    <v-autocomplete
                                        :menu-props="{
                                            nudgeBottom: 15 + 'px',
                                        }"
                                        v-model="product.brand_id"
                                        :items="brands"
                                        :search-input.sync="searchBrand"
                                        label="Elija una marca"
                                        item-text="nombre"
                                        item-value="id"
                                        cache-items
                                        clearable
                                        :loading="loading"
                                        @click:clear="product.marca_id = null"
                                        ref="marca_producto"
                                    >
                                        <!-- En caso que no encuentra -->
                                        <!-- Opcion para crear marca -->
                                        <template v-slot:no-data>
                                            <v-sheet
                                                class="d-flex justify-center ma-2"
                                            >
                                                <h6>Marca inexistente</h6>
                                            </v-sheet>
                                            <v-sheet
                                                class="d-flex justify-center"
                                            >
                                                <v-btn
                                                    color="primary"
                                                    @click="
                                                        setDialogBrand();
                                                        setIsBrandForOther();
                                                    "
                                                    >Crear Marca</v-btn
                                                >
                                            </v-sheet>
                                        </template>

                                        <template v-slot:item="{ item }">
                                            <v-card
                                                outlined
                                                tile
                                                class="mr-2 rounded"
                                            >
                                                <v-img
                                                    v-if="!!item.img_url"
                                                    max-height="50"
                                                    max-width="50"
                                                    :src="item.img_url"
                                                />
                                            </v-card>
                                            {{ item.nombre }}
                                        </template>
                                        <template v-slot:append-item>
                                            <div
                                                v-intersect="endIntersectBrand"
                                            />
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <!-- Seccion Categoria -->
                                <v-col cols="12" sm="6" md="6">
                                    <!-- seleccion y busqueda de categoria -->
                                    <v-autocomplete
                                        v-model="product.category"
                                        :items="categories"
                                        :search-input.sync="searchCategory"
                                        label="Seleccione una categoria"
                                        item-text="nombre"
                                        item-value="id"
                                        multiple
                                        cache-items
                                        clearable
                                        @click:clear="product.category = null"
                                    >
                                        <!-- En caso que no encuentra -->
                                        <!-- Opcion para crear categoria -->
                                        <template v-slot:no-data>
                                            <v-sheet
                                                class="d-flex justify-center ma-2"
                                            >
                                                <h6>Categoria inexistente</h6>
                                            </v-sheet>
                                            <v-sheet
                                                class="d-flex justify-center"
                                            >
                                                <v-btn
                                                    class="primary"
                                                    @click="
                                                        setDialogCategory();
                                                        setIsCategoryForOther();
                                                    "
                                                    >Crear Categoria</v-btn
                                                >
                                            </v-sheet>
                                        </template>

                                        <template v-slot:item="{ item }">
                                            <v-card
                                                outlined
                                                tile
                                                class="mr-2 rounded"
                                            >
                                                <v-img
                                                    v-if="!!item.img_url"
                                                    max-height="50"
                                                    max-width="50"
                                                    :src="item.img_url"
                                                />
                                            </v-card>
                                            {{ item.nombre }}
                                        </template>
                                        <template v-slot:append-item>
                                            <div
                                                v-intersect="
                                                    endIntersectCategory
                                                "
                                            />
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row v-if="!isFromService">
                                <v-col
                                    class="d-flex align-end"
                                    cols="12"
                                    sm="4"
                                    md="4"
                                >
                                    <v-file-input
                                        v-model="filename"
                                        accept="image/*"
                                        label="Seleccionar imagen"
                                        :rules="sizeRules"
                                        show-size
                                        counter
                                        clearable
                                        @change="onFileChange"
                                    >
                                    </v-file-input>
                                </v-col>
                                <v-col
                                    class="d-flex align-end mb-5"
                                    cols="12"
                                    sm="4"
                                    md="4"
                                >
                                    <v-sheet elevation="2" class="rounded">
                                        <v-img
                                            v-if="
                                                product.img_url != null &&
                                                product.img_url != ''
                                            "
                                            v-bind:src="product.img_url"
                                            height="150"
                                            width="120"
                                            class="rounded text-center align-end pa-1"
                                            aspect-ratio="2"
                                            contain
                                        >
                                            <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-fab-transition>
                                                        <v-btn
                                                            x-small
                                                            dark
                                                            bottom
                                                            color="red"
                                                            v-on="on"
                                                            v-bind="attrs"
                                                            @click="removeImg()"
                                                            >Eliminar
                                                        </v-btn>
                                                    </v-fab-transition>
                                                </template>
                                                <span>Eliminar Imagen</span>
                                            </v-tooltip>
                                        </v-img>
                                    </v-sheet>
                                </v-col>

                                <v-col md="4">
                                    <v-select
                                        v-model="product.iva"
                                        :items="opcionesIva"
                                        label="Iva"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    v-if="isFromService"
                                    cols="12"
                                    sm="6"
                                    md="6"
                                >
                                    <v-text-field
                                        label="Precio"
                                        type="number"
                                        v-model.number="product.precio"
                                        :rules="negativeRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    v-if="isFromService"
                                    cols="12"
                                    sm="6"
                                    md="6"
                                >
                                    <v-select
                                        v-model="product.quotation_id"
                                        :items="quotes"
                                        item-value="quotation_id"
                                        item-text="nombre"
                                        label="Moneda"
                                        :rules="quoteRules"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Descripcion"
                                        v-model="product.descripcion"
                                        rows="2"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                        <small>*Campos obligatorios</small>

                        <v-divider></v-divider>

                        <DetailsFormProductComponent
                            :valuesColors="valuesColors"
                            :addExtensionColor="addExtensionColor"
                            :addExtensionSize="addExtensionSize"
                            :removeExtensionSize="removeExtensionSize"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red darken-1"
                            outlined
                            @click="cerrarDialogProduct()"
                        >
                            Cerrar
                        </v-btn>
                        <v-btn
                            v-if="edit"
                            :disabled="!valid || isLoading"
                            :loading="isLoading"
                            color="blue darken-1"
                            outlined
                            @click="validateSubmit(edit, product)"
                        >
                            Editar
                        </v-btn>
                        <div v-else>
                            <v-btn
                                :disabled="!valid || isLoading"
                                :loading="isLoading"
                                color="blue darken-1"
                                outlined
                                @click="validateSubmit(edit, product)"
                                class="ml-1"
                            >
                                Guardar y salir
                            </v-btn>
                            <v-btn
                                :disabled="!valid || isLoading"
                                :loading="isLoading"
                                color="blue darken-1"
                                outlined
                                @click="guardarSinSalir()"
                            >
                                Guardar
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <template><FormProductFamily /></template>
    </v-form>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import { required } from "../../utils/validate.js";
import DetailsFormProductComponent from "./DetailsFormProduct.Component.vue";
import FormProductFamily from "../ProductFamily/FormProductFamily.Component.vue";
export default {
    components: {
        DetailsFormProductComponent,
        FormProductFamily,
    },
    data: () => ({
        valid: true,
        required,
        negativeRules: [(v) => v >= 0 || "El precio no puede ser negativo"],
        sizeRules: [
            (v) =>
                !v ||
                v.size < 10000000 ||
                "No subir archivo superando los 10 MB!",
        ],
        // marcaRules: [(v) => !!v || "Marca del Producto es obligatorio"],
        localItem: {},
        searchBrand: "",
        searchCategory: "",
        filename: null,
        showImg: null,
        iva: "10%",
        opcionesIva: ["5%", "10%", "Exenta"],
        quoteRules: [
            (v) => !!v || "Es necesario seleccionar moneda",
            (v) => v !== 0 || "Es necesario seleccionar moneda",
        ],
        valuesColors: [],
        buscarReferencia: "",
        referencia: {},
    }),
    mounted() {
        this.getBrands();
        this.getCategories();
        this.getQuoteCurrency();
    },
    computed: {
        //llamada de variables del store
        ...mapState("product", [
            "dialog",
            "edit",
            "product",
            "img",
            "isFromService",
            "errorCodigo",
            "productFamilies",
            "imgFile",
            "loadingCompareProductCode",
        ]),
        ...mapState("product", {
            productLoading: (state) => state.loading,
        }),
        ...mapState("brand", [
            "brands",
            "isBrandForOther",
            "brandForOther",
            "brandsPage",
            "loading",
        ]),
        ...mapState("category", [
            "categories",
            "isCategoryForOther",
            "categoryForOther",
            "categoryPage",
        ]),
        ...mapState("app", ["isLoading"]),
        ...mapState("quote", ["quotes"]),
        //Dependiendo del edit (boolean) cambia el titulo del form
        formTitle() {
            if (this.isFromService) {
                return this.edit === false
                    ? "Nuevo servicio"
                    : "Editar servicio";
            } else {
                return this.edit === false
                    ? "Nuevo Producto"
                    : "Editar Producto";
            }
        },
    },
    watch: {
        //actualiza al agregar nueva Marca desde el Form Producto
        //agrega el id Marca creado al campo de seleccion Marca
        brandForOther(val) {
            if (this.isBrandForOther) {
                this.product.marca_id = this.brandForOther.id;
                this.clearBarndForOther;
            }
        },
        categoryForOther(val) {
            if (this.isCategoryForOther) {
                this.product.category.push(this.categoryForOther.id);
                this.clearCategoryForOther();
            }
        },
        searchBrand(val) {
            this.searchBrands(val);
        },
        searchCategory(val) {
            this.searchCategories(val);
        },
        "product.codigo"(val) {
            this.searchRepeatingProduct(val);
        },
        "product.img_url"(val) {
            if (val) {
                this.showImg = val;
                this.setImage(val);
            } else {
                this.showImg = null;
            }
        },
        buscarReferencia(val) {
            this.searchReferencia(val);
        },
        referencia(val) {
            //HACER FOCUS AL CAMPO REFERENCIA AL ABRIR EL DIALOG
            if (val) {
                this.setProductFromReferencia(JSON.parse(JSON.stringify(val)));
                setTimeout(() => {
                    this.setFocus("nombre_producto");
                });
            }
        },
        dialog(val) {
            if (val) {
                setTimeout(() => {
                    this.setFocus("referencia");
                });
            } else {
                this.referencia = null;
                this.removeImg();
            }
        },
    },
    methods: {
        //TODO: VER QUE HACER AL SELECCIONAR LA REFERENCIA
        //llamada de metodos del store
        ...mapMutations("product", [
            "setDialog",
            "setEdit",
            "clearProduct",
            "setImage",
            "setDetailsProduct",
            "setProductFromReferencia",
            "setIsSalir",
            "setDialogFamily",
            "setImgFile",
            "setImgUrl",
        ]),
        ...mapMutations("brand", { setDialogBrand: "setDialog" }),
        ...mapMutations("brand", [
            "setIsBrandForOther",
            "clearBrandForOther",
            "addListBrands",
        ]),
        ...mapMutations("category", { setDialogCategory: "setDialog" }),
        ...mapMutations("category", [
            "setIsCategoryForOther",
            "clearCategoryForOther",
            "addListCategories",
            "clearCategoryForOther",
        ]),
        ...mapActions("quote", ["getQuoteCurrency"]),
        //llamada de peticiones del store
        ...mapActions("product", [
            "createProduct",
            "editProduct",
            "getCategory",
            "createProductDetails",
            "searchRepeatingProduct",
            "searchReferencia",
            ,
        ]),
        ...mapActions("brand", ["getBrands", "searchBrands"]),
        ...mapActions("category", ["getCategories", "searchCategories"]),

        //validar campo requerido
        //Dependiendo del edit (boolean), crea o edita la marca
        //resetea validacion, obtiene las listas marca, borra los campos nombre url
        async validateSubmit(edit, item) {
            // this.product.img_url = this.img;
            this.setIsSalir(true);
            if (this.$refs.form.validate()) {
                if (!!this.valuesColors.length) {
                    this.setDetailsProduct(this.valuesColors);
                    const complete = await this.createProductDetails();
                    if (complete) {
                        this.valuesColors = [];
                    }
                } else {
                    if (edit) {
                        this.editProduct(item);
                    } else {
                        this.createProduct(item);
                    }
                }
            }
            this.resetValidate();
            this.cerrarDialogProduct();
        },
        resetValidate() {
            this.$refs.form.resetValidation();
            // this.$refs.form.reset();
        },
        async guardarSinSalir() {
            this.setIsSalir(false);
            //this.product.img_url = this.img;
            if (this.$refs.form.validate()) {
                if (!!this.valuesColors.length) {
                    this.setDetailsProduct(this.valuesColors);
                    const complete = await this.createProductDetails();
                    if (complete) {
                        this.valuesColors = [];
                    }
                } else {
                    this.createProduct(this.product);
                }
            }
            this.referencia = null;
            this.filename = null;
            this.resetValidate();
            this.setFocus("referencia");
        },
        removeImg() {
            this.setImgUrl(null);
            this.filename = null;
        },
        resetImage() {
            this.showImg = null;
            this.setImage(null);
            this.filename = null;
        },
        onFileChange(file) {
            if (file) {
                this.setImgUrl(URL.createObjectURL(file));
                //this.showImg = URL.createObjectURL(file); //guardar solo la ruta guardada
                URL.revokeObjectURL(file); // liberar memoria
                this.setImgFile(file);
            } else {
                this.removeImg();
            }
        },
        /*onFileChange(payload) {
            const file = payload; //obtener datos de la variable
            if (file) {
                this.showImg = URL.createObjectURL(file); //guardar solo la ruta guardada
                URL.revokeObjectURL(file); // liberar memoria
                this.saveImage(file); //guardar imagen
            } else {
                this.setImage(null);
            }
        },*/
        saveImage(payload) {
            let data = new FormData();
            data.append("image", payload);
            this.setImage(data);
        },
        endIntersectBrand(entries, observer, isIntersecting) {
            if (isIntersecting) {
                if (this.brandsPage.lastPage > this.brandsPage.current) {
                    this.brandsPage.current += 1;
                    this.getBrands();
                    this.addListBrands(this.brands);
                }
            }
        },
        endIntersectCategory(entries, observer, isIntersecting) {
            if (isIntersecting) {
                if (this.categoryPage.lastPage > this.categoryPage.current) {
                    this.categoryPage.current += 1;
                    this.getCategories();
                    this.addListCategories(this.categories);
                }
            }
        },
        addExtensionColor() {
            this.valuesColors.push({
                color: "",
                codigo: "",
                size: "",
                sizes: [],
            });
        },
        addExtensionSize(payload) {
            this.valuesColors[payload.colorIdx].sizes.push(payload.size);
        },
        removeExtensionSize(payload) {
            this.valuesColors[payload.colorIdx].sizes.splice(
                payload.sizeIdx,
                1
            );
        },
        cerrarDialogProduct() {
            this.setIsSalir(true);
            this.setDialog();
            this.clearProduct();
            this.setEdit(false);
            this.resetValidate();
            this.clearBrandForOther();
            this.valuesColors = [];
        },
        filterObjectProduct(item, queryText, itemText) {
            return (
                (item.referencia &&
                    item.referencia
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.descripcion &&
                    item.descripcion
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1)
            );
        },
        setFocus(ref) {
            this.$refs[ref].$refs.input.focus();
        },
    },
};
</script>
