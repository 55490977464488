import axios from "axios";

const state = {
    loadingGetInvoice: false,
    invoice: {},
    invoices: [],
    invoiceHeader: [
        { text: "Factura #", value: "numero", align: "start", width: "10%" },
        {
            text: "Venta #",
            value: "sale_header.numero",
            align: "start",
            width: "10%",
        },
        {
            text: "Cliente",
            align: "start",
            value: "client",
        },
        { text: "Fecha emitida", value: "fecha" },
        { text: "Opciones", value: "actions", sortable: false, width: "150px" },
    ],
};
const actions = {
    getInvoices({ state, commit }) {
        state.loadingGetInvoice = true;
        axios
            .get("/invoices")
            .then((response) => {
                commit("GET_INVOICES", response.data);
            })
            .catch((err) => {
                console.log(err);
                this.commit("app/setSnackbarAlert", err.response.data.message);
            });
    },
};
const mutations = {
    GET_INVOICES(state, payload) {
        state.loadingGetInvoice = false;
        state.invoices = payload;
    },
};
const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
