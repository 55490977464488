<template v-slot:activator="{ on, attrs }">
    <v-form ref="formProduct" v-model="validateProduct">
        <!-- Dialog (Modal Form Client) -->
        <v-dialog
            class="dialogo-compras"
            v-model="dialog"
            @keydown.esc="setDialog()"
            @click:outside="setDialog()"
        >
            <v-card class="pa-4">
                <v-list-item>
                    <v-row class="my-1">
                        <!-- Header compras -->
                        <v-row>
                            <v-col cols="12" sm="4" md="2">
                                <v-text-field
                                    label="N° factura"
                                    v-model="purchase.factura_numero"
                                    :rules="numeroFacturaRules"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="6">
                                <v-autocomplete
                                    v-model="purchase.supplier_id"
                                    :rules="proveedorRules"
                                    :items="suppliers"
                                    :search-input.sync="searchLocalSupplier"
                                    :loading="loading"
                                    item-text="nombre"
                                    item-value="id"
                                    cache-items
                                    label="Proveedor *"
                                    clearable
                                    @click="purchase.supplier_id = null"
                                >
                                    <template v-slot:no-data>
                                        <v-sheet
                                            class="d-flex justify-center ma-2"
                                        >
                                            <h5>Proveedor inexistente</h5>
                                        </v-sheet>
                                        <v-sheet class="d-flex justify-center">
                                            <v-btn
                                                @click="
                                                    setDialogSupplier();
                                                    setIsSupplierForOther();
                                                    autoInputNewSuppleir(
                                                        searchLocalSupplier
                                                    );
                                                "
                                                >Crear Proveedor</v-btn
                                            >
                                        </v-sheet>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                                <v-checkbox
                                    v-model="purchase.credito"
                                    label="Crédito *"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="2" md="2">
                                <v-text-field
                                    v-model="purchase.plazo"
                                    :rules="methodPlazo"
                                    :disabled="!purchase.credito"
                                    label="Plazo *"
                                    suffix="Días"
                                    required
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <!-- Detalles compras -->
                        <!-- Producto, cantidad, precio compra -->
                        <v-form
                            ref="formInputProduct"
                            v-model="validateInputProduct"
                        >
                            <v-row class="my-0">
                                <v-col class="py-0" cols="12" md="4">
                                    <v-autocomplete
                                        v-model="localProduct.select"
                                        :items="products"
                                        :loading="loadingSearchProduct"
                                        :search-input.sync="searchProduct"
                                        label="Elija un producto"
                                        clearable
                                        no-filter
                                        @input="
                                            (event) =>
                                                agregarDatoProducto(event)
                                        "
                                    >
                                        <!-- En caso que no encuentra -->
                                        <template v-slot:no-data>
                                            <div
                                                class="d-flex flex-column align-items-center"
                                            >
                                                <h6>Producto inexistente</h6>
                                                <v-btn
                                                    class="ma-2"
                                                    outlined
                                                    small
                                                    color="primary"
                                                    @click="setDialogProduct()"
                                                >
                                                    <v-icon dark
                                                        >mdi-plus</v-icon
                                                    >
                                                    Agregar
                                                </v-btn>
                                            </div>
                                        </template>

                                        <template
                                            slot="item"
                                            slot-scope="{ item }"
                                        >
                                            <v-list>
                                                <v-list-item-title>
                                                    {{ item.nombre }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{ item.codigo }}
                                                </v-list-item-subtitle>
                                            </v-list>
                                        </template>

                                        <!--   Lista categoria de productos -->
                                        <template
                                            slot="selection"
                                            slot-scope="{ item }"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{
                                                        ` ${item.nombre} - ${item.codigo}`
                                                    }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>

                                        <template v-slot:append-item>
                                            <div
                                                v-intersect="
                                                    endIntersectProduct
                                                "
                                            />
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col class="py-0" md="1">
                                    <v-text-field
                                        type="number"
                                        v-model="localProduct.cantidad"
                                        placeholder="cantidad producto"
                                        label="Cantidad"
                                        @input="calcularTotal()"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="py-0" md="2">
                                    <vuetify-money
                                        v-model="localProduct.precio_unitario"
                                        label="Precio Compra"
                                        v-bind:options="vmOptions"
                                        @input="
                                            calcularTotal();
                                            calcularPrecioVenta();
                                        "
                                    />
                                </v-col>
                                <v-col md="2">
                                    <v-select
                                        class="mt-n2"
                                        v-model="selectStorage"
                                        label="Deposito"
                                        :items="stores"
                                        placeholder="Seleccionar Deposito"
                                        item-text="nombre"
                                        item-value="id"
                                        :rules="storeRules"
                                        @change="changeStore(selectStorage)"
                                    ></v-select>
                                </v-col>
                                <v-col class="" md="3" align-self="center">
                                    <span class="text-success h5 ml-10">
                                        Sub
                                        {{
                                            convertMoney(
                                                localProduct.total,
                                                getCurrencyCode
                                            )
                                        }}
                                    </span>
                                </v-col>
                                <v-col class="" md="3" align-self="center">
                                    <v-select
                                        v-model="purchase.quotation_id"
                                        :items="quotes"
                                        item-value="quotation_id"
                                        item-text="nombre"
                                        label="Moneda"
                                        :rules="quoteRules"
                                        @change="selectQuotation"
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <!-- Precio venta, mayorista, descuento -->
                            <v-row class="my-0">
                                <v-col class="py-0" md="3">
                                    <v-row no-gutters align-end>
                                        <v-col md="8">
                                            <vuetify-money
                                                type="number"
                                                v-model.number="
                                                    localProduct.precio_venta
                                                "
                                                :options="vmOptions"
                                                label="Precio Venta"
                                                @input="calcularPorcentaje(0)"
                                            >
                                            </vuetify-money>
                                        </v-col>
                                        <v-col md="3" class="ml-1">
                                            <v-text-field
                                                class="centered-input"
                                                v-model="porcentaje"
                                                suffix="%"
                                                @input="calcularPrecioVenta(0)"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col class="py-0" md="3">
                                    <v-row no-gutters align-end>
                                        <v-col md="8">
                                            <vuetify-money
                                                v-model.number="
                                                    localProduct.precio_descuento
                                                "
                                                label="Precio Descuento"
                                                :options="vmOptions"
                                                @input="calcularPorcentaje(1)"
                                            >
                                            </vuetify-money>
                                        </v-col>
                                        <v-col md="3" class="ml-1">
                                            <v-text-field
                                                v-model="porcentajeDescuento"
                                                suffix="%"
                                                class="centered-input"
                                                @input="calcularPrecioVenta(1)"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col class="py-0" md="3">
                                    <v-row no-gutters align-end>
                                        <v-col md="8">
                                            <vuetify-money
                                                v-model.number="
                                                    localProduct.precio_mayorista
                                                "
                                                min="0"
                                                label="Precio Mayorista"
                                                :options="vmOptions"
                                                @input="calcularPorcentaje(2)"
                                            >
                                            </vuetify-money>
                                        </v-col>
                                        <v-col md="3" class="ml-1">
                                            <v-text-field
                                                v-model="porcentajeMayorista"
                                                suffix="%"
                                                class="centered-input"
                                                @input="calcularPrecioVenta(2)"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-col md="3" class="pl-14 pt-6 py-0 mt-n2">
                                    <v-btn
                                        outlined
                                        :color="formColorEditProduct"
                                        @click="agregarProducto"
                                    >
                                        {{ formTitleEditProduct }}
                                    </v-btn>

                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                class="ml-4"
                                                color="red"
                                                @click="btnEditProduct"
                                                v-on="on"
                                                v-bind="attrs"
                                            >
                                                mdi-close
                                            </v-icon>
                                        </template>
                                        <span>Vaciar Campos</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>

                            <!-- Lista de productos a comprar -->
                        </v-form>
                        <v-col>
                            <v-data-table
                                :headers="returnHeader"
                                :items="purchaseProducts"
                                :items-per-page="-1"
                                hide-default-footer
                            >
                                <!-- Iconos Editar Y Eliminar producto  -->
                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        color="blue"
                                        @click="
                                            setIsEditProduct(true);
                                            addEditProduct(item);
                                        "
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon
                                        small
                                        class="mr-2"
                                        color="red"
                                        @click="deletePurchaseProduct(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </template>

                                <!-- Mostrar precio subTotal -->
                                <template v-slot:[`item.sub_total`]="{ item }">
                                    <span>{{
                                        convertMoney(
                                            item.cantidad *
                                                item.precio_unitario,
                                            getCurrencyCode
                                        )
                                    }}</span>
                                </template>

                                <!-- Mostrar precio costo -->
                                <template
                                    v-slot:[`item.precio_unitario`]="{ item }"
                                >
                                    <span>{{
                                        convertMoney(
                                            item.precio_unitario,
                                            getCurrencyCode
                                        )
                                    }}</span>
                                </template>

                                <!-- Mostrar precio venta -->
                                <template
                                    v-slot:[`item.precio_venta`]="{ item }"
                                >
                                    <span>{{
                                        convertMoney(
                                            item.precio_venta,
                                            getCurrencyCode
                                        )
                                    }}</span>
                                </template>

                                <!-- Mostrar precio mayorista -->
                                <template
                                    v-slot:[`item.precio_mayorista`]="{ item }"
                                >
                                    <span>{{
                                        convertMoney(
                                            item.precio_mayorista,
                                            getCurrencyCode
                                        )
                                    }}</span>
                                </template>

                                <!-- Mostrar precio descuento -->
                                <template
                                    v-slot:[`item.precio_descuento`]="{ item }"
                                >
                                    <span>{{
                                        convertMoney(
                                            item.precio_descuento,
                                            getCurrencyCode
                                        )
                                    }}</span>
                                </template>

                                <!-- Mostrar Nombre Deposito -->
                                <template
                                    v-if="edit"
                                    v-slot:[`item.store_id`]="{ item }"
                                >
                                    <span>{{
                                        returnNameStore(item.store_id)
                                    }}</span>
                                </template>
                                <template v-else v-slot:[`item.dep`]="{ item }">
                                    <span>{{ returnNameStore(item.dep) }}</span>
                                </template>
                            </v-data-table>
                            <h4 class="text-success text-right mb-0 mt-2 mr-4">
                                Total:
                                {{
                                    convertMoney(getTotalPrice, getCurrencyCode)
                                }}
                            </h4>
                            <v-divider />
                            <v-card-actions class="ma-0 pa-0">
                                <v-spacer></v-spacer>
                                <v-btn
                                    v-if="edit"
                                    color="red lighten-2"
                                    text
                                    class="ma-3"
                                    outlined
                                    @click="
                                        setDialog();
                                        setEdit();
                                        resetValidate();
                                        clearPurchase();
                                    "
                                >
                                    Cerrar
                                </v-btn>
                                <v-btn
                                    v-else
                                    color="red lighten-2"
                                    text
                                    class="ma-3"
                                    outlined
                                    @click="
                                        setDialog();
                                        resetValidate();
                                    "
                                >
                                    Cerrar
                                </v-btn>
                                <v-btn
                                    v-if="edit"
                                    :disabled="!valid"
                                    :loading="loadingEditPurchase"
                                    class="ma-3"
                                    color="primary"
                                    outlined
                                    text
                                    @click="validateSubmit(edit, purchase)"
                                >
                                    Editar
                                </v-btn>
                                <v-btn
                                    v-else
                                    :disabled="!valid"
                                    :loading="loadingCreatePurchase"
                                    color="primary"
                                    outlined
                                    text
                                    @click="validateSubmit(edit, purchase)"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-col>
                    </v-row>
                </v-list-item>
            </v-card>
            <FormSupplies />
            <FormProductComponent />
        </v-dialog>
    </v-form>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import FormSupplies from "../Supplies/FormSupplies.Component";
import FormProductComponent from "../Products/FormProduct.Component.vue";
import { convertMoney } from "../../formatos";
export default {
    data: () => ({
        valid: true,
        validateProduct: true,
        validateInputProduct: true,
        queryTerm: "",
        searchLocalSupplier: "",
        numeroFacturaRules: [(v) => !!v || "Factura es obligatorio"],
        proveedorRules: [(v) => !!v || "Proveedor es obligatorio"],
        numberRules: [
            (v) => !!v || "El valor es obligatorio",
            (v) => v >= 0 || "El valor no puede ser negativo",
        ],
        isEmpty: [(v) => !!v || "El campo no debe ser vacio"],
        isEmptyProduct: [(v) => !!v || "Debes seleccionar un producto"],
        quoteRules: [
            (v) => !!v || "Es necesario seleccionar moneda",
            (v) => v !== 0 || "Es necesario seleccionar moneda",
        ],
        storeRules: [
            (v) => !!v || "Es necesario seleccionar deposito",
            (v) => v !== 0 || "Deposito origen es obligatorio",
        ],
        plazoRules: [],
        localItem: {},
        searchProduct: "",
        porcentaje: 0,
        porcentajeDescuento: 0,
        porcentajeMayorista: 0,
        localProduct: {
            product_id: null,
            product: {
                nombre: "",
                codigo: "",
            },
            cantidad: 0,
            precio_unitario: 0,
            precio_descuento: 0,
            precio_mayorista: 0,
            precio_venta: 0,
            dep: null,
            total: 0,
        },
        options: {
            locale: "es-PY",
            prefix: "Gs.",
            suffix: "",
            length: 13,
            precision: 0,
        },
        selectStorage: "",
    }),
    components: {
        FormSupplies,
        FormProductComponent,
    },
    watch: {
        supplierForOther(val) {
            if (this.isSupplierForOther) {
                this.purchase.supplier_id = this.supplierForOther.id;
                this.clearSupplierForOther;
            }
        },
        edit() {
            this.edit
                ? (this.localProduct.store_id = this.selectStorage)
                : (this.localProduct.dep = this.selectStorage);
        },
        localProduct(val) {
            this.edit
                ? (this.localProduct.store_id = this.selectStorage)
                : (this.localProduct.dep = this.selectStorage);
        },
        searchProduct(val, old) {
            if (val !== old) {
                this.productsPage.current = 1;
            }
            this.searchOnlyProduct(val);
        },
        searchLocalSupplier(val) {
            this.searchSupplier(val);
        },
    },
    computed: {
        //llamada de variables del store
        ...mapState("purchase", [
            "dialog",
            "edit",
            "purchase",
            "headersPurchase",
            "headersProduct",
            "headersProductAdd",
            "purchaseProducts",
            "isEditProduct",
            //"loadingSearch",
            "loadingCreatePurchase",
            "loadingEditPurchase",
        ]),
        ...mapState("supplier", [
            "suppliers",
            "supplier",
            "supplierForOther",
            "isSupplierForOther",
            "loading",
        ]),
        ...mapState("product", ["products", "productsPage", "loadingSearchProduct"]),
        ...mapState("app", ["snackbar", "isLoading", "vmOptions"]),
        ...mapState("deposito", ["stores"]),
        ...mapState("quote", ["quotes"]),
        //...mapState("app", ["snackbar"]),
        ...mapGetters("quote", ["getQuote"]),
        ...mapGetters("purchase", ["getTotalPrice", "getPurchaseProductCount"]),
        ...mapGetters("deposito", ["returnNameStore", "getLocaleStorageStore"]),
        //Dependiendo del edit (boolean) cambia el titulo del form
        formTitle() {
            return this.edit === false ? "Nueva Compra" : "Editar Compra";
        },
        formTitleEditProduct() {
            return this.isEditProduct === false ? "AGREGAR" : "EDITAR";
        },
        formColorEditProduct() {
            return this.isEditProduct === false ? "primary" : "green";
        },
        methodPlazo() {
            if (this.purchase.credito) {
                const rule = (v) => !!v || "Plazo es obligatorio";
                this.plazoRules.push(rule);
            } else {
                this.plazoRules = [];
            }
            return this.plazoRules;
        },
        returnHeader() {
            return this.edit ? this.headersProduct : this.headersProductAdd;
        },
        getCurrencySymbol() {
            return this.quotes.find(
                (co) => co.quotation_id === this.purchase.quotation_id
            ).simbolo;
        },
        getCurrencyCode() {
            return this.quotes.find(
                (co) => co.quotation_id === this.purchase.quotation_id
            ).codigo;
        },
        getCurrencyId() {
            return this.quotes.find(
                (co) => co.quotation_id === this.purchase.quotation_id
            ).currency_id;
        },
        getDecimales() {
            return this.quotes.find(
                (co) => co.quotation_id === this.purchase.quotation_id
            ).decimales;
        },
    },
    mounted() {
        this.getSuppliers();
        this.setIsFromService(false);
        this.searchOnlyProduct("");
        this.porcentaje = this.obtenerDesdeLocalStorage("porcentaje");
        this.porcentajeDescuento = this.obtenerDesdeLocalStorage(
            "porcentajeDescuento"
        );
        this.porcentajeMayorista = this.obtenerDesdeLocalStorage(
            "porcentajeMayorista"
        );
        this.getAllStores();
        this.getQuoteCurrency();
        this.purchase.quotation_id = this.getQuote.quotation_id;
        this.selectStorage = parseInt(this.getLocaleStorageStore)
            ? parseInt(this.getLocaleStorageStore)
            : this.stores[0];
    },
    methods: {
        convertMoney,
        //llamada de metodos del store
        ...mapMutations("purchase", [
            "setDialog",
            "setEdit",
            "setIsEditProduct",
            "clearPurchase",
            "addPurchaseProduct",
            "editPurchaseProduct",
            "deletePurchaseProduct",
        ]),
        //llamada de metodo para snackbar
        ...mapMutations("app", [
            "setSnackbar",
            "setSnackbarInfo",
            "setVmSuffix",
            "setVmPrecision",
        ]),
        ...mapMutations("deposito", ["setLocaleStorageStore"]),
        //llamada de peticiones del store

        ...mapMutations("product", ["setIsFromService", "setLazy"]),
        ...mapMutations("product", {
            setDialogProduct: "setDialog",
        }),
        ...mapMutations("supplier", { setDialogSupplier: "setDialog" }),
        ...mapMutations("supplier", [
            "setIsSupplierForOther",
            "clearSupplierForOther",
        ]),

        ...mapActions("supplier", ["getSuppliers", "searchSupplier"]),
        ...mapActions("product", ["getProducts", "searchOnlyProduct"]),
        ...mapActions("purchase", [
            "createPurchase",
            "editPurchase",
            "getPurchases",
            "getOnePurchase",
        ]),
        ...mapActions("deposito", ["getAllStores"]),
        ...mapActions("quote", ["getQuoteCurrency"]),
        //validar campo requerido
        //Dependiendo del edit (boolean), crea o edita la marca
        //resetea validacion, obtiene las listas marca, borra los campos nombre url
        validateSubmit(edit, purchase) {
            purchase.pagado = false;
            if (!purchase.credito) {
                purchase.pagado = true;
                purchase.credito = false;
                purchase.plazo = 0;
            } else {
                purchase.credito = true;
            }
            purchase.products = this.purchaseProducts;
            if (!this.getPurchaseProductCount) {
                this.setSnackbarInfo(
                    "No hay productos agregados, agregue al menos uno"
                );
                return;
            }
            if (this.$refs.formProduct.validate()) {
                if (edit) {
                    this.editPurchase(purchase);
                } else {
                    this.createPurchase(purchase);
                }
                this.resetValidate();
            }
            this.localShow = true;
        },
        //resetea las validaciones activadas
        resetValidate() {
            this.$refs.formProduct.validate();
        },
        agregarProducto() {
            this.$refs.formInputProduct.validate();

            if (this.validateInputProduct) {
                this.addDefaultValuePrices();
                if (this.localProduct.cantidad <= 0) {
                    this.setSnackbar({
                        text: "Cantidad debe ser mayor a 0",
                        type: "info",
                        timeout: 1500,
                        show: true,
                        icon: "mdi-alert-circle-outline",
                    });
                    return;
                }

                if (this.localProduct.product_id) {
                    if (this.isEditProduct === false) {
                        this.localProduct.total =
                            this.localProduct.cantidad *
                            this.localProduct.precio_unitario;

                        this.localProduct.dep = this.selectStorage;
                        this.addPurchaseProduct(this.localProduct);
                    } else {
                        this.localEditProduct(this.localProduct);
                    }
                    this.porcentaje = 0;
                    this.resetProduct();
                } else {
                    //Activar snackbar con mensaje de error
                    let snackbar = {
                        text: "Complete los campos del producto",
                        type: "info",
                        timeout: 1500,
                        show: true,
                        icon: "mdi-alert-circle-outline",
                    };
                    this.setSnackbar(snackbar);
                }
            } else {
                this.setSnackbar({
                    text: "Complete los campos rojos",
                    type: "info",
                    timeout: 1500,
                    show: true,
                    icon: "mdi-alert-circle-outline",
                });
            }
        },
        agregarDatoProducto(item) {
            if (item) {
                this.localProduct.product_id = item.id;
                this.localProduct.product = {
                    nombre: item.nombre,
                    codigo: item.codigo,
                };
            }
        },
        //Editar los producto espesificos de la compra
        localEditProduct(item) {
            this.editPurchaseProduct(item);
            this.setIsEditProduct(false);
            this.localProduct = {};
            this.porcentaje = 0;
        },
        addEditProduct(item) {
            this.localProduct = item;
        },
        btnEditProduct() {
            this.localProduct = {};
            this.setIsEditProduct(false);
        },
        //calcular compra total del producto
        calcularTotal() {
            this.localProduct.total =
                this.localProduct.cantidad * this.localProduct.precio_unitario;
        },
        calcularPrecioVenta(opcion) {
            switch (opcion) {
                case undefined: {
                    let porc =
                        (this.porcentaje * this.localProduct.precio_unitario) /
                        100;
                    this.localProduct.precio_venta =
                        parseInt(this.localProduct.precio_unitario) +
                        parseInt(porc);

                    porc =
                        (this.porcentajeDescuento *
                            this.localProduct.precio_unitario) /
                        100;
                    this.localProduct.precio_descuento =
                        parseInt(this.localProduct.precio_venta) +
                        parseInt(porc);

                    porc =
                        (this.porcentajeMayorista *
                            this.localProduct.precio_unitario) /
                        100;
                    this.localProduct.precio_mayorista =
                        parseInt(this.localProduct.precio_mayorista) +
                        parseInt(porc);
                    break;
                }
                case 0: {
                    let porc =
                        (this.porcentaje * this.localProduct.precio_unitario) /
                        100;
                    this.localProduct.precio_venta =
                        parseInt(this.localProduct.precio_unitario) +
                        parseInt(porc);
                    this.guardarEnLocalStorage("porcentaje", this.porcentaje);
                    break;
                }
                case 1: {
                    let porc =
                        (this.porcentajeDescuento *
                            this.localProduct.precio_unitario) /
                        100;
                    this.localProduct.precio_descuento =
                        parseInt(this.localProduct.precio_unitario) +
                        parseInt(porc);
                    this.guardarEnLocalStorage(
                        "porcentajeDescuento",
                        this.porcentajeDescuento
                    );
                    break;
                }
                case 2: {
                    let porc =
                        (this.porcentajeMayorista *
                            this.localProduct.precio_unitario) /
                        100;
                    this.localProduct.precio_mayorista =
                        parseInt(this.localProduct.precio_unitario) +
                        parseInt(porc);
                    this.guardarEnLocalStorage(
                        "porcentajeMayorista",
                        this.porcentajeMayorista
                    );
                    break;
                }
            }
        },
        calcularPorcentaje(opcion) {
            switch (opcion) {
                case 0: {
                    let temp =
                        this.localProduct.precio_venta -
                        this.localProduct.precio_unitario;
                    let temp2 =
                        (100 * temp) / this.localProduct.precio_unitario;
                    this.porcentaje = parseInt(temp2);
                    break;
                }
                case 1: {
                    let temp =
                        this.localProduct.precio_descuento -
                        this.localProduct.precio_unitario;
                    let temp2 =
                        (100 * temp) / this.localProduct.precio_unitario;
                    this.porcentajeDescuento = parseInt(temp2);
                    break;
                }
                case 2: {
                    let temp =
                        this.localProduct.precio_mayorista -
                        this.localProduct.precio_unitario;
                    let temp2 =
                        (100 * temp) / this.localProduct.precio_unitario;
                    this.porcentajeMayorista = parseInt(temp2);
                    break;
                }
            }
        },

        resetProduct() {
            this.localProduct = {
                product_id: null,
                product: {
                    nombre: "",
                    codigo: "",
                },
                cantidad: 0,
                precio_unitario: 0,
                precio_descuento: 0,
                precio_mayorista: 0,
                precio_venta: 0,
                total: 0,
                dep: parseInt(this.getLocaleStorageStore),
            };
        },
        addDefaultValuePrices() {
            if (
                this.localProduct.precio_mayorista === 0 ||
                this.localProduct.precio_mayorista === ""
            ) {
                this.localProduct.precio_mayorista =
                    this.localProduct.precio_venta;
            }
            if (
                this.localProduct.precio_descuento === 0 ||
                this.localProduct.precio_descuento === ""
            ) {
                this.localProduct.precio_descuento =
                    this.localProduct.precio_venta;
            }
        },
        autoInputNewSuppleir(payload) {
            this.supplier.nombre = payload;
        },
        encriptarDato(dato) {
            const textoCifrado = window.btoa(dato);
            return textoCifrado;
        },
        desencriptarDato(datoEncriptado) {
            const textoDescifrado = window.atob(datoEncriptado);
            return textoDescifrado;
        },
        guardarEnLocalStorage(nombre, valor) {
            //const nombreEncriptado = this.encriptarDato(nombre);
            const valorEncriptado = this.encriptarDato(valor);
            localStorage.setItem(nombre, valorEncriptado);
        },
        obtenerDesdeLocalStorage(nombre) {
            const valorEncriptado = localStorage.getItem(nombre);
            const valorDesencriptado = valorEncriptado
                ? this.desencriptarDato(valorEncriptado)
                : 0;
            return valorDesencriptado;
        },
        changeStore(val) {
            this.setLocaleStorageStore(val);
        },
        filterObjectProduct(item, queryText, itemText) {
            return (
                (item.nombre &&
                    item.nombre
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1) ||
                (item.codigo &&
                    item.codigo
                        .toLocaleLowerCase()
                        .indexOf(queryText.toLocaleLowerCase()) > -1)
            );
        },
        selectQuotation() {
            this.setVmSuffix(this.getCurrencySymbol);
            this.setVmPrecision(this.getDecimales);
        },
        async endIntersectProduct(entries, observer, isIntersecting) {
            if (isIntersecting) {
                if (this.productsPage.lastPage > this.productsPage.current) {
                    this.setLazy(true);
                    this.productsPage.current += 1;
                    await this.searchOnlyProduct("");
                    this.setLazy(false);
                }
            }
        },
    },
};
</script>

<style scoped>
.table {
    background-color: #f7f7f8;
}

.centered-input >>> input {
    text-align: center;
}

.text-right {
    text-align: right;
}
</style>
