<template>
    <article>
        <v-row>
            <v-col cols="6">
                <AutocompleteProductVue
                    v-model="localProduct"
                    :deposito="selectedStore"
                />
            </v-col>
            <v-col cols="2">
                <v-text-field
                    v-model="cantidad"
                    type="Number"
                    label="cantidad"
                />
            </v-col>
            <v-col cols="2">
                <v-select
                    v-model="selectedStore"
                    :items="stores"
                    label="Deposito"
                    item-text="nombre"
                    item-value="id"
                    @change="(item) => setLocaleStorageStore(item)"
                ></v-select>
            </v-col>
            <v-col class="d-flex" align-self="center">
                <v-btn
                    :color="btnAddEditColor"
                    outlined
                    @click="agregarProducto"
                    >{{ btnAddEditName }}</v-btn
                >
            </v-col>
        </v-row>

        <v-data-table
            :headers="headersRemission"
            :items="remissionProducts"
            hide-default-footer
        >
            <template v-slot:[`item.nombre`]="{ item }">
                {{ item?.producto }}
            </template>
            <template v-slot:[`item.store_id`]="{ item }">
                {{ returnNameStore(item?.store_id) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="green" @click="editarProducto(item)"
                    >mdi-pencil</v-icon
                >
                <v-icon
                    v-if="!editar"
                    color="red"
                    @click="deleteRemissionProducts(item)"
                    >mdi-cancel</v-icon
                >
            </template>
        </v-data-table>
    </article>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import AutocompleteProductVue from "../../AutocompleteProduct.vue";
export default {
    data: () => ({
        searchProduct: "",
        selectedStore: null,
        localProduct: {},
        editar: false,
        cantidad: 0,
        oldProductId: 0,
    }),
    watch: {
        dialogRemission(val) {
            if (val) {
                this.selectedStore = parseInt(this.getLocaleStorageStore);
                this.getRemissionProducts(this.selectedStore);
            }
        },
    },
    components: {
        AutocompleteProductVue,
    },
    computed: {
        ...mapState("deposito", ["stores"]),
        ...mapState("product", ["products"]),
        ...mapState("remission", [
            "remissionProducts",
            "headersRemission",
            "productsRemissionStore",
            "dialogRemission",
        ]),
        ...mapGetters("deposito", ["getLocaleStorageStore", "returnNameStore"]),

        btnAddEditName() {
            return this.editar ? "Editar" : "Agregar";
        },
        btnAddEditColor() {
            return this.editar ? "green" : "primary";
        },
    },
    methods: {
        ...mapMutations("deposito", ["setLocaleStorageStore"]),
        ...mapMutations("remission", [
            "addRemissionProducts",
            "editRemissionProducts",
            "deleteRemissionProducts",
            "clearRemission",
        ]),
        ...mapMutations("app", ["setSnackbar"]),

        ...mapActions("deposito", ["getAllStores"]),
        ...mapActions("remission", ["getRemissionProducts"]),

        agregarProducto() {
            this.cantidad ??= 0;
            if (this.cantidad <= 0) {
                this.setSnackbar({
                    text: "Cantidad no puede ser menor o igual a 0",
                    type: "info",
                    timeout: 1500,
                    show: true,
                    icon: "mdi-alert-circle-outline",
                });
                return;
            }

            this.localProduct.cantidad = parseInt(this.cantidad);

            if (this.editar) {
                this.editRemissionProducts(this.localProduct);
                this.editar = false;
            } else {
                this.addRemissionProducts(this.localProduct);
            }

            this.localProduct = {};
            this.selectedProduct = {};
            this.cantidad = 0;
        },

        editarProducto(item) {
            this.editar = true;
            this.oldProductId = parseInt(item.product_id);
            this.selectedProduct = item.product_id;
            this.cantidad = item.cantidad;
        },
    },
    mounted() {
        this.getAllStores();
    },
};
</script>
